import React from "react";
import { SpecificationFieldOptions, SpecificationFieldRangeOptions } from "./specification-field";
import { useGridApiContext } from "@mui/x-data-grid-premium";

function DataGridSpecificationField(props) {
	const apiContext = useGridApiContext();
	const ref = React.useRef();
	React.useLayoutEffect(() => {
		if (props.hasFocus && ref.current) {
			const input = ref.current.querySelector("select");
			if (input) input.focus();
		}
	}, [props.hasFocus]);
	const handleChange = (event) => {
		apiContext.current.setEditCellValue({ id: props.dataGridId, field: props.dataGridField, value: event.target.value });
		if (props.onChange) props.onChange(event);
	};
	return (
		<props.SpecificationField
			{...props}
			onChange={handleChange}
			propagateRef={ref}
		/>
	);
}

export function DataGridSpecificationFieldOptions(props) {
	return <DataGridSpecificationField {...props} SpecificationField={SpecificationFieldOptions} />;
}

export function DataGridSpecificationFieldRangeOptions(props) {
	return <DataGridSpecificationField {...props} SpecificationField={SpecificationFieldRangeOptions} />;
}