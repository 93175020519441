import { Certificate as CertificateClass } from "../classes/certificate";
import StatusField from "./status-field";

export default function ResultStatusField(props) {
	const value = props.value ? props.value : CertificateClass.RESULT_STATUS.PENDING;
	const status = props.value === CertificateClass.RESULT_STATUS.PASSED ? "green" :
		props.value === CertificateClass.RESULT_STATUS.FAILED ? "red" : "yellow";
	return (
		<StatusField {...props} value={value} status={status} label={props.label ? props.label : "Testing Status"} />
	);
}