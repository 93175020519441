const { Item } = require("./item.js");

class Customer extends Item {

	static className = "Customer";
	static history = true;
	static idIndex = "id2";
	static idPlusIndex = "idPlus3";
	static indexes = [...Item.indexes, Customer.idIndex, Customer.idPlusIndex];
	static key = [...Item.key, "tenantIdShard"];
	static tableName = "LabAxiomCustomer";

	constructor() {
		super();
		this.address = undefined;
		this.contacts = undefined;
		this.email = undefined;
		this.payBeforeCOANotification = undefined;
		this.phone = undefined;
		this.tenantIdShard = undefined;
	}

}

export { Customer };