import { AlertProvider } from "./contexts/alert";
import { DialogProvider } from "./contexts/dialog";
import { Item as ItemClass } from "./classes/item";
import Router from "./router";
import { VeilProvider } from "./contexts/veil";

export default function App() {
	return (
		<AlertProvider>
			<VeilProvider>
				<DialogProvider>
					<Router />
				</DialogProvider>
			</VeilProvider>
		</AlertProvider>
	);
}

export function getEnvironment() {
	return window.location.hostname === "app.labaxiom.com" ? ItemClass.ENVIRONMENT.PROD : ItemClass.ENVIRONMENT.DEV;
}