import React from "react";

const DialogContext = React.createContext();
export default DialogContext;

export const DialogProvider = ({ children }) => {
	const [dialogConfiguration, setDialogConfiguration] = React.useState(null);
	const setDialog = (dialogConfiguration) => {
		setDialogConfiguration(dialogConfiguration);
	};
	return (
		<DialogContext.Provider value={{ dialogConfiguration, setDialog }}>
			{children}
		</DialogContext.Provider>
	);
};