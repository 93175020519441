import React from "react";
import { AccountCircle, Help as SupportIcon, Home as HomeIcon, Info as AboutIcon, Logout as LogoutIcon, Menu as MenuIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { logout, refresh as refreshSession } from "../session";
import useSession from "../hooks/useSession";

export default function Header() {
	const [menuDrawerState, setMenuDrawerState] = React.useState(false);
	const { session } = useSession();
	const [userDrawerState, setUserDrawerState] = React.useState(false);
	const handleRefresh = () => {
		(async () => {
			await refreshSession();
			window.location.reload();
		})();
	};
	const toggleMenuDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setMenuDrawerState(open);
	};
	const toggleUserDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setUserDrawerState(open);
	};
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar elevation={5} position="fixed" color="primary">
				<Toolbar sx={{ height: 64 }}>
					<IconButton size="large" edge="start" color="inherit" aria-label="Main Menu" onClick={toggleMenuDrawer(true)}>
						<MenuIcon />
					</IconButton>
					<Drawer anchor="left" open={menuDrawerState} onClose={toggleMenuDrawer(false)}>
						<Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)} onKeyDown={toggleMenuDrawer(false)}>
							<List>
								<ListItem sx={{ paddingTop: "12px", paddingBottom: "12px" }}>
									<img
										src={process.env.REACT_APP_WWW_URL + "/images/lab-axiom-logo-wide.svg"}
										alt="Lab Axiom"
										style={{ display: "block", filter: "brightness(0) saturate(100%) invert(1)", height: "24px", margin: "auto" }}
									/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemButton component={Link} to="/">
										<ListItemIcon><HomeIcon /></ListItemIcon><ListItemText primary="Home" />
									</ListItemButton>
								</ListItem>
								<ListItem disablePadding>
									<ListItemButton component={Link} to="/support">
										<ListItemIcon><SupportIcon /></ListItemIcon><ListItemText primary="Support" />
									</ListItemButton>
								</ListItem>
								<ListItem disablePadding>
									<ListItemButton component={Link} to="/about">
										<ListItemIcon><AboutIcon /></ListItemIcon><ListItemText primary="About" />
									</ListItemButton>
								</ListItem>
							</List>
						</Box>
					</Drawer>
					<Box align="center" sx={{ flexGrow: 1, overflow: "hidden", whiteSpace: "nowrap", marginLeft: "12px", marginRight: "12px" }}>
						<Typography component="h1" variant="h5" sx={{ fontWeight: "normal", fontSize: "24px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
							{session.tenant.name}
						</Typography>
					</Box>
					<IconButton size="large" edge="end" color="inherit" aria-label="Account Menu" onClick={toggleUserDrawer(true)}>
						<AccountCircle />
					</IconButton>
					<Drawer anchor="right" open={userDrawerState} onClose={toggleUserDrawer(false)}>
						<Box sx={{ width: 250 }} role="presentation" onClick={toggleUserDrawer(false)} onKeyDown={toggleUserDrawer(false)}>
							<List>
								<ListItem sx={{ paddingTop: "12px", paddingBottom: "12px" }}>
									<ListItemIcon><AccountCircle /></ListItemIcon>
									<Box sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
										{session.user.name ? session.user.name : session.user.email}
										{session.user.name ? <Typography variant="body2" color="text.secondary">{session.user.email}</Typography> : null}
									</Box>
								</ListItem>
								<ListItem disablePadding>
									<ListItemButton onClick={handleRefresh}>
										<ListItemIcon><RefreshIcon /></ListItemIcon><ListItemText primary="Refresh" />
									</ListItemButton>
								</ListItem>
								<ListItem disablePadding>
									<ListItemButton onClick={logout}>
										<ListItemIcon><LogoutIcon /></ListItemIcon><ListItemText primary="Logout" />
									</ListItemButton>
								</ListItem>
							</List>
						</Box>
					</Drawer>
				</Toolbar>
			</AppBar>
			<Toolbar sx={{ height: 64 }} />
		</Box>
	);
}