import CurrencyField from "./currency-field";
import { Icon as LabIcon } from "./lab";
import { Icon as TestIcon } from "./test";
import Items from "./items";
import { Lab as LabClass } from "../classes/lab";
import NumberField from "./number-field";
import ReferenceField from "./reference-field";
import { Test as TestClass } from "../classes/test";

export default function Tests(props) {
	const data = props.data;
	const columns = [
		{
			field: "tenantIdShardLabIdReference", type: "string", headerName: "Lab", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ReferenceField
							class={LabClass}
							icon={LabIcon}
							mode="view"
							submode="naked"
							id={params.row.id}
							idReference={params.rowNode.type === "group" ? params.value : params.row.tenantIdShardLabIdReference}
						/>
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return TestClass.getNameFromCombinedId(params.value);
			}
		},
		{
			field: "isGroup", type: "boolean", headerName: "Group", valueGetter: (params) => {
				if (params.value && params.value.toLowerCase() === "on") {
					return true;
				} else {
					return false;
				}
			}
		},
		{
			field: "tenantIdShardTestIdReferences", type: "string", headerName: "Tests", groupable: false, renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ReferenceField
							class={TestClass}
							icon={TestIcon}
							multiple="true"
							mode="view"
							submode="naked"
							id={params.row.id}
							idReferences={params.row.tenantIdShardTestIdReferences}
						/>
					);
				}
			}, valueGetter: (params) => {
				if (params.value && Array.isArray(params.value) && params.value.length > 0) {
					return params.value.map((tenantIdShardTestIdReference) => TestClass.getNameFromCombinedId(tenantIdShardTestIdReference)).join(", ");
				}
			}
		},
		{
			field: "method", type: "string", headerName: "Method", valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},
		{
			field: "sampleSizeRequired", type: "number", headerName: "Sample Size", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<NumberField value={params.value} valueSuffix={params.row?.sampleSizeRequiredUOM ? " " + params.row.sampleSizeRequiredUOM : null} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "turnAroundDays", type: "number", headerName: "Turn Around", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<NumberField value={params.value} unit="day" mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseInt(params.value);
			}
		},
		{
			field: "price", type: "number", headerName: "Price", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<CurrencyField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "cost", type: "number", headerName: "Cost", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<CurrencyField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		}
	];
	const columnVisibilityModel = props.columnVisibilityModel ? props.columnVisibilityModel : {};
	columnVisibilityModel.tenantIdShardLabIdReference = false;
	if (columnVisibilityModel.isGroup === null || window.innerWidth < 300) columnVisibilityModel.isGroup = false;
	columnVisibilityModel.tenantIdShardTestIdReferences = false;
	if (columnVisibilityModel.method === null || window.innerWidth < 600) columnVisibilityModel.method = false;
	if (columnVisibilityModel.sampleSizeRequired === null || window.innerWidth < 900) columnVisibilityModel.sampleSizeRequired = false;
	if (columnVisibilityModel.turnAroundDays === null || window.innerWidth < 900) columnVisibilityModel.turnAroundDays = false;
	if (columnVisibilityModel.price === null || window.innerWidth < 900) columnVisibilityModel.price = false;
	if (columnVisibilityModel.cost === null || window.innerWidth < 900) columnVisibilityModel.cost = false;
	return (
		<Items
			class={TestClass} data={data} columns={columns}
			columnVisibilityModel={columnVisibilityModel} filterName={props.filterName} filterValue={props.filterValue}
		/>
	);
}