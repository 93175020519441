import React from "react";
import { Certificate as CertificateClass } from "../classes/certificate";
import CurrencyField from "./currency-field";
import DateField from "./date-field";
import { DataGridSpecificationFieldOptions, DataGridSpecificationFieldRangeOptions } from "./data-grid-specification-field";
import { Icon as LabIcon } from "./lab";
import { Icon as TestIcon } from "./test";
import { Lab as LabClass } from "../classes/lab";
import NumberField from "./number-field";
import { processSpecificationUpdate } from "./specification-field";
import ReferenceField from "./reference-field";
import { Result as ResultClass } from "../classes/result";
import ResultStatusField from "./result-status-field";
import Subitems from "./subitems";
import { Test as TestClass } from "../classes/test";
import TextField from "./text-field";
import useSession from "../hooks/useSession";

export default function Results(props) {
	const [firstLoad, setFirstLoad] = React.useState(true);
	const [originalData, setOriginalData] = React.useState(new Map());
	const { session } = useSession();

	const handleChange = React.useCallback((id, field, value) => {
		const data = props.data.map((item) => {
			if (item.id === id) {
				return processResultUpdate({ ...item, [field]: value }, item, originalData.get(id));
			} else {
				return item;
			}
		});
		if (props.onChange) props.onChange({ target: { name: ResultClass.getType(true), value: data } });
	}, [originalData, props]);

	const columns = React.useMemo(() => [
		{
			field: "tenantIdShardTestIdReference",
			type: "string",
			headerName: "Test",
			editable: false,
			renderCell: (params) => {
				const id = ResultClass.getIdFromCombinedId(params.row.id);
				const idReference = ResultClass.getCombinedIdFromInputs(ResultClass.getTenantIdShardFromCombinedId(params.row.id), id, params.row.name);
				return (
					<ReferenceField
						class={TestClass}
						icon={TestIcon}
						mode="view"
						submode="naked"
						id={id}
						idReference={idReference}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.row.name) return params.row.name;
			}
		},
		{
			field: "tenantIdShardLabIdReference",
			type: "string",
			headerName: "Lab",
			editable: false,
			renderCell: (params) => {
				const id = ResultClass.getIdFromCombinedId(params.row.id);
				const idReference = params.row.tenantIdShardLabIdReference
				return (
					<ReferenceField
						class={LabClass}
						icon={LabIcon}
						mode="view"
						submode="naked"
						id={id}
						idReference={idReference}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value) return ResultClass.getNameFromCombinedId(params.value);
			}
		},
		/*{
			field: "sampleSizeRequired",
			type: "string",
			headerName: "Size Required",
			editable: false,
			renderCell: (params) => {
				return (
					<NumberField
						mode="view"
						name="sampleSizeRequired"
						submode="naked"
						value={params.value}
						valueSuffix={params.row.sampleSizeRequiredUOM ? " " + params.row.sampleSizeRequiredUOM : null}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},*/
		{
			field: "isGroup",
			type: "boolean",
			headerName: "Group",
			editable: false,
			valueGetter: (params) => {
				if (params.value && params.value.toLowerCase() === "on") {
					return true;
				} else {
					return false;
				}
			}
		},
		{
			field: "turnAroundDays",
			type: "number",
			headerName: "Turn Around",
			editable: false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<NumberField name="turnAroundDays" value={params.value} unit="day" mode="view" submode="naked" />
				);
			},
			valueGetter: (params) => {
				if (params.value) return parseInt(params.value);
			}
		},
		{
			field: "dateOutsourced",
			type: "date",
			headerName: "Outsourced",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup || params.row.tenantIdShardLabId === TestClass.getDefaultTenantIdShardLabId(session.tenant.id)) {
					return null;
				}
				return (
					<DateField
						min={props.minDate}
						mode={props.mode}
						name="dateOutsourced"
						onChange={(event) => { handleChange(params.id, "dateOutsourced", event.target.value); }}
						submode="naked"
						value={params.value}
						valueDefault="true"
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		{
			field: "dateOutsourcedDue",
			type: "date",
			headerName: "Outsourced Due",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup || params.row.tenantIdShardLabId === TestClass.getDefaultTenantIdShardLabId(session.tenant.id)) {
					return null;
				}
				return (
					<DateField
						min={props.minDate}
						mode={props.mode}
						name="dateOutsourcedDue"
						onChange={(event) => { handleChange(params.id, "dateOutsourcedDue", event.target.value); }}
						submode="naked"
						value={params.value}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		{
			field: "method",
			type: "string",
			headerName: "Method",
			editable: false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<TextField name="method" value={params.value} mode="view" submode="naked" />
				);
			}
		},
		{
			field: "detectionLimit",
			type: "number",
			headerName: "MDL",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<NumberField
						inputStep="0.001"
						mode={props.mode}
						name="detectionLimit"
						submode="naked"
						value={params.value}
						valueSuffix={params.row.detectionLimitUOM ? " " + params.row.detectionLimitUOM : null}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value || params.value === 0) return parseFloat(params.value);
			}
		},
		{
			field: "detectionLimitUOM",
			type: "string",
			headerName: "MDL UoM",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<TextField name="detectionLimitUOM" value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "specification",
			type: "string",
			headerName: "Spec",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<DataGridSpecificationFieldOptions
						mode="view"
						submode="naked"
						value={params.value}
					/>
				);
			},
			renderEditCell: (params) => {
				return (
					<DataGridSpecificationFieldOptions
						dataGridField="specification"
						dataGridId={params.id}
						hasFocus={params.hasFocus}
						mode="edit"
						onChange={(event) => { handleChange(params.id, "specification", event.target.value); }}
						submode="naked"
						tabIndex={params.tabIndex}
						value={params.value}
					/>
				);
			}
		},
		{
			field: "specificationValue",
			type: "number",
			headerName: "Spec Value",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (
					params.row.isGroup ||
					(
						(
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
						) && (
							params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
						)
					)
				) {
					return null;
				}
				return (
					<NumberField
						inputStep="0.001"
						mode={props.mode}
						name="specificationValue"
						submode="naked"
						value={params.value}
						valueSuffix={params.row.specificationValueUOM ? " " + params.row.specificationValueUOM : null}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value || params.value === 0) return parseFloat(params.value);
			}
		},
		{
			field: "specificationValueUOM",
			type: "string",
			headerName: "Spec UoM",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (
					params.row.isGroup ||
					(
						(
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
						) && (
							params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
						)
					)
				) {
					return null;
				}
				return (
					<TextField name="specificationValueUOM" value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "specificationRange",
			type: "string",
			headerName: "Range",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (
					params.row.isGroup ||
					(
						params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
						params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
					)
				) {
					return null;
				}
				return (
					<DataGridSpecificationFieldRangeOptions
						mode="view"
						submode="naked"
						value={params.value}
					/>
				);
			},
			renderEditCell: (params) => {
				return (
					<DataGridSpecificationFieldRangeOptions
						dataGridField="specificationRange"
						dataGridId={params.id}
						hasFocus={params.hasFocus}
						mode="edit"
						onChange={(event) => { handleChange(params.id, "specificationRange", event.target.value); }}
						submode="naked"
						tabIndex={params.tabIndex}
						value={params.value}
					/>
				);
			}
		},
		{
			field: "specificationRangeValue",
			type: "number",
			headerName: "Range Value",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (
					params.row.isGroup ||
					(
						(
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
						) || (
							params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
							params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
						)
					)
				) {
					return null;
				}
				return (
					<NumberField
						inputStep="0.001"
						mode={props.mode}
						name="specificationRangeValue"
						submode="naked"
						value={params.value}
						valueSuffix={params.row.specificationRangeValueUOM ? " " + params.row.specificationRangeValueUOM : null}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value || params.value === 0) return parseFloat(params.value);
			}
		},
		{
			field: "specificationRangeValueUOM",
			type: "string",
			headerName: "Range UoM",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (
					params.row.isGroup ||
					(
						(
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
							params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
						) || (
							params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
							params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
						)
					)
				) {
					return null;
				}
				return (
					<TextField name="specificationRangeValueUOM" value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "detectionResult",
			type: "number",
			headerName: "Result",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<NumberField
						inputStep="0.001"
						mode={props.mode}
						name="detectionResult"
						submode="naked"
						value={params.value}
						valueSuffix={params.row.detectionResultUOM ? " " + params.row.detectionResultUOM : null}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value || params.value === 0) return parseFloat(params.value);
			}
		},
		{
			field: "detectionResultUOM",
			type: "string",
			headerName: "Result UoM",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				if (params.row.isGroup) return null;
				return (
					<TextField name="detectionResultUOM" value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "dateCompleted",
			type: "date",
			headerName: "Completed",
			editable: false,
			renderCell: (params) => {
				return (
					<DateField
						min={props.minDate}
						mode="view"
						submode="naked"
						value={params.value}
					/>
				);
			},
			valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		{
			field: "specificationResult",
			type: "string",
			headerName: "Status",
			editable: false,
			renderCell: (params) => {
				return (
					<ResultStatusField value={params.value} mode="view" submode="naked" />
				);
			}
		},
		{
			field: "price",
			type: "number",
			headerName: "Price",
			editable: false,
			renderCell: (params) => {
				return (
					<CurrencyField value={params.value} mode="view" submode="naked" />
				);
			},
			valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "fee",
			type: "number",
			headerName: "Fee",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				return (
					<CurrencyField value={params.value} mode={props.mode} submode="naked" />
				);
			},
			valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "coaNote",
			type: "string",
			headerName: "CoA Note",
			editable: false,
			renderCell: (params) => {
				return (
					<TextField name="method" value={params.value} mode="view" submode="naked" />
				);
			}
		}
	], [handleChange, props.minDate, props.mode, session.tenant.id]);

	const columnVisibilityModel = React.useMemo(() => {
		const model = props.columnVisibilityModel ? props.columnVisibilityModel : {};
		model.tenantIdShardTestIdReference = false;
		model.tenantIdShardLabIdReference = false;
		model.isGroup = false;
		model.sampleSizeRequired = false;
		model.turnAroundDays = false;
		model.dateOutsourced = false;
		model.dateOutsourcedDue = false;
		model.method = false;
		if (window.innerWidth < 1200) model.detectionLimit = false;
		if (props.mode !== "edit" || window.innerWidth < 1200) model.detectionLimitUOM = false;
		if (window.innerWidth < 1200) model.specification = false;
		if (window.innerWidth < 1200) model.specificationValue = false;
		if (props.mode !== "edit" || window.innerWidth < 1200) model.specificationValueUOM = false;
		if (window.innerWidth < 1200) model.specificationRange = false;
		if (window.innerWidth < 1200) model.specificationRangeValue = false;
		if (props.mode !== "edit" || window.innerWidth < 1200) model.specificationRangeValueUOM = false;
		model.detectionResult = true;
		if (props.mode !== "edit" || window.innerWidth < 300) model.detectionResultUOM = false;
		model.dateCompleted = false;
		if (window.innerWidth < 600) model.specificationResult = false;
		model.price = false;
		if (window.innerWidth < 600) model.fee = false;
		model.coaNote = false;
		return model;
	}, [props.columnVisibilityModel, props.mode]);

	const isCellEditable = React.useCallback((params) => {
		if (params.field === "tenantIdShardTestIdReference") return false;
		if (params.field === "tenantIdShardLabIdReference") return false;
		if (params.field === "sampleSizeRequired") return false;
		if (params.field === "isGroup") return false;
		if (params.field === "turnAroundDays") return false;
		if (params.field === "method") return false;
		if (params.field === "dateCompleted") return false;
		if (params.field === "specificationResult") return false;
		if (params.field === "price") return false;
		if (params.field === "fee") return true;
		if (params.field === "coaNote") return false;
		if (params.row.isGroup === "on") {
			if (params.field === "dateOutsourced") return false;
			if (params.field === "dateOutsourcedDue") return false;
			if (params.field === "detectionLimit") return false;
			if (params.field === "detectionLimitUOM") return false;
			if (params.field === "specification") return false;
			if (params.field === "specificationValue") return false;
			if (params.field === "specificationValueUOM") return false;
			if (params.field === "specificationRange") return false;
			if (params.field === "specificationRangeValue") return false;
			if (params.field === "specificationRangeValueUOM") return false;
			if (params.field === "detectionResult") return false;
			if (params.field === "detectionResultUOM") return false;
		} else {
			if (params.field === "dateOutsourced" || params.field === "dateOutsourcedDue") {
				if (params.row.tenantIdShardLabId !== TestClass.getDefaultTenantIdShardLabId(session.tenant.id)) {
					return true;
				} else {
					return false;
				}
			}
			if (params.field === "detectionLimit") return true;
			if (params.field === "detectionLimitUOM") return true;
			if (params.field === "specification") return true;
			if (
				params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
				params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG &&
				params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
				params.row.specification !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
			) {
				if (params.field === "specificationValue") return false;
				if (params.field === "specificationValueUOM") return false;
			} else {
				if (params.field === "specificationValue") return true;
				if (params.field === "specificationValueUOM") return true;
			}
			if (
				params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
				params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
			) {
				if (params.field === "specificationRange") return false;
			} else {
				if (params.field === "specificationRange") return true;
			}
			if (
				(
					params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN.LONG &&
					params.row.specification !== CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
				) || (
					params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN.LONG &&
					params.row.specificationRange !== CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
				)
			) {
				if (params.field === "specificationRangeValue") return false;
				if (params.field === "specificationRangeValueUOM") return false;
			} else {
				if (params.field === "specificationRangeValue") return true;
				if (params.field === "specificationRangeValueUOM") return true;
			}
			if (params.field === "detectionResult") return true;
			if (params.field === "detectionResultUOM") return true;
		}
		return true;
	}, [session.tenant.id]);

	React.useEffect(() => {
		if (firstLoad) {
			const newOriginalData = new Map();
			props.data.forEach((result) => {
				newOriginalData.set(result.id, {
					dateCompleted: result.dateCompleted,
					detectionResult: result.detectionResult,
					detectionResultUOM: result.detectionResultUOM,
					specificationResult: result.specificationResult
				});
			});
			setOriginalData(newOriginalData);
			setFirstLoad(false);
		}
	}, [firstLoad, props.data]);

	return (
		<Subitems
			class={ResultClass}
			columns={columns}
			columnVisibilityModel={columnVisibilityModel}
			data={props.data}
			editName="false"
			isCellEditable={isCellEditable}
			mode={props.mode}
			onChange={props.onChange}
			onProcessRowUpdate={
				(updatedResult, originalResult) => processResultUpdate(updatedResult, originalResult, originalData.get(originalResult.id))
			}
			process={(...args) => CertificateClass.processResults(...args).processedResults}
			showAdd="false"
			showRemove="false"
		/>
	);
}

function processResultUpdate(updatedResult, originalResult, originalValues) {
	//console.log("originalResult", originalResult);
	if (updatedResult.isGroup === "on") return {
		...updatedResult,
		dateCompleted: originalValues.dateCompleted,
		specificationResult: originalValues.specificationResult
	};
	updatedResult = processSpecificationUpdate(updatedResult, originalResult, true);
	if (!isNaN(updatedResult.detectionResult)) {
		if (updatedResult.specification === CertificateClass.SPECIFICATION.ABSENT.LONG) {
			if (
				(!updatedResult.detectionLimit && updatedResult.detectionResult === 0) ||
				updatedResult.detectionLimit > updatedResult.detectionResult
			) {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG) {
			if (
				(!updatedResult.detectionLimit || updatedResult.detectionLimit <= updatedResult.detectionResult) &&
				(
					updatedResult.specificationValue !== null &&
					updatedResult.specificationValue !== undefined &&
					updatedResult.detectionResult > updatedResult.specificationValue
				)
			) {
				if (updatedResult.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN.LONG) {
					if (updatedResult.specificationRangeValue && updatedResult.detectionResult < updatedResult.specificationRangeValue) {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
					} else {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
					}
				} else if (updatedResult.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG) {
					if (updatedResult.specificationRangeValue && updatedResult.detectionResult <= updatedResult.specificationRangeValue) {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
					} else {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
					}
				} else {
					updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
				}
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG) {
			if (
				(!updatedResult.detectionLimit || updatedResult.detectionLimit <= updatedResult.detectionResult) &&
				(
					updatedResult.specificationValue !== null &&
					updatedResult.specificationValue !== undefined &&
					updatedResult.detectionResult >= updatedResult.specificationValue
				)
			) {
				if (updatedResult.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN.LONG) {
					if (updatedResult.specificationRangeValue && updatedResult.detectionResult < updatedResult.specificationRangeValue) {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
					} else {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
					}
				} else if (updatedResult.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG) {
					if (updatedResult.specificationRangeValue && updatedResult.detectionResult <= updatedResult.specificationRangeValue) {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
					} else {
						updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
					}
				} else {
					updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
				}
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.LESS_THAN.LONG) {
			if (
				(
					!updatedResult.detectionLimit ||
					(
						updatedResult.specificationValue !== null &&
						updatedResult.specificationValue !== undefined &&
						updatedResult.detectionLimit <= updatedResult.specificationValue
					)
				) &&
				updatedResult.detectionResult < updatedResult.specificationValue
			) {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG) {
			if (
				(
					!updatedResult.detectionLimit ||
					(
						updatedResult.specificationValue !== null &&
						updatedResult.specificationValue !== undefined &&
						updatedResult.detectionLimit <= updatedResult.specificationValue
					)
				) &&
				updatedResult.detectionResult <= updatedResult.specificationValue
			) {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.PRESENT.LONG) {
			if (
				(!updatedResult.detectionLimit && updatedResult.detectionResult > 0) ||
				updatedResult.detectionLimit <= updatedResult.detectionResult
			) {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.REPORT.LONG) {
			updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
		} else if (updatedResult.specification === CertificateClass.SPECIFICATION.CONFORMS.LONG) {
			if (
				(!updatedResult.detectionLimit && updatedResult.detectionResult > 0) ||
				updatedResult.detectionLimit <= updatedResult.detectionResult
			) {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			} else {
				updatedResult.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
			}
		} else {
			updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PENDING;
		}
	} else {
		updatedResult.specificationResult = CertificateClass.RESULT_STATUS.PENDING;
	}
	if (
		updatedResult.specificationResult === CertificateClass.RESULT_STATUS.PASSED ||
		updatedResult.specificationResult === CertificateClass.RESULT_STATUS.FAILED
	) {
		if (
			originalValues &&
			updatedResult.detectionResult === originalValues.detectionResult &&
			(
				updatedResult.detectionResultUOM === originalValues.detectionResultUOM ||
				(!updatedResult.detectionResultUOM && !originalValues.detectionResultUOM)
			) &&
			updatedResult.specificationResult === originalValues.specificationResult
		) {
			updatedResult.dateCompleted = originalValues.dateCompleted;
		} else {
			updatedResult.dateCompleted = new Date().toISOString();
		}
	} else {
		updatedResult.dateCompleted = "";
	}
	//console.log("updatedResult", updatedResult);
	return updatedResult;
}