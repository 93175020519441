import { action, media, summarize } from "./coa";
import { Certificate as CertificateClass } from "../classes/certificate";
import Subitems from "./subitems";

export default function COAs(props) {
	const data = props.data ? props.data : [];
	const tenantIdShardItemId = props.tenantIdShardItemId;
	return (
		<Subitems
			action={action}
			class={CertificateClass}
			data={data}
			media={media}
			mode={props.mode}
			onChange={props.onChange}
			showAdd="false"
			showCards="true"
			showEdit="false"
			showNotify="true"
			showRemove="false"
			summarize={summarize}
			tenantIdShardItemId={tenantIdShardItemId}
		/>
	);
}