import TextField from "./text-field";

export default function NumberField(props) {
	const inputMax = props.inputMax ? props.inputMax : null;
	const inputMin = props.inputMin ? props.inputMin : "0";
	const inputStep = props.inputStep ? props.inputStep : "1";
	const inputProps = {
		max: inputMax,
		min: inputMin,
		step: inputStep
	};
	const label = props.label ? props.label : "Number";
	const name = props.name ? props.name : "number";
	const showZeros = props.showZeros === "true" ? true : false;
	const valueDisplay = getNumberText(props.value, inputStep, showZeros);
	const valueSuffix = getNumberTextSuffix(props.value, props.valueSuffix, props.unit);
	return (
		<TextField
			{...props}
			type="number"
			name={name}
			label={label}
			inputProps={inputProps}
			value={props.value}
			valueDisplay={valueDisplay}
			valueSuffix={valueSuffix}
		/>
	);
}

export function getNumberText(number, inputStep, showZeros) {
	if (number !== null && number !== undefined) {
		let multiplier = 1;
		let decimals = 0;
		if (inputStep && parseFloat(inputStep) % 1 !== 0) {
			decimals = inputStep.split(".")[1].length;
			multiplier = Math.pow(10, decimals);
		}
		const roundedNumber = Math.round(number * multiplier) / multiplier;
		if (showZeros && decimals > 0) {
			return roundedNumber.toLocaleString("en-US", { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
		} else {
			return roundedNumber.toLocaleString("en-US");
		}
	} else {
		return null;
	}
}

function getNumberTextSuffix(number, suffix, unit) {
	if (suffix) {
		return suffix;
	} else if (unit) {
		if (number === "1") {
			return " " + unit;
		} else {
			return " " + unit + "s";
		}
	} else {
		return null;
	}
}