import React from "react";
import { authenticate } from "../session";

const SessionContext = React.createContext();
export default SessionContext;

export const SessionProvider = ({ children }) => {
	const [session, setSession] = React.useState(null);
	React.useEffect(() => {
		if (!session) {
			(async () => {
				setSession(await authenticate());
			})();
		}
	}, [session]);
	return (
		<SessionContext.Provider value={{ session }}>
			{session ? children : null}
		</SessionContext.Provider>
	);
};