import { readTenant } from "./api";

const USER_CLIENT_ID = process.env.REACT_APP_USER_CLIENT_ID;
const USER_REDIRECT_URI = process.env.REACT_APP_USER_REDIRECT_URI;
const USER_URL = process.env.REACT_APP_USER_URL;

const loginRequestParameters = new URLSearchParams({
	client_id: USER_CLIENT_ID,
	redirect_uri: USER_REDIRECT_URI,
	response_type: "code",
	scope: "email openid profile"
}).toString();

export async function authenticate() {
	return await authenticateFromCode();
}

async function authenticateFromCode() {
	//console.log(window.location.search);
	const code = new URLSearchParams(window.location.search).get("code");
	if (code) {
		if (code !== localStorage.getItem("code")) {
			console.log("Authenticating User from Code");
			localStorage.setItem("code", code);
			try {
				const requestParameters = new URLSearchParams({
					client_id: USER_CLIENT_ID,
					code: code,
					grant_type: "authorization_code",
					redirect_uri: USER_REDIRECT_URI
				}).toString();
				const request = {
					method: "POST",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					},
					body: requestParameters
				};
				const response = await fetch(USER_URL + "/oauth2/token", request);
				const responseData = await handleAuthenticateResponse(response);
				const redirect = localStorage.getItem("redirect") || USER_REDIRECT_URI;
				localStorage.removeItem("redirect");
				window.history.replaceState({}, document.title, redirect);
				return await loadFromRemoteStorage(responseData.access_token, responseData.id_token);
			} catch (error) {
				console.error("Error Authenticating User from Code", error);
				return login();
			}
		} else {
			console.log("Ignored Duplicate Request to Authenticate from Code");
		}
	} else {
		//console.log("Unable to Authenticate User from Code");
		return await loadFromLocalStorage();
	}
}

async function authenticateFromRefreshToken() {
	const refreshToken = localStorage.getItem("refresh_token");
	if (refreshToken) {
		console.log("Authenticating User from Refresh Token");
		try {
			const requestParameters = new URLSearchParams({
				client_id: USER_CLIENT_ID,
				grant_type: "refresh_token",
				refresh_token: refreshToken
			}).toString();
			const request = {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				},
				body: requestParameters
			};
			const response = await fetch(USER_URL + "/oauth2/token", request);
			const responseData = await handleAuthenticateResponse(response);
			return await loadFromRemoteStorage(responseData.access_token, responseData.id_token);
		} catch (error) {
			console.error("Error Authenticating User from Refresh Token", error);
			return login();
		}
	} else {
		console.log("Unable to Authenticate User from Refresh Token");
		return login();
	}
}

function getGroups(accessToken) {
	const token = parseJwt(accessToken);
	if (token && token["cognito:groups"]) {
		return token["cognito:groups"];
	} else {
		return [];
	}
}

function getRemainingTime(expiresTime) {
	let remainingTime = 0;
	if (expiresTime) remainingTime = Math.floor((expiresTime - (new Date().getTime())) / 1000 / 60);
	return remainingTime;
}

async function handleAuthenticateResponse(response) {
	const responseData = await handleResponse(response);
	if (responseData && responseData.access_token && responseData.expires_in && responseData.id_token) {
		console.log("Received User Tokens");
		localStorage.setItem("access_token", responseData.access_token);
		localStorage.setItem("expires_time", ((new Date().getTime()) + (responseData.expires_in * 1000)));
		localStorage.setItem("id_token", responseData.id_token);
		if (responseData.refresh_token) localStorage.setItem("refresh_token", responseData.refresh_token);
		return responseData;
	} else {
		console.error("User Tokens", responseData);
		throw new Error("Unexpected User Tokens");
	}
}

async function handleLoadResponse(response, idToken) {
	const responseData = await handleResponse(response);
	if (responseData && responseData.email && responseData["custom:tenantId"]) {
		console.log("Received User Info");
		localStorage.setItem("user", JSON.stringify(responseData));
		const tenant = await readTenant(idToken, responseData["custom:tenantId"]);
		if (tenant && tenant.name) {
			console.log("Received Tenant Info");
			localStorage.setItem("tenant", JSON.stringify(tenant));
			return await loadFromLocalStorage();
		} else {
			console.error("Tenant Info", tenant);
			throw new Error("Unexpected Tenant Info");
		}
	} else {
		console.error("User Info", responseData);
		throw new Error("Unexpected User Info");
	}
}

async function handleResponse(response) {
	if (!response.ok) {
		console.error("Error in Response to User Request", response.statusText);
		throw new Error("Error in Response to User Request");
	}
	return await response.json();
}

async function loadFromLocalStorage() {
	const accessToken = localStorage.getItem("access_token");
	const idToken = localStorage.getItem("id_token");
	const userString = localStorage.getItem("user");
	if (accessToken && idToken && userString) {
		const user = JSON.parse(userString);
		const groups = getGroups(accessToken);
		const expiresTime = localStorage.getItem("expires_time");
		const remainingTime = getRemainingTime(expiresTime);
		if (remainingTime > 1) {
			const tenantString = localStorage.getItem("tenant");
			if (tenantString) {
				//console.log("Loading Session from Local Storage");
				const tenant = JSON.parse(tenantString);
				const redirect = localStorage.getItem("redirect");
				return {
					groups: groups,
					idToken: idToken,
					redirect: redirect,
					tenant: tenant,
					user: user
				};
			} else {
				console.log("Unable to Load Session from Local Storage Tenant");
				return await loadFromRemoteStorage(accessToken, idToken);
			}
		} else {
			console.log("Unable to Load Session from Local Storage Time");
			return await authenticateFromRefreshToken();
		}
	} else {
		console.log("Unable to Load Session from Local Storage");
		return login();
	}
}

async function loadFromRemoteStorage(accessToken, idToken) {
	console.log("Loading Session from Remote Storage");
	try {
		const infoResponse = await fetch(USER_URL + "/oauth2/userInfo", {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + accessToken
			}
		});
		return await handleLoadResponse(infoResponse, idToken);
	} catch (error) {
		console.error("Error Loading Session From Remote Storage", error);
		return login();
	}
}

export function login() {
	console.log("Logging In User");
	localStorage.clear();
	localStorage.setItem("redirect", window.location.href);
	const request = USER_URL + "/login?" + loginRequestParameters;
	window.location.replace(request);
}

export function logout() {
	console.log("Logging Out User");
	localStorage.clear();
	const request = USER_URL + "/logout?" + loginRequestParameters;
	window.location.replace(request);
}

function parseJwt(token) {
	try {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
			return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(""));
		return JSON.parse(jsonPayload);
	} catch (error) {
		console.error("Error Parsing JWT", error);
	}
}

export async function refresh() {
	return await authenticateFromRefreshToken();
}