import React from "react";
import ReactDOM from "react-dom/client";
import { SessionProvider } from "./contexts/session";
import Theme from "./theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<SessionProvider>
			<Theme />
		</SessionProvider>
	</React.StrictMode>
);

serviceWorkerRegistration.register({
	onUpdate: registration => {
		const waitingServiceWorker = registration.waiting
		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener("statechange", event => {
				if (event.target.state === "activated") {
					console.log("Installed Update");
					alert("Installed Update");
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
		}
	}
});