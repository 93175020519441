import React from "react";
import { Certificate as CertificateClass } from "../classes/certificate";
import { PDFViewer } from "@react-pdf/renderer";
import { readItem } from "../api";

export default function Certificate(props) {
	const [certificate, setCertificate] = React.useState(null);
	const [customer, setCustomer] = React.useState(null);
	const sample = props.sample ? props.sample : null;
	const tenant = React.useMemo(() => props.tenant || null, [props.tenant]);
	const user = React.useMemo(() => props.user || null, [props.user]);
	React.useEffect(() => {
		let isMounted = true;
		(async () => {
			const customerData = await readItem(
				"customer",
				CertificateClass.getTenantIdShardFromCombinedId(props.customer),
				CertificateClass.getIdFromCombinedId(props.customer)
			);
			if (isMounted) setCustomer(customerData);
		})();
		return () => { isMounted = false; };
	}, [props.customer]);
	React.useEffect(() => {
		if (customer) {
			const certificateData = CertificateClass.generate(customer, sample, user, tenant, CertificateClass.ENVIRONMENT.DEV, true);
			setCertificate(certificateData);
		}
	}, [customer, sample, user, tenant]);
	if (certificate) {
		return (
			<PDFViewer showToolbar={true} width="100%" style={{ "border": "0", "borderRadius": "5px", "height": "calc(100vh - 160px)" }}>
				{certificate}
			</PDFViewer>
		);
	} else {
		return <div>Loading ...</div>;
	}
}