import { CheckCircle as GreenIcon, Circle as CircleIcon, Error as RedIcon, Pending as YellowIcon } from "@mui/icons-material";
import TextField from "./text-field";

export default function StatusField(props) {
	const status = props.status === "green" ? "green" : props.status === "red" ? "red" : "yellow";
	const statusColor = status === "green" ? "success.main" : status === "red" ? "error.main" : "warning.main";
	const StatusIcon = props.value === null ? null : status === "green" ? props.GreenIcon ? props.GreenIcon : GreenIcon : status === "red" ? props.RedIcon ? props.RedIcon : RedIcon : YellowIcon;
	return (
		<TextField {...props} labelIcon={CircleIcon} label={props.label ? props.label : "Status"} valueIcon={StatusIcon} valueColor={statusColor} />
	);
}