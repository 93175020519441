import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./app";
import { blue, green } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-license-pro";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Theme() {
	LicenseInfo.setLicenseKey("962235017b1fc641c4cdab13fa9ad42eTz04MDk4NCxFPTE3MzQ4MTk0NjIwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y");
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const theme = createTheme({
		components: {
			MuiButton: {
				defaultProps: {
					sx: {
						margin: 0
					}
				}
			},
			MuiCheckbox: {
				defaultProps: {
					size: "medium"
				}
			},
			MuiDataGrid: {
				styleOverrides: {
					filterForm: {
						flexWrap: "wrap",
						gap: "16px",
						maxWidth: "300px"
					},
					filterFormDeleteIcon: {
						display: "none"
					},
					filterFormColumnInput: {
						width: "100%"
					},
					filterFormOperatorInput: {
						width: "100%"
					},
					filterFormValueInput: {
						width: "100%"
					},
					footerCell: {
						color: "inherit"
					}
				}
			},
			MuiDialogActions: {
				defaultProps: {
					sx: {
						padding: 2
					}
				},
				styleOverrides: {
					root: {
						"& .MuiButton-root": {
							margin: "0 0 0 16px"
						}
					}
				}
			},
			MuiDialogContent: {
				defaultProps: {
					sx: {
						overflow: "visible",
						paddingTop: 0,
						paddingRight: 2,
						paddingBottom: 0,
						paddingLeft: 2
					}
				}
			},
			MuiDialogTitle: {
				defaultProps: {
					component: "h2",
					variant: "h5",
					fontSize: "24px",
					fontWeight: "normal",
					sx: {
						padding: 2
					}
				}
			},
			MuiFormControlLabel: {
				defaultProps: {
					sx: {
						margin: 0,
						width: "100%"
					}
				}
			},
			MuiLink: {
				defaultProps: {
					component: Link,
					underline: "hover"
				}
			},
			MuiTextField: {
				defaultProps: {
					fullWidth: true,
					sx: {
						margin: 0
					}
				}
			},
			MuiPaper: {
				defaultProps: {
					sx: {
						padding: 0
					}
				}
			}
		},
		palette: {
			mode: prefersDarkMode ? "dark" : "light",
			primary: {
				main: blue[700],
				light: blue[600],
				dark: blue[800]
			},
			secondary: {
				main: green[700],
				light: green[600],
				dark: green[800]
			}
		}
	});
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />
			<App />
		</ThemeProvider>
	);
}