import { Customer as CustomerClass } from "../classes/customer";
import EmailField from "./email-field";
import Items from "./items";
import PhoneField from "./phone-field";

export default function Customers(props) {
	const data = props.data;
	const columns = [
		{
			field: "email", type: "string", headerName: "Email", groupable: false, renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<EmailField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},
		{
			field: "phone", type: "string", headerName: "Phone", groupable: false, renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<PhoneField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return params.value;
			}
		}
	];
	const columnVisibilityModel = props.columnVisibilityModel ? props.columnVisibilityModel : {};
	if (columnVisibilityModel.phone === null || window.innerWidth < 300) columnVisibilityModel.phone = false;
	if (columnVisibilityModel.email === null || window.innerWidth < 600) columnVisibilityModel.email = false;
	return (
		<Items
			class={CustomerClass} data={data} columns={columns}
			columnVisibilityModel={columnVisibilityModel} filterName={props.filterName} filterValue={props.filterValue}
		/>
	);
}