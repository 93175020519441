import React from "react";
import AddressField from "./address-field";
import { Business as LabIcon } from "@mui/icons-material";
import Contacts from "./contacts";
import EmailField from "./email-field";
import Item, { handleChangeDebounced } from "./item";
import { Lab as LabClass } from "../classes/lab";
import PhoneField from "./phone-field";
import Tests from "./tests";
import { Unstable_Grid2 as Grid } from "@mui/material";

export const Icon = LabIcon;

export default function Lab(props) {
	const [data, setData] = React.useState(props.data ? props.data : {});
	const [isDirty, setIsDirty] = React.useState(false);
	const handleChange = React.useMemo(() => handleChangeDebounced(setData, null, setIsDirty), [setData, setIsDirty]);
	return (
		<Grid container spacing={2} sx={{ padding: 0 }}>
			<Grid xs={12} md={props.mode === "view" ? 6 : 12}>
				<Item class={LabClass} data={data} icon={LabIcon} isDirty={isDirty} mode={props.mode} onChange={handleChange}>
					<EmailField value={data.email} onChange={handleChange} mode={props.mode} />
					<PhoneField value={data.phone} onChange={handleChange} mode={props.mode} />
					<AddressField value={data.address} onChange={handleChange} mode={props.mode} />
					<Contacts data={data.contacts} onChange={handleChange} mode={props.mode} />
				</Item>
			</Grid>
			{props.mode === "view" &&
				<Grid xs={12} md={6}>
					<Tests
						filterName="tenantIdShardLabId"
						filterValue={LabClass.getCombinedIdFromInputs(data.tenantIdShard, data.id)}
						columnVisibilityModel={{
							"isGroup": false,
							"createdAt": false,
							"updatedAt": false
						}}
					/>
				</Grid>
			}
		</Grid>
	);
}
