import { Box, Paper, Typography } from '@mui/material';
import Footer from "../components/footer";
import Header from "../components/header";
import { setDocumentTitle } from "../router";
import Title from "../components/title";
import { useRouteError } from "react-router-dom";

export default function Error() {
	setDocumentTitle("Error");
	const error = useRouteError();
	console.error(error);
	return (
		<Box>
			<Header />
			<Paper elevation={1} sx={{ padding: 2, flexGrow: 1, minHeight: "calc(100vh - 128px)" }}>
				<Paper elevation={2} sx={{ padding: 2 }}>
					<Title>Error</Title>
					<Typography paragraph>{error.statusText || error.message}</Typography>
				</Paper>
			</Paper>
			<Footer />
		</Box>
	);
}