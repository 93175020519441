import { Certificate as CertificateClass } from "../classes/certificate";
import COANotificationStatusField from "./coa-notification-status-field";
import DateField from "./date-field";
import Subitem from "./subitem";

export default function COA(props) {
	return (
		<Subitem class={CertificateClass} nameReadOnly="true" data={props.data} callback={props.callback} handleClose={props.handleClose}>
		</Subitem>
	);
}

export function action(data) {
	if (data.file) {
		return data.file;
	} else if (data.bucket && data.key) { // for legacy file uploads
		return "https://" + data.bucket + "/" + data.key;
	}
}

export function media(data) {
	if (data.image) {
		return data.image;
	} else if (data.bucket && data.thumbnail) { // for legacy file uploads
		return "https://" + data.bucket + "/" + data.thumbnail;
	}
}

export function summarize(data) {
	return (
		<>
			{data.certifiedAt && <DateField label="Certified" value={data.certifiedAt} submode="box" />}
			<COANotificationStatusField name="notificationStatus" label="Notification" value={data.notificationStatus} submode="box" />
			{data.notifiedAt && <DateField label="Notified" value={data.notifiedAt} submode="box" />}
		</>
	);
}