import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

Title.propTypes = {
	children: PropTypes.node,
};

export default function Title(props) {
	const marginBottom = props.marginBottom ? props.marginBottom : 2;
	return (
		<Typography component="h2" variant="h5" sx={{ fontWeight: "normal", fontSize: "24px", marginBottom: marginBottom }}>{props.children}</Typography>
	);
}