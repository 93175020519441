import React from "react";
import { CalendarMonth as CalendarIcon, Grid3x3 as NumberIcon, Scale as SizeIcon, WorkspacePremium as CertifyIcon } from "@mui/icons-material";
import { Certificate as CertificateClass } from "../classes/certificate";
import Certificates from "./certificates";
import COANotificationStatusField from "./coa-notification-status-field";
import COAs from "./coas";
import ComboField from "./combo-field";
import { Customer as CustomerClass } from "../classes/customer";
import CurrencyField from "./currency-field";
import DateField, { getDateDisplay } from "./date-field";
import debounce from "lodash/debounce";
import { Icon as CustomerIcon } from "./customer";
import { Icon as TestIcon } from "./test";
import ImageField from "./image-field";
import Item, { handleChangeDebounced } from "./item";
import { Label as LabelIcon } from "@mui/icons-material";
import NoteField from "./note-field";
import NumberField from "./number-field";
import { print as printLabel } from "../printer-zebra";
import { readItem } from "../api";
import ReferenceField from "./reference-field";
import Results from "./results";
import ResultStatusField from "./result-status-field";
import { Sample as SampleClass } from "../classes/sample";
import SampleStorageField from "./sample-storage-field";
import SampleTypeField from "./sample-type-field";
import { Science as SampleIcon } from "@mui/icons-material";
import { Test as TestClass } from "../classes/test";
import TextField from "./text-field";
import { Unstable_Grid2 as Grid } from "@mui/material";
import useAlert from "../hooks/useAlert";
import useSession from "../hooks/useSession";

export const Icon = SampleIcon;

export default function Sample(props) {
	const [data, setData] = React.useState(props.data ? {
		...props.data,
		dateReceived: props.data.dateReceived ? props.data.dateReceived : "",
		results: props.data.results ? props.data.results : [],
		sizeRequired: props.data.sizeRequired ? props.data.sizeRequired : "0",
		testDateCompleted: props.data.testDateCompleted ? props.data.testDateCompleted : "",
		testDateDue: props.data.testDateDue ? props.data.testDateDue : "",
		testDateOutsourced: props.data.testDateOutsourced ? props.data.testDateOutsourced : "",
		testDateOutsourcedDue: props.data.testDateOutsourcedDue ? props.data.testDateOutsourcedDue : "",
		testFees: props.data.testFees ? props.data.testFees : "0",
		testOutsourcedTurnAroundDays: props.data.testOutsourcedTurnAroundDays ? props.data.testOutsourcedTurnAroundDays : "0",
		testPrice: props.data.testPrice ? props.data.testPrice : "0",
		testResult: props.data.testResult ? props.data.testResult : CertificateClass.RESULT_STATUS.PENDING,
		testTotal: props.data.testTotal ? props.data.testTotal : "0",
		testTurnAroundDays: props.data.testTurnAroundDays ? props.data.testTurnAroundDays : "0"
	} : {});
	const [isDirty, setIsDirty] = React.useState(false);
	const { session } = useSession();
	const { setAlert } = useAlert();
	const tenantId = session.tenant.id;
	const handleChange = React.useMemo(() => handleChangeDebounced(setData, null, setIsDirty), [setData, setIsDirty]);
	const handleBillingCustomerChange = React.useMemo(() => handleBillingCustomerChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleCoACustomersChange = React.useMemo(() => handleCoACustomersChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleResultsChange = React.useMemo(() => handleResultsChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleTestsChange = React.useMemo(() => handleTestsChangeDebounced(setData, setIsDirty, tenantId), [setData, setIsDirty, tenantId]);
	if (props.mode === "certify" && session.groups.includes("Manager")) {
		return (
			<Certificates data={data} />
		);
	} else {
		const handlePrintLabel = async () => {
			try {
				await printLabel(data);
				setAlert("success", "Label Printed");
			} catch (error) {
				const errorMessage = "Error Printing Label";
				console.error(errorMessage, error);
				setAlert("error", errorMessage + ": " + error.message);
			}
		};
		const handleFormSubmit = async (event) => {
			/*if (event.target.size?.value && event.target.sizeUOM?.value && data.sizeRequired) {
				const sizeRequiredTokens = data.sizeRequired.split(", ");
				for (const sizeRequiredToken of sizeRequiredTokens) {
					if (sizeRequiredToken === "" || sizeRequiredToken === "0" || sizeRequiredToken === 0) continue;
					const sizeRequiredTokenSplit = sizeRequiredToken.split(" ");
					if (sizeRequiredTokenSplit.length !== 2 || isNaN(sizeRequiredTokenSplit[0])) throw new Error("Invalid Testing Size Required");
					if (
						sizeRequiredTokenSplit[1].toLowerCase() === event.target.sizeUOM.value.toLowerCase() &&
						parseInt(event.target.size.value) < parseInt(sizeRequiredTokenSplit[0])
					) {
						throw new Error("Size Cannot Be Less Than Testing Size Required");
					}
				}
			}*/
			const presentDate = getDateDisplay();
			if (event.target.dateReceived?.value) {
				if (event.target.dateReceived.value > presentDate) throw new Error("Received Date Cannot Be In The Future");
				if (event.target.testDateDue?.value) {
					const soonestTestDateDue = getDateDisplay(getSoonestTestDateDue(
						event.target.dateReceived.value,
						data.testTurnAroundDays
					));
					if (event.target.testDateDue.value < soonestTestDateDue) {
						throw new Error(
							"Testing Due Date Cannot Be Before Received Date Plus Testing Turn Around Days"
						);
					}
				}
			}
			if (event.target.testDateOutsourced?.value) {
				if (event.target.testDateOutsourced.value > presentDate) throw new Error("Testing Date Outsourced Cannot Be In The Future");
				if (event.target.testDateOutsourcedDue?.value) {
					const soonestTestDateOutsourcedDue = getDateDisplay(getSoonestTestDateDue(
						event.target.testDateOutsourced.value,
						data.testOutsourcedTurnAroundDays
					));
					if (event.target.testDateOutsourcedDue.value < soonestTestDateOutsourcedDue) {
						throw new Error(
							"Testing Date Outsourced Due Cannot Be Before Testing Date Outsourced Plus Testing Outsourced Turn Around Days"
						);
					}
				}
			}
		};
		return (
			<Grid container spacing={2} sx={{ padding: 0 }}>
				<Grid xs={12}>
					<Item
						action={[
							{
								title: "Print Label",
								onClick: () => handlePrintLabel(),
								icon: <LabelIcon />,
							},
							session.groups.includes("Manager") ? {
								title: "Certify",
								link: "/" + SampleClass.getType() + "/certify/" + data.tenantIdShard + SampleClass.COMBINED_ID_DELIMITER + data.id,
								icon: <CertifyIcon />
							} : null
						]}
						class={SampleClass}
						data={data}
						icon={SampleIcon}
						isDirty={isDirty}
						mode={props.mode}
						onChange={handleChange}
						onSubmitForm={handleFormSubmit}
					>
						{
							(data.createdAt || data.updatedAt) &&
							<TextField
								label="Code"
								labelIcon={NumberIcon}
								mode="view"
								value={CertificateClass.getCode(data.createdAt, data.updatedAt)}
							/>
						}
						<TextField
							labelIcon={NumberIcon}
							label="Lot Number"
							mode={props.mode}
							name="lotNumber"
							onChange={handleChange}
							value={data.lotNumber}
						/>
						<ReferenceField
							class={CustomerClass}
							icon={CustomerIcon}
							id={data.id}
							idReference={data.tenantIdShardCustomerIdReference}
							label="Billing Customer"
							mode={props.mode}
							onChange={handleBillingCustomerChange}
							required="true"
						/>
						<ImageField
							inputProps={{ accept: "image/jpeg, image/png" }}
							label="Picture"
							mode={props.mode}
							name="picture"
							onChange={handleChange}
							value={data.picture}
						/>
						<SampleTypeField
							mode={props.mode}
							onChange={handleChange}
							value={data.type}
						/>
						<ComboField
							labelIcon={SizeIcon}
							label="Size"
							mode={props.mode}
							name="size"
							onChange={handleChange}
							onQualifierChange={handleChange}
							qualifierLabel="Unit of Measurement"
							qualifierName="sizeUOM"
							qualifierValue={data.sizeUOM}
							value={data.size ? data.size : "0"}
						/>
						{/*<TextField
							label="Size Required"
							labelIcon={SizeIcon}
							mode="view"
							name="sizeRequired"
							value={data.sizeRequired ? data.sizeRequired : "0"}
						/>*/}
						<SampleStorageField
							mode={props.mode}
							onChange={handleChange}
							value={data.storage}
						/>
						<DateField
							label="Received"
							mode={props.mode}
							name="dateReceived"
							onChange={handleChange}
							value={data.dateReceived}
							valueDefault="true"
						/>
						<NumberField
							label="Testing Turn Around"
							labelIcon={CalendarIcon}
							mode="view"
							name="testTurnAroundDays"
							unit="day"
							value={data.testTurnAroundDays}
						/>
						<DateField
							label="Testing Outsourced"
							mode="view"
							name="testDateOutsourced"
							value={data.testDateOutsourced}
						/>
						<NumberField
							label="Testing Outsourced Turn Around"
							labelIcon={CalendarIcon}
							mode="view"
							name="testOutsourcedTurnAroundDays"
							unit="day"
							value={data.testOutsourcedTurnAroundDays}
						/>
						<DateField
							label="Testing Outsourced Due"
							mode="view"
							name="testDateOutsourcedDue"
							value={data.testDateOutsourcedDue}
						/>
						<DateField
							label="Testing Due"
							min={data.dateReceived}
							mode={props.mode}
							name="testDateDue"
							onChange={handleChange}
							value={data.testDateDue}
						/>
						<ReferenceField
							class={TestClass}
							icon={TestIcon}
							id={data.id}
							idReferences={data.tenantIdShardTestIdReferences}
							mode={props.mode}
							multiple="true"
							onChange={(item, isDefault) => handleTestsChange(item, isDefault, data)}
							required="true"
						/>
						<Results
							data={data.results}
							minDate={data.dateReceived}
							mode={props.mode}
							onChange={handleResultsChange}
						/>
						<DateField
							label="Testing Completed"
							mode="view"
							name="testDateCompleted"
							value={data.testDateCompleted}
						/>
						<ResultStatusField
							name="testResult"
							value={data.testResult}
						/>
						<CurrencyField
							label="Testing Price Subtotal (USD)"
							mode="view"
							name="testPrice"
							value={data.testPrice}
						/>
						<CurrencyField
							label="Testing Fees Subtotal (USD)"
							mode="view"
							name="testFees"
							value={data.testFees}
						/>
						<CurrencyField
							label="Testing Price & Fees Total (USD)"
							mode="view"
							name="testTotal"
							value={data.testTotal}
						/>
						<TextField
							label="Purchase Order Number"
							mode={props.mode}
							name="purchaseOrderNumber"
							onChange={handleChange}
							value={data.purchaseOrderNumber}
						/>
						<ReferenceField
							class={CustomerClass}
							icon={CustomerIcon}
							id={data.id}
							idReferences={data.tenantIdShardCustomerIdReferences}
							label="Certificate of Analysis Customers"
							mode={props.mode}
							multiple="true"
							onChange={handleCoACustomersChange}
						/>
						<NoteField
							label="Certificate of Analysis Note (external)"
							mode={props.mode}
							name="coaNote"
							onChange={handleChange}
							value={data.coaNote}
						/>
						<COAs
							data={data.coas}
							mode={props.mode}
							tenantIdShardItemId={data.tenantIdShard + SampleClass.COMBINED_ID_DELIMITER + data.id}
						/>
						<COANotificationStatusField
							name="coaNotificationStatus"
							value={data.coaNotificationStatus}
						/>
					</Item>
				</Grid>
			</Grid>
		);
	}
}

async function addTestToResults(tenantIdShardTestId, oldTestResults, newTestResults, tenantId, inGroup) {
	//console.log("addTestToResults", tenantIdShardTestId, oldTestResults, newTestResults, tenantId, inGroup);
	let newTestResult = newTestResults.find((newTestResult) => newTestResult.id === tenantIdShardTestId);
	if (!newTestResult) {
		const oldTestResult = oldTestResults.find((oldTestResult) => oldTestResult.id === tenantIdShardTestId);
		if (oldTestResult) {
			newTestResult = oldTestResult;
			newTestResults.push(newTestResult);
			if (newTestResult.isGroup === "on") {
				if (newTestResult.tenantIdShardTestIds && newTestResult.tenantIdShardTestIds.length) {
					for (const tenantIdShardChildTestId of newTestResult.tenantIdShardTestIds) {
						await addTestToResults(tenantIdShardChildTestId, oldTestResults, newTestResults, tenantId, "on");
					}
				}
			}
		} else {
			const test = await readItem(
				"test",
				SampleClass.getTenantIdShardFromCombinedId(tenantIdShardTestId),
				SampleClass.getIdFromCombinedId(tenantIdShardTestId)
			);
			if (test.isGroup === "on") {
				newTestResult = {
					id: tenantIdShardTestId,
					name: test.name,
					tenantIdShardLabId: test.tenantIdShardLabId,
					tenantIdShardLabIdReference: test.tenantIdShardLabIdReference,
					price: test.price,
					type: test.type,
					sampleSizeRequired: test.sampleSizeRequired,
					sampleSizeRequiredUOM: test.sampleSizeRequiredUOM,
					inGroup: inGroup,
					isGroup: test.isGroup,
					tenantIdShardTestIds: test.tenantIdShardTestIds,
					tenantIdShardTestIdReferences: test.tenantIdShardTestIdReferences,
					coaNote: test.coaNote
				};
				newTestResults.push(newTestResult);
				if (test.tenantIdShardTestIds && test.tenantIdShardTestIds.length) {
					for (const tenantIdShardChildTestId of test.tenantIdShardTestIds) {
						await addTestToResults(tenantIdShardChildTestId, oldTestResults, newTestResults, tenantId, "on");
					}
				}
			} else {
				const sharedQualifierValue = test.specificationValueUOM || test.detectionLimitUOM || "";
				newTestResult = {
					id: tenantIdShardTestId,
					name: test.name,
					tenantIdShardLabId: test.tenantIdShardLabId,
					tenantIdShardLabIdReference: test.tenantIdShardLabIdReference,
					type: test.type,
					inGroup: inGroup,
					method: test.method,
					specification: test.specification,
					specificationRange: test.specificationRange,
					specificationRangeValue: test.specificationRangeValue,
					specificationRangeValueUOM: test.specificationRangeValueUOM,
					specificationResult: CertificateClass.RESULT_STATUS.PENDING,
					specificationValue: test.specificationValue,
					specificationValueUOM: sharedQualifierValue,
					detectionLimit: test.detectionLimit,
					detectionLimitUOM: sharedQualifierValue,
					turnAroundDays: test.turnAroundDays,
					detectionResultUOM: sharedQualifierValue,
					coaNote: test.coaNote
				};
				if (TestClass.getDefaultTenantIdShardLabId(tenantId) !== test.tenantIdShardLabId) {
					newTestResult.dateOutsourced = new Date().toISOString();
					newTestResult.dateOutsourcedDue = getSoonestTestDateDue(newTestResult.dateOutsourced, test.turnAroundDays);
				}
				if (inGroup !== "on") {
					newTestResult.price = test.price;
					newTestResult.sampleSizeRequired = test.sampleSizeRequired;
					newTestResult.sampleSizeRequiredUOM = test.sampleSizeRequiredUOM;
				}
				newTestResults.push(newTestResult);
			}
		}
	}
}

async function changeResults(oldData, newData) {
	//console.log("changeResults", oldData, newData);
	//newData.sizeRequired = [];
	newData.testDateCompleted = "";
	newData.testDateDue = "";
	newData.testDateOutsourced = "";
	newData.testDateOutsourcedDue = "";
	newData.testFees = 0;
	newData.testOutsourcedTurnAroundDays = 0;
	newData.testPrice = 0;
	newData.testResult = CertificateClass.RESULT_STATUS.PENDING;
	newData.testTotal = 0;
	newData.testTurnAroundDays = 0;
	let summaryTestDateCompletedPending = false;
	let summaryTestResultFailed = false;
	let summaryTestResultPending = false;
	for (const result of newData.results) {
		if (result.isGroup !== "on") {
			if (result.dateCompleted) {
				if (newData.testDateCompleted === "") {
					newData.testDateCompleted = result.dateCompleted;
				} else if (newData.testDateCompleted < result.dateCompleted) {
					newData.testDateCompleted = result.dateCompleted;
				}
			} else {
				summaryTestDateCompletedPending = true;
			}
			if (result.specificationResult) {
				if (result.specificationResult === CertificateClass.RESULT_STATUS.FAILED) {
					summaryTestResultFailed = true;
				} else if (result.specificationResult === CertificateClass.RESULT_STATUS.PENDING) {
					summaryTestResultPending = true;
				}
			} else {
				summaryTestResultPending = true;
			}
		}
	}
	for (const result of newData.results) {
		if (result.isGroup === "on") {
			let groupDateCompleted = "";
			let groupDateCompletedPending = false;
			let groupResultFailed = false;
			let groupResultPending = false;
			if (result.tenantIdShardTestIds && result.tenantIdShardTestIds.length) {
				for (const tenantIdShardChildTestId of result.tenantIdShardTestIds) {
					const groupResults = newData.results.filter((result) => result.id === tenantIdShardChildTestId);
					for (const groupResult of groupResults) {
						if (groupResult.dateCompleted) {
							if (groupDateCompleted === "") {
								groupDateCompleted = groupResult.dateCompleted;
							} else if (groupDateCompleted < groupResult.dateCompleted) {
								groupDateCompleted = groupResult.dateCompleted;
							}
						} else {
							groupDateCompletedPending = true;
						}
						if (groupResult.specificationResult) {
							if (groupResult.specificationResult === CertificateClass.RESULT_STATUS.FAILED) {
								groupResultFailed = true;
							} else if (groupResult.specificationResult === CertificateClass.RESULT_STATUS.PENDING) {
								groupResultPending = true;
							}
						} else {
							groupResultPending = true;
						}
					}
				}
			}
			if (groupDateCompletedPending) groupDateCompleted = "";
			result.dateCompleted = groupDateCompleted;
			if (groupResultFailed) {
				result.specificationResult = CertificateClass.RESULT_STATUS.FAILED;
				summaryTestResultFailed = true;
			} else if (!result.tenantIdShardTestIds || !result.tenantIdShardTestIds.length || groupResultPending) {
				result.specificationResult = CertificateClass.RESULT_STATUS.PENDING;
				summaryTestResultPending = true;
			} else {
				result.specificationResult = CertificateClass.RESULT_STATUS.PASSED;
			}
		}
	}
	for (const result of newData.results) {
		/*if (result.sampleSizeRequired) {
			newData.sizeRequired = mapResults(newData.sizeRequired, result.sampleSizeRequiredUOM, result.sampleSizeRequired);
		}*/
		if (result.dateOutsourced) {
			const testDateOutsourced = result.dateOutsourced;
			if (!newData.testDateOutsourced || testDateOutsourced < newData.testDateOutsourced) {
				newData.testDateOutsourced = testDateOutsourced;
			}
		}
		if (result.turnAroundDays) {
			const testTurnAroundDays = parseInt(result.turnAroundDays);
			if (testTurnAroundDays > newData.testTurnAroundDays) newData.testTurnAroundDays = testTurnAroundDays;
			if (result.dateOutsourced) {
				if (testTurnAroundDays > newData.testOutsourcedTurnAroundDays) newData.testOutsourcedTurnAroundDays = testTurnAroundDays;
			}
		}
		if (result.dateOutsourcedDue) {
			const testDateOutsourcedDue = result.dateOutsourcedDue;
			if (!newData.testDateOutsourcedDue || testDateOutsourcedDue > newData.testDateOutsourcedDue) {
				newData.testDateOutsourcedDue = testDateOutsourcedDue;
			}
		}
		if (result.fee) {
			const testFee = parseFloat(result.fee);
			newData.testFees += testFee;
			newData.testTotal += testFee;
		}
		if (result.price) {
			const testPrice = parseFloat(result.price);
			newData.testPrice += testPrice;
			newData.testTotal += testPrice;
		}
	}
	//newData.sizeRequired = reduceResults(newData.sizeRequired);
	//newData.sizeRequired = newData.sizeRequired ? newData.sizeRequired : "0";
	newData.testTurnAroundDays = newData.testTurnAroundDays ? newData.testTurnAroundDays : "0";
	newData.testDateOutsourced = newData.testDateOutsourced ? newData.testDateOutsourced : "";
	newData.testOutsourcedTurnAroundDays = newData.testOutsourcedTurnAroundDays ? newData.testOutsourcedTurnAroundDays : "0";
	newData.testDateOutsourcedDue = newData.testDateOutsourcedDue ? newData.testDateOutsourcedDue
		: getSoonestTestDateDue(newData.testDateOutsourced, newData.testOutsourcedTurnAroundDays);
	newData.testDateDue = oldData.testDateDue ? oldData.testDateDue
		: getSoonestTestDateDue(oldData.dateReceived, newData.testTurnAroundDays);
	if (newData.testDateDue < newData.testDateOutsourcedDue) newData.testDateDue = newData.testDateOutsourcedDue;
	newData.testDateCompleted = summaryTestDateCompletedPending ? "" : newData.testDateCompleted;
	newData.testFees = newData.testFees ? newData.testFees.toFixed(2) : "0";
	newData.testPrice = newData.testPrice ? newData.testPrice.toFixed(2) : "0";
	newData.testTotal = newData.testTotal ? newData.testTotal.toFixed(2) : "0";
	if (summaryTestResultFailed) {
		newData.testResult = CertificateClass.RESULT_STATUS.FAILED;
	} else if (!newData.results || !newData.results.length || summaryTestResultPending) {
		newData.testResult = CertificateClass.RESULT_STATUS.PENDING;
	} else {
		newData.testResult = CertificateClass.RESULT_STATUS.PASSED;
	}
}

function getSoonestTestDateDue(dateReceived, testTurnAroundDays) {
	let soonestTestDateDue = dateReceived;
	if (testTurnAroundDays && testTurnAroundDays !== "0") {
		testTurnAroundDays = parseInt(testTurnAroundDays);
		soonestTestDateDue = new Date(soonestTestDateDue);
		soonestTestDateDue.setDate(soonestTestDateDue.getDate() + testTurnAroundDays);
		soonestTestDateDue = soonestTestDateDue.toISOString();
	}
	return soonestTestDateDue;
}

function handleBillingCustomerChangeDebounced(setData, setIsDirty) {
	return debounce((billingCustomer, isDefault = false) => {
		//console.log("handleBillingCustomerChangeDebounced", billingCustomer, isDefault);
		setData((previousData) => ({
			...previousData,
			tenantIdShardCustomerId: billingCustomer?.getCombinedId(),
			tenantIdShardCustomerIdReference: billingCustomer?.getCombinedId(true, billingCustomer?.context)
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}

function handleCoACustomersChangeDebounced(setData, setIsDirty) {
	return debounce((coaCustomers, isDefault = false) => {
		//console.log("handleCoACustomersChangeDebounced", coaCustomers, isDefault);
		setData((previousData) => ({
			...previousData,
			tenantIdShardCustomerIds: coaCustomers.map((coaCustomer) => coaCustomer.getCombinedId()),
			tenantIdShardCustomerIdReferences: coaCustomers.map((coaCustomer) => coaCustomer.getCombinedId(true, coaCustomer?.context))
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}

function handleResultsChangeDebounced(setData, setIsDirty) {
	return debounce((event) => {
		//console.log("handleResultsChangeDebounced", event.target.name, event.target.value, event.target.isDefault);
		setData((previousData) => {
			const newData = { results: event.target.value };
			changeResults(previousData, newData);
			return {
				...previousData,
				...newData
			};
		});
		if (setIsDirty && !event.target.isDefault) setIsDirty(true);
	}, 300);
}

function handleTestsChangeDebounced(setData, setIsDirty, tenantId) {
	return debounce(async (tests, isDefault = false, oldData) => {
		//console.log("handleTestsChangeDebounced", tests, isDefault);
		const newData = {
			results: [],
			tenantIdShardTestIds: tests.map((test) => test.getCombinedId()),
			tenantIdShardTestIdReferences: tests.map((test) => test.getCombinedId(true, test?.context))
		};
		for (const test of tests) {
			await addTestToResults(test.getCombinedId(), oldData.results, newData.results, tenantId);
		}
		changeResults(oldData, newData);
		setData((previousData) => ({
			...previousData,
			...newData
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}

/*function mapResults(mappedTestResults, key, value, float) {
	const index = mappedTestResults.findIndex((mappedTestResultsSub) => {
		return mappedTestResultsSub.key === key;
	});
	if (index === -1) {
		mappedTestResults.push({
			key: key,
			value: float ? parseFloat(value) : parseInt(value)
		});
	} else {
		mappedTestResults[index].value += float ? parseFloat(value) : parseInt(value);
	}
	return mappedTestResults;
}

function reduceResults(mappedTestResults, decimals) {
	let result = "";
	mappedTestResults.sort((a, b) => {
		if (a.key < b.key) return -1;
		if (a.key > b.key) return 1;
		return 0;
	});
	for (const mappedTestResultsSub of mappedTestResults) {
		if (result !== "") result += ", ";
		result += (decimals ? mappedTestResultsSub.value.toFixed(decimals) : mappedTestResultsSub.value);
		if (mappedTestResultsSub.key) result += " " + mappedTestResultsSub.key;
	}
	return result;
}*/