const { Item } = require("./item.js");

class Test extends Item {

	static className = "Test";
	static history = true;
	static idIndex = "id3";
	static idPlusIndex = "idPlus3";
	static indexes = [...Item.indexes, Test.idIndex, Test.idPlusIndex];
	static key = [...Item.key, "tenantIdShard"];
	static required = [...Item.required, "tenantIdShardLabId", "tenantIdShardLabIdReference"];
	static tableName = "LabAxiomTest";

	constructor() {
		super();
		this.coaNote = undefined;
		this.cost = undefined;
		this.detectionLimit = undefined;
		this.detectionLimitUOM = undefined;
		this.isGroup = undefined;
		this.method = undefined;
		this.price = undefined;
		this.sampleSizeRequired = undefined;
		this.sampleSizeRequiredUOM = undefined;
		this.specification = undefined;
		this.specificationRange = undefined;
		this.specificationRangeValue = undefined;
		this.specificationRangeValueUOM = undefined;
		this.specificationValue = undefined;
		this.specificationValueUOM = undefined;
		this.tenantIdShard = undefined;
		this.tenantIdShardLabId = undefined;
		this.tenantIdShardLabIdReference = undefined;
		this.tenantIdShardTestIdReferences = undefined;
		this.tenantIdShardTestIds = undefined;
		this.turnAroundDays = undefined;
		this.type = undefined;
	}

	static getDefaultTenantIdShardLabId(tenantId) {
		return tenantId + Test.COMBINED_ID_DELIMITER + 0 + Test.COMBINED_ID_DELIMITER + tenantId;
	}

	static getDefaultTenantIdShardLabIdReference(tenantId, tenantName) {
		return Test.getDefaultTenantIdShardLabId(tenantId) + Test.COMBINED_ID_DELIMITER + tenantName;
	}

	getReference() {
		return Test.getReferenceFromContext(this.tenantIdShard, this.id, this.name, this.isGroup, this.method, this.tenantIdShardLabIdReference);
	}

	static getReferenceFromContext(tenantIdShard, id, name, isGroup, method, tenantIdShardLabIdReference) {
		let context = "";
		if (isGroup) {
			context += "Group";
		} else if (method) {
			context += method;
		}
		if (tenantIdShardLabIdReference) {
			const labId = Test.getIdFromCombinedId(tenantIdShardLabIdReference);
			if (!tenantIdShard.startsWith(labId)) {
				if (context) context += ", ";
				context += Test.getNameFromCombinedId(tenantIdShardLabIdReference);
			}
		}
		return super.getReferenceFromInput(tenantIdShard, id, name, context);
	}

	static getReferenceFromData(data) {
		return Test.getReferenceFromContext(data.tenantIdShard, data.id, data.name, data.isGroup, data.method, data.tenantIdShardLabIdReference);
	}

}

export { Test };