import React from "react";

const AlertContext = React.createContext();
export default AlertContext;

export const AlertProvider = ({ children }) => {
	const [severity, setSeverity] = React.useState("");
	const [text, setText] = React.useState("");
	const setAlert = (severity, text) => {
		setSeverity(severity);
		setText(text);
	};
	return (
		<AlertContext.Provider value={{ severity, text, setAlert }}>
			{children}
		</AlertContext.Provider>
	);
};