import TextField from "./text-field";
import { Widgets as TypeIcon } from "@mui/icons-material";

export default function SampleTypeField(props) {
	return (
		<TextField {...props} name="type" labelIcon={TypeIcon} label="Type" valueDefault="">
			<option value=""></option>
			<option value="Capsule">Capsule</option>
			<option value="Gelcap">Gelcap</option>
			<option value="Gummy">Gummy</option>
			<option value="Liquid">Liquid</option>
			<option value="Powder">Powder</option>
			<option value="Solid">Solid</option>
		</TextField>
	);
}