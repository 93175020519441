const { v4: uuidv4 } = require("uuid");

class Item {

	static #tenantIdShardCountMax = 10;
	static className = "Item";
	static COMBINED_ID_DELIMITER = "|";
	static history = false;
	static idIndex = null;
	static idPlusIndex = null;
	static indexes = [];
	static key = ["id"];
	static NEW_ID = "new";
	static readonly = ["createdAt", "createdBy"];
	static required = ["name"];

	static ENVIRONMENT = {
		PROD: "prod",
		DEV: "dev"
	};

	constructor() {
		this.createdAt = undefined;
		this.createdBy = undefined;
		this.id = undefined;
		this.name = undefined;
		this.note = undefined;
		this.updatedAt = undefined;
		this.updatedBy = undefined;
	}

	static getBaseUrl(environment) {
		return (environment ? "https://app" + (environment === Item.ENVIRONMENT.PROD ? "" : "-dev") + ".labaxiom.com" : "");
	}

	getCombinedId(name = false, context) {
		if (name && !context && this.context) context = this.context;
		return Item.getCombinedIdFromInputs(this.tenantIdShard, this.id, (name ? this.name : null), context);
	}

	static getCombinedIdFromInputs(tenantIdShard, id, name, context) {
		let combinedId = Item.NEW_ID;
		if (id && id !== Item.NEW_ID) {
			combinedId = id;
			if (tenantIdShard) combinedId = tenantIdShard + Item.COMBINED_ID_DELIMITER + combinedId;
			if (name) combinedId += Item.COMBINED_ID_DELIMITER + Item.getNameContextFromInput(name, context);
		}
		return combinedId;
	}

	static getContextFromCombinedId(combinedId) {
		let context = "";
		const nameContext = Item.getNameContextFromCombinedId(combinedId);
		const indexOfOpenBracket = nameContext.indexOf(" [");
		if (indexOfOpenBracket > -1) context = nameContext.substring(indexOfOpenBracket + 1);
		return context;
	}

	static getContextFromInput(context) {
		return (context ? context.replaceAll("[", "").replaceAll("]", "") : "");
	}

	getEditUrl(environment) {
		return this.constructor.getEditUrlFromInputs(this.tenantIdShard, this.id, environment);
	}

	static getEditUrlFromInputs(tenantIdShard, id, environment) {
		return Item.getBaseUrl(environment) + "/" + this.getType() + "/edit/" + Item.getCombinedIdFromInputs(tenantIdShard, id);
	}

	static getIdFromCombinedId(combinedId) {
		if (combinedId === Item.NEW_ID) return combinedId;
		return combinedId.split(Item.COMBINED_ID_DELIMITER)[2];
	}

	static getIdIndex() {
		return this.idIndex;
	}

	static getIdPlusIndex() {
		return this.idPlusIndex;
	}

	static getNameContextFromCombinedId(combinedId) {
		return combinedId.split(Item.COMBINED_ID_DELIMITER)[3];
	}

	static getNameContextFromInput(name, context) {
		let nameContext = Item.getNameFromInput(name);
		if (context) nameContext += " [" + Item.getContextFromInput(context) + "]";
		return nameContext;
	}

	static getNameFromCombinedId(combinedId) {
		const nameContext = Item.getNameContextFromCombinedId(combinedId);
		return nameContext.split(" [")[0];
	}

	static getNameFromInput(name) {
		return (name ? name.replaceAll(Item.COMBINED_ID_DELIMITER, " ") : "");
	}

	getReference(context) {
		return this.constructor.getReferenceFromInput(this.tenantIdShard, this.id, this.name, context);
	}

	static getReferenceFromCombinedId(combinedId) {
		const tenantIdShard = Item.getTenantIdShardFromCombinedId(combinedId);
		const id = Item.getIdFromCombinedId(combinedId);
		const name = Item.getNameFromCombinedId(combinedId);
		const context = Item.getContextFromCombinedId(combinedId);
		return this.getReferenceFromInput(tenantIdShard, id, name, context);
	}

	static getReferenceFromData(data, context) {
		return this.getReferenceFromInput(data.tenantIdShard, data.id, data.name, context);
	}

	static getReferenceFromInput(tenantIdShard, id, name, context) {
		const reference = new Item();
		reference.tenantIdShard = tenantIdShard;
		reference.id = id;
		reference.name = Item.getNameFromInput(name);
		reference.context = Item.getContextFromInput(context);
		reference.nameContext = Item.getNameContextFromInput(name, context);
		reference.viewUrl = this.getViewUrlFromInputs(tenantIdShard, id);
		return reference;
	}

	static getShardFromCombinedId(combinedId) {
		if (combinedId === Item.NEW_ID) return null;
		return combinedId.split(Item.COMBINED_ID_DELIMITER)[1];
	}

	static getTenantIdFromCombinedId(combinedId) {
		if (combinedId === Item.NEW_ID) return null;
		return combinedId.split(Item.COMBINED_ID_DELIMITER)[0];
	}

	static getTenantIdShardFromCombinedId(combinedId) {
		if (combinedId === Item.NEW_ID) return null;
		return Item.getTenantIdFromCombinedId(combinedId) + Item.COMBINED_ID_DELIMITER + Item.getShardFromCombinedId(combinedId);
	}

	static getTenantIdShardPrefixFromInput(tenantId) {
		return tenantId + Item.COMBINED_ID_DELIMITER;
	}

	static getType(plural = false) {
		return plural ? this.className.toLowerCase() + "s" : this.className.toLowerCase();
	}

	static getTypeTitle(plural = false) {
		return plural ? this.className + "s" : this.className;
	}

	getViewUrl(environment) {
		return this.constructor.getViewUrlFromInputs(this.tenantIdShard, this.id, environment);
	}

	static getViewUrlFromInputs(tenantIdShard, id, environment) {
		return Item.getBaseUrl(environment) + "/" + this.getType() + "/view/" + Item.getCombinedIdFromInputs(tenantIdShard, id);
	}

	static #generateId() {
		return uuidv4();
	}

	static #generateTenantIdShard(tenantId) {
		return Item.getTenantIdShardPrefixFromInput(tenantId) + Math.floor(Math.random() * Item.#tenantIdShardCountMax);
	}

	initialize(data, server = false) {
		if (!data || !data.userId || !data.tenantId) throw new Error("User ID and Tenant ID Are Required");
		const timestamp = new Date().toISOString();
		for (const property in this) {
			if (this.hasOwnProperty(property)) {
				if (this.constructor.key.includes(property)) {
					if (property === "id") {
						if (data.id) {
							this.id = data.id;
						} else if (server) {
							this.id = Item.#generateId();
						}
					} else if (property === "tenantIdShard") {
						if (data.id) {
							if (!data.tenantIdShard) throw new Error("Tenant ID Shard Is Required");
							if (!data.tenantIdShard.startsWith(Item.getTenantIdShardPrefixFromInput(data.tenantId))) {
								throw new Error("Tenant ID Shard Prefix Is Invalid");
							}
							this.tenantIdShard = data.tenantIdShard;
						} else if (server) {
							this.tenantIdShard = Item.#generateTenantIdShard(data.tenantId);
						}
					} else {
						if (!data[property]) throw new Error(property + " Is Required");
						this[property] = data[property];
					}
				} else if (property === "createdAt" && !data.id && server) {
					this.createdAt = timestamp;
				} else if (property === "createdBy" && !data.id && server) {
					this.createdBy = data.userId;
				} else if (property === "updatedAt" && server) {
					this.updatedAt = timestamp;
				} else if (property === "updatedBy" && server) {
					this.updatedBy = data.userId;
				} else {
					this[property] = data[property];
				}
			}
		}
	}

	validate() {
		for (const property in this) {
			if (this.hasOwnProperty(property)) {
				if (this.constructor.required.includes(property) && !this[property]) throw new Error(property + " Is Required");
			}
		}
	}

}

export { Item };