import React from "react";
import { InputAdornment, TextField as MuiTextField, Unstable_Grid2 as Grid } from "@mui/material";
import PropTypes from "prop-types";
import Field from "./field";

TextField.propTypes = {
	children: PropTypes.node
};

export default function TextField(props) {
	const autoFocus = props.autoFocus === "true" ? true : false;
	const onBlur = props.onBlur ? props.onBlur : null;
	const onChange = props.onChange ? props.onChange : null;
	const required = props.required === "true" ? true : false;
	const rows = props.rows ? parseInt(props.rows) : 1;
	const [value, setValue] = React.useState("");
	React.useEffect(() => {
		if (props.value || props.value === 0) {
			setValue(props.value);
		} else if (props.valueDefault || props.valueDefault === 0) {
			if (onChange) onChange({ target: { name: props.name, value: props.valueDefault, isDefault: true } });
			setValue(props.valueDefault);
		} else {
			setValue("");
		}
	}, [onChange, props.name, props.value, props.valueDefault]);
	const handleBlur = (event) => {
		if (onBlur) onBlur(event);
	};
	const handleChange = (event) => {
		if (onChange) onChange(event);
		setValue(event.target.value);
	};
	if (props.mode === "edit") {
		if (props.submode === "naked") {
			return TextFieldEditNaked(props, autoFocus, required, rows, value, handleBlur, handleChange);
		} else {
			return TextFieldEditGrid(props, autoFocus, required, rows, value, handleBlur, handleChange);
		}
	} else {
		return (
			<Field {...props} />
		);
	}
}

function TextFieldEditGrid(props, autoFocus, required, rows, value, handleBlur, handleChange) {
	return (
		<Grid xs={12}>
			{TextFieldEditNaked(props, autoFocus, required, rows, value, handleBlur, handleChange, true)}
		</Grid>
	);
}

function TextFieldEditNaked(props, autoFocus, required, rows, value, handleBlur, handleChange, complex) {
	const InputProps = props.InputProps ? props.InputProps : {};
	if (!complex) InputProps.disableUnderline = true;
	if (props.children?.length > 0) InputProps.startAdornment = <InputAdornment position="start" sx={{ marginRight: 0 }}></InputAdornment>;
	const label = complex ? props.label : null;
	const variant = complex ? "outlined" : "standard";
	if (props.children?.length > 0) {
		return (
			<MuiTextField
				autoFocus={autoFocus}
				inputProps={props.inputProps}
				InputProps={InputProps}
				label={label}
				name={props.name}
				onBlur={handleBlur}
				onChange={handleChange}
				ref={props.propagateRef}
				required={required}
				select
				SelectProps={{ native: true }}
				type="text"
				value={value}
				variant={variant}
			>
				{props.children}
			</MuiTextField>
		);
	} else if (props.type === "file") {
		return (
			<MuiTextField
				autoFocus={autoFocus}
				inputProps={props.inputProps}
				InputProps={InputProps}
				label={label}
				name={props.name}
				onBlur={handleBlur}
				onChange={handleChange}
				ref={props.propagateRef}
				required={required}
				type="file"
				variant={variant}
			/>
		);
	} else {
		return (
			<MuiTextField
				autoFocus={autoFocus}
				inputProps={props.inputProps}
				InputProps={InputProps}
				label={label}
				multiline={rows > 1 ? true : false}
				name={props.name}
				onBlur={handleBlur}
				onChange={handleChange}
				onWheel={(e) => e.target.blur()}
				ref={props.ref}
				required={required}
				rows={rows}
				type={props.type}
				value={value}
				variant={variant}
			/>
		);
	}
}