import React from "react";
import { Biotech as TestIcon } from "@mui/icons-material";
import { CalendarMonth as CalendarIcon, Scale as SizeIcon, Sensors as MethodIcon, Widgets as TypeIcon } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import ComboField from "./combo-field";
import CurrencyField from "./currency-field";
import debounce from "lodash/debounce";
import { Icon as LabIcon } from "./lab";
import Item, { handleChangeDebounced } from "./item";
import { Lab as LabClass } from "../classes/lab";
import NoteField from "./note-field";
import NumberField from "./number-field";
import ReferenceField from "./reference-field";
import Samples from "./samples";
import SpecificationField from "./specification-field";
import { Test as TestClass } from "../classes/test";
import TextField from "./text-field";
import useSession from "../hooks/useSession";

export const Icon = TestIcon;

export default function Test(props) {
	const [data, setData] = React.useState(props.data ? props.data : {});
	const [isDirty, setIsDirty] = React.useState(false);
	const { session } = useSession();
	const handleChange = React.useMemo(() => handleChangeDebounced(setData, null, setIsDirty), [setData, setIsDirty]);
	const handleIsGroupChange = React.useMemo(() => handleIsGroupChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleLabChange = React.useMemo(() => handleLabChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleSpecificationChange = React.useMemo(() => handleSpecificationChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleTestsChange = React.useMemo(() => handleTestsChangeDebounced(setData, setIsDirty), [setData, setIsDirty]);
	const handleFormSubmit = async (event) => {
		if (!event.target.isGroup?.checked) {
			if (
				event.target.detectionLimit?.value &&
				event.target.specificationValue?.value &&
				parseFloat(event.target.specificationValue.value) < parseFloat(event.target.detectionLimit.value)
			) {
				throw new Error("Specification Value Cannot Be Less Than Method Detection Limit");
			}
			if (
				event.target.specificationRangeValue?.value &&
				event.target.specificationValue?.value &&
				parseFloat(event.target.specificationRangeValue.value) <= parseFloat(event.target.specificationValue.value)
			) {
				throw new Error("Specification Range Value Must Be Greater Than Specification Value");
			}
		}
	};
	return (
		<Grid container spacing={2} sx={{ padding: 0 }}>
			<Grid xs={12} md={props.mode === "view" ? 6 : 12}>
				<Item
					class={TestClass}
					data={data}
					icon={TestIcon}
					isDirty={isDirty}
					mode={props.mode}
					onChange={handleChange}
					onSubmitForm={handleFormSubmit}
				>
					<ReferenceField
						class={LabClass}
						icon={LabIcon}
						required="true"
						mode={props.mode}
						id={data.id}
						idReference={data.tenantIdShardLabIdReference}
						defaultIdReference={TestClass.getDefaultTenantIdShardLabIdReference(session.tenant.id, session.tenant.name)}
						onChange={handleLabChange}
					/>
					<CurrencyField name="price" label="Price (USD)" value={data.price} onChange={handleChange} mode={props.mode} />
					<CurrencyField name="cost" label="Cost (USD)" value={data.cost} onChange={handleChange} mode={props.mode} />
					<TextField
						name="type"
						labelIcon={TypeIcon}
						label="Type"
						value={data.type}
						onChange={handleChange}
						mode={props.mode}
					/>
					<ComboField
						name="sampleSizeRequired"
						labelIcon={SizeIcon}
						label="Sample Size Required"
						value={data.sampleSizeRequired}
						onChange={handleChange}
						mode={props.mode}
						qualifierName="sampleSizeRequiredUOM"
						qualifierLabel="Unit of Measurement"
						qualifierValue={data.sampleSizeRequiredUOM}
						onQualifierChange={handleChange}
					/>
					{props.mode === "edit" &&
						<Grid xs={12}>
							<FormControlLabel
								control={<Checkbox name="isGroup" checked={data.isGroup === "on"} onChange={handleIsGroupChange} />}
								label={<Typography>Group</Typography>}
							/>
						</Grid>
					}
					{data.isGroup === "on" &&
						<ReferenceField
							class={TestClass}
							icon={TestIcon}
							multiple="true"
							required="true"
							mode={props.mode}
							id={data.id}
							idReferences={data.tenantIdShardTestIdReferences}
							onChange={handleTestsChange}
							filter="[Group"
						/>
					}
					{data.isGroup !== "on" &&
						<>
							<NumberField
								name="turnAroundDays"
								labelIcon={CalendarIcon}
								label="Turn Around (business days)"
								value={data.turnAroundDays}
								unit="day"
								onChange={handleChange}
								mode={props.mode}
							/>
							<TextField
								name="method"
								labelIcon={MethodIcon}
								label="Method"
								value={data.method}
								required="true"
								onChange={handleChange}
								mode={props.mode}
							/>
							<SpecificationField
								data={data}
								onChange={handleSpecificationChange}
								mode={props.mode}
							/>
						</>
					}
					<NoteField
						name="coaNote"
						label="Certificate of Analysis Note (external)"
						value={data.coaNote}
						onChange={handleChange}
						mode={props.mode}
					/>
				</Item>
			</Grid>
			{props.mode === "view" &&
				<Grid xs={12} md={6}>
					<Samples
						filterName="tenantIdShardTestIds"
						filterValue={TestClass.getCombinedIdFromInputs(data.tenantIdShard, data.id)}
						columnVisibilityModel={{
							"dateReceived": false,
							"testDateDue": false,
							"testDateCompleted": false,
							"createdAt": false,
							"updatedAt": false
						}}
					/>
				</Grid>
			}
		</Grid>
	);
}

function handleIsGroupChangeDebounced(setData, setIsDirty) {
	return debounce((event) => {
		//console.log("handleIsGroupChangeDebounced", event.target.name, event.target.checked, event.target.isDefault);
		const isGroup = !event.target.checked;
		setData((previousData) => ({
			...previousData,
			isGroup: isGroup ? "on" : undefined,
			...(isGroup ? {
				turnAroundDays: undefined,
				method: undefined,
				detectionLimit: undefined,
				detectionLimitUOM: undefined,
				specification: undefined,
				specificationRange: undefined,
				specificationRangeValue: undefined,
				specificationRangeValueUOM: undefined,
				specificationValue: undefined,
				specificationValueUOM: undefined
			} : {
				tenantIdShardTestIdReferences: undefined,
				tenantIdShardTestIds: undefined
			})
		}));
		if (setIsDirty && !event.target.isDefault) setIsDirty(true);
	}, 300);
}

function handleLabChangeDebounced(setData, setIsDirty) {
	return debounce((lab, isDefault = false) => {
		//console.log("handleLabChangeDebounced", lab, isDefault);
		setData((previousData) => ({
			...previousData,
			tenantIdShardLabId: lab?.getCombinedId(),
			tenantIdShardLabIdReference: lab?.getCombinedId(true, lab?.context)
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}

function handleSpecificationChangeDebounced(setData, setIsDirty) {
	return debounce((specification, isDefault = false) => {
		//console.log("handleSpecificationChangeDebounced", specification, isDefault);
		setData((previousData) => ({
			...previousData,
			detectionLimit: specification.detectionLimit,
			detectionLimitUOM: specification.detectionLimitUOM,
			specification: specification.specification,
			specificationValue: specification.specificationValue,
			specificationValueUOM: specification.specificationValueUOM,
			specificationRange: specification.specificationRange,
			specificationRangeValue: specification.specificationRangeValue,
			specificationRangeValueUOM: specification.specificationRangeValueUOM
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}

function handleTestsChangeDebounced(setData, setIsDirty) {
	return debounce((tests, isDefault = false) => {
		//console.log("handleTestsChangeDebounced", tests, isDefault);
		setData((previousData) => ({
			...previousData,
			tenantIdShardTestIds: tests.map((test) => test.getCombinedId()),
			tenantIdShardTestIdReferences: tests.map((test) => test.getCombinedId(true, test?.context))
		}));
		if (setIsDirty && !isDefault) setIsDirty(true);
	}, 300);
}