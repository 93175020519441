import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigation } from "react-router-dom";
import useVeil from "../hooks/useVeil";

Veil.propTypes = {
	children: PropTypes.node,
};

export default function Veil(props) {
	const navigation = useNavigation();
	const { active } = useVeil();
	const [style, setStyle] = React.useState({ opacity: "100%" });
	React.useEffect(() => {
		if (active || navigation.state !== "idle") {
			setStyle({ opacity: "50%" });
		} else {
			setStyle({ opacity: "100%" });
		}
	}, [active, navigation.state, setStyle]);
	return (
		<Box sx={style}>
			{props.children}
		</Box>
	);
}