import { Certificate as CertificateClass } from "../classes/certificate";
import COANotificationStatusField from "./coa-notification-status-field";
import CurrencyField from "./currency-field";
import { Customer as CustomerClass } from "../classes/customer";
import DateField from "./date-field";
import { Icon as CustomerIcon } from "./customer";
import { Icon as TestIcon } from "./test";
import Items from "./items";
import ReferenceField from "./reference-field";
import ResultStatusField from "./result-status-field";
import { Sample as SampleClass } from "../classes/sample";
import { Send as SendIcon, Link as LinkIcon } from "@mui/icons-material";
import { Test as TestClass } from "../classes/test";
import useAlert from "../hooks/useAlert";
import useDialog from "../hooks/useDialog";
import useSession from "../hooks/useSession";

const WWW_URL = process.env.REACT_APP_WWW_URL;

export default function Samples(props) {
	const { setAlert } = useAlert();
	const data = props.data;
	const columns = [
		{
			field: "code", type: "string", headerName: "Code", aggregable: false, groupable: false, valueGetter: (params) => {
				if (params.row.createdAt || params.row.updatedAt) return CertificateClass.getCode(params.row.createdAt, params.row.updatedAt);
			}
		},
		{
			field: "lotNumber", type: "string", headerName: "Lot Number", aggregable: false, groupable: false, valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},
		{
			field: "tenantIdShardCustomerIdReference", type: "string", headerName: "Customer", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ReferenceField
							class={CustomerClass}
							icon={CustomerIcon}
							mode="view"
							submode="naked"
							id={params.row.id}
							idReference={params.rowNode.type === "group" ? params.value : params.row.tenantIdShardCustomerIdReference}
						/>
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return SampleClass.getNameFromCombinedId(params.value);
			}
		},
		/*{
			field: "size", type: "number", headerName: "Size", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<NumberField value={params.value} valueSuffix={params.row?.sizeUOM ? " " + params.row.sizeUOM : null} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "sizeRequired", type: "string", headerName: "Size Required", valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},*/
		{
			field: "dateReceived", type: "date", headerName: "Received", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<DateField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		/*{
			field: "testTurnAroundDays", type: "number", headerName: "Turn Around", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<NumberField value={params.value} unit="day" mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseInt(params.value);
			}
		},*/
		{
			field: "tenantIdShardTestIdReferences", type: "string", headerName: "Tests", groupable: false, renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ReferenceField
							class={TestClass}
							icon={TestIcon}
							multiple="true"
							mode="view"
							submode="naked"
							id={params.row.id}
							idReferences={params.row.tenantIdShardTestIdReferences}
						/>
					);
				}
			}, valueGetter: (params) => {
				if (params.value && Array.isArray(params.value) && params.value.length > 0) {
					return params.value.map((tenantIdShardTestIdReference) => SampleClass.getNameFromCombinedId(tenantIdShardTestIdReference)).join(", ");
				}
			}
		},
		{
			field: "testDateDue", type: "date", headerName: "Due", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<DateField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		{
			field: "testDateCompleted", type: "date", headerName: "Completed", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<DateField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return new Date(params.value);
			}
		},
		{
			field: "testResult", type: "string", headerName: "Testing", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ResultStatusField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},
		{
			field: "testPrice", type: "number", headerName: "Price", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<CurrencyField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "testFees", type: "number", headerName: "Fees", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<CurrencyField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "testTotal", type: "number", headerName: "Total", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<CurrencyField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return parseFloat(params.value);
			}
		},
		{
			field: "purchaseOrderNumber", type: "string", headerName: "PO Number", valueGetter: (params) => {
				if (params.value) return params.value;
			}
		},
		{
			field: "tenantIdShardCustomerIdReferences", type: "string", headerName: "CoA Customers", groupable: false, renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<ReferenceField
							class={CustomerClass}
							icon={CustomerIcon}
							multiple="true"
							mode="view"
							submode="naked"
							id={params.row.id}
							idReferences={params.row.tenantIdShardCustomerIdReferences}
						/>
					);
				}
			}, valueGetter: (params) => {
				if (params.value && Array.isArray(params.value) && params.value.length > 0) {
					return params.value.map((tenantIdShardCustomerIdReference) => SampleClass.getNameFromCombinedId(tenantIdShardCustomerIdReference)).join(", ");
				}
			}
		},
		{
			field: "coaNotificationStatus", type: "string", headerName: "Notification", renderCell: (params) => {
				if (params.value) {
					if (params.aggregation && !params.aggregation.hasCellUnit) return params.value;
					return (
						<COANotificationStatusField value={params.value} mode="view" submode="naked" />
					);
				}
			}, valueGetter: (params) => {
				if (params.value) return params.value;
			}
		}
	];
	const columnVisibilityModel = props.columnVisibilityModel ? props.columnVisibilityModel : {};
	if (columnVisibilityModel.code === null || window.innerWidth < 600) columnVisibilityModel.code = false;
	columnVisibilityModel.tenantIdShardCustomerIdReference = false;
	if (columnVisibilityModel.testResult === null || window.innerWidth < 300) columnVisibilityModel.testResult = false;
	if (columnVisibilityModel.lotNumber === null || window.innerWidth < 600) columnVisibilityModel.lotNumber = false;
	if (columnVisibilityModel.dateReceived === null || window.innerWidth < 900) columnVisibilityModel.dateReceived = false;
	if (columnVisibilityModel.testDateDue === null || window.innerWidth < 900) columnVisibilityModel.testDateDue = false;
	if (columnVisibilityModel.testDateCompleted === null || window.innerWidth < 900) columnVisibilityModel.testDateCompleted = false;
	if (columnVisibilityModel.coaNotificationStatus === null || window.innerWidth < 900) columnVisibilityModel.coaNotificationStatus = false;
	columnVisibilityModel.size = false;
	columnVisibilityModel.sizeRequired = false;
	//columnVisibilityModel.testTurnAroundDays = false;
	columnVisibilityModel.tenantIdShardTestIdReferences = false;
	columnVisibilityModel.testPrice = false;
	columnVisibilityModel.testFees = false;
	columnVisibilityModel.testTotal = false;
	columnVisibilityModel.purchaseOrderNumber = false;
	columnVisibilityModel.tenantIdShardCustomerIdReferences = false;
	const { setDialog } = useDialog();
	const { session } = useSession();
	const authorized = session.groups.includes("Manager");
	const action = [];
	if (authorized && data) {
		let hasPendingCOANotification = false;
		for (let i = 0; i < data.length; i++) {
			if (data[i].coaNotificationStatus === CertificateClass.RESULT_STATUS.PENDING) {
				hasPendingCOANotification = true;
				break;
			}
		}
		if (hasPendingCOANotification) {
			action.push({
				title: "Notify Customers",
				icon: <SendIcon />,
				onClick: () => setDialog({
					notifyCustomers: true
				})
			});
		}
	}
	action.push({
		title: "Copy Sample Submission Link",
		icon: <LinkIcon />,
		onClick: () => {
			const url = WWW_URL + "/sample/" + session.tenant.id + "?" +
				"labLogo=" + encodeURIComponent(session.tenant.logo) +
				"&labName=" + encodeURIComponent(session.tenant.name) +
				"&labAddress=" + encodeURIComponent(session.tenant.address?.replace(/\n/g, ", ")) +
				"&labEmail=" + encodeURIComponent(session.tenant.email) +
				"&labPhone=" + encodeURIComponent(session.tenant.phone);
			navigator.clipboard.writeText(url);
			setAlert("success", "Copied Sample Submission Link to Clipboard");
		}
	});
	return (
		<Items
			action={action}
			class={SampleClass}
			columns={columns}
			columnVisibilityModel={columnVisibilityModel}
			data={data}
			filterName={props.filterName}
			filterValue={props.filterValue}
		/>
	);
}