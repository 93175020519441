import React from "react";

const VeilContext = React.createContext();
export default VeilContext;

export const VeilProvider = ({ children }) => {
	const [active, setActive] = React.useState(false);
	const setVeil = (active) => {
		setActive(active);
	};
	return (
		<VeilContext.Provider value={{ active, setVeil }}>
			{children}
		</VeilContext.Provider>
	);
};