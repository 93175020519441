import { LocalShipping as AddressIcon } from "@mui/icons-material";
import TextField from "./text-field";

export default function AddressField(props) {
	return (
		<TextField
			{...props}
			append={
				props.value && (
					<iframe
						height="300"
						loading="lazy"
						src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDcuib8ZqfuU1TAAjZyBiHDiCQ4qvB5DnI&q=" + encodeURIComponent(props.value)}
						style={{ border: 0, borderRadius: "4px" }}
						title="Address"
						width="100%"
					>
					</iframe>
				)
			}
			label="Address"
			labelIcon={AddressIcon}
			name="address"
			newline=", "
			rows="3"
			showLabel="false"
		/>
	);
}