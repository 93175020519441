import { Alert as MuiAlert, Snackbar } from "@mui/material";
import useAlert from "../hooks/useAlert";

export default function Alert() {
	const { severity, text, setAlert } = useAlert();
	const handleClose = () => {
		setAlert("", "");
	};
	if (severity && text) {
		return (
			<Snackbar open anchorOrigin={{ vertical: "bottom", horizontal: "left" }} autoHideDuration={6000} onClose={handleClose} sx={{ marginBottom: 0, marginLeft: 0 }}>
				<MuiAlert variant="filled" severity={severity} onClose={handleClose}>
					{text}
				</MuiAlert>
			</Snackbar>
		);
	} else {
		return null;
	}
}