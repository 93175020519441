const { Item } = require("./item.js");

class Lab extends Item {

	static className = "Lab";
	static history = true;
	static idIndex = "id2";
	static idPlusIndex = "idPlus3";
	static indexes = [...Item.indexes, Lab.idIndex, Lab.idPlusIndex];
	static key = [...Item.key, "tenantIdShard"];
	static tableName = "LabAxiomLab";

	constructor() {
		super();
		this.address = undefined;
		this.contacts = undefined;
		this.email = undefined;
		this.phone = undefined;
		this.tenantIdShard = undefined;
	}

}

export { Lab };