const { Item } = require("./item.js");

class Sample extends Item {

	static className = "Sample";
	static history = true;
	static idIndex = "id2";
	static idPlusIndex = "idPlus4";
	static indexes = [...Item.indexes, Sample.idIndex, Sample.idPlusIndex];
	static key = [...Item.key, "tenantIdShard"];
	static tableName = "LabAxiomSample";

	constructor() {
		super();
		this.coaNote = undefined;
		this.coaNotificationStatus = undefined;
		this.coaQualifier = undefined;
		this.coas = undefined;
		this.code = undefined;
		this.dateReceived = undefined;
		this.lotNumber = undefined;
		this.picture = undefined;
		this.purchaseOrderNumber = undefined;
		this.results = undefined;
		this.size = undefined;
		this.sizeRequired = undefined;
		this.sizeUOM = undefined;
		this.storage = undefined;
		this.tenantIdShard = undefined;
		this.tenantIdShardCustomerId = undefined;
		this.tenantIdShardCustomerIdReference = undefined;
		this.tenantIdShardCustomerIdReferences = undefined;
		this.tenantIdShardCustomerIds = undefined;
		this.tenantIdShardTestIdReferences = undefined;
		this.tenantIdShardTestIds = undefined;
		this.testDateCompleted = undefined;
		this.testDateDue = undefined;
		this.testDateOutsourced = undefined;
		this.testDateOutsourcedDue = undefined;
		this.testFees = undefined;
		this.testOutsourcedTurnAroundDays = undefined;
		this.testPrice = undefined;
		this.testResult = undefined;
		this.testTotal = undefined;
		this.testTurnAroundDays = undefined;
		this.type = undefined;
	}

	getReference() {
		return Sample.getReferenceFromContext(this.tenantIdShard, this.id, this.name, this.tenantIdShardCustomerIdReference);
	}

	static getReferenceFromContext(tenantIdShard, id, name, tenantIdShardCustomerIdReference) {
		let context = "";
		if (tenantIdShardCustomerIdReference) {
			context += Sample.getNameFromCombinedId(tenantIdShardCustomerIdReference);
		}
		return super.getReferenceFromInput(tenantIdShard, id, name, context);
	}

	static getReferenceFromData(data) {
		return Sample.getReferenceFromContext(data.tenantIdShard, data.id, data.name, data.tenantIdShardCustomerIdReference);
	}

}

export { Sample };