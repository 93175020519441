import React from "react";
import { FormControlLabel, InputAdornment, Radio, RadioGroup, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import TextField from "./text-field";

export default function ImageField(props) {
	const originalValueRef = React.useRef(props.value || "");
	const originalValue = originalValueRef.current;
	const [radioValue, setRadioValue] = React.useState(originalValue ? "current" : "none");
	const [value, setValue] = React.useState(originalValue);
	const handleFileChange = (event) => {
		const newValue = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "";
		if (props.onChange) props.onChange({ target: { name: props.name, value: newValue } });
		setValue(newValue);
	};
	const handleRadioChange = (event) => {
		const newRadioValue = event.target.value;
		setRadioValue(newRadioValue);
		const newValue = newRadioValue === "current" ? originalValue : "";
		if (props.onChange) props.onChange({ target: { name: props.name, value: newValue } });
		setValue(newValue);
	};
	return (
		<>
			{props.mode === "edit" &&
				<>
					<Grid xs={12}>
						<RadioGroup value={radioValue} onChange={handleRadioChange}>
							<FormControlLabel value="none" control={<Radio />} label={<Typography>No {props.label}</Typography>} />
							<FormControlLabel value="new" control={<Radio />} label={<Typography>New {props.label}</Typography>} />
							{originalValue && <FormControlLabel value="current" control={<Radio />} label={<Typography>Current {props.label}</Typography>} />}
						</RadioGroup>
					</Grid>
					{radioValue === "new" && (
						<TextField
							{...props}
							InputProps={{ startAdornment: <InputAdornment position="start" sx={{ marginRight: 0 }}></InputAdornment> }}
							label={"New " + props.label}
							onChange={handleFileChange}
							type="file"
						/>
					)}
				</>
			}
			{value &&
				<Grid xs={12}>
					<img title={props.label} alt={props.label} loading="lazy" style={{ borderRadius: "4px", maxWidth: "100%" }} src={value} />
				</Grid>
			}
		</>
	);
}