import { Box, BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Customer as CustomerClass } from "../classes/customer";
import { Icon as CustomerIcon } from "../components/customer";
import { Icon as LabIcon } from "../components/lab";
import { Icon as SampleIcon } from "../components/sample";
import { Icon as TestIcon } from "../components/test";
import { Lab as LabClass } from "../classes/lab";
import { Link } from "react-router-dom";
import { Sample as SampleClass } from "../classes/sample";
import { Test as TestClass } from "../classes/test";
import { useLocation } from "react-router-dom";

export default function Footer() {
	const location = useLocation();
	let route = location.pathname.split("/")[1];
	if (route.slice(-1) !== "s") route = route + "s";
	return (
		<Box sx={{ flexGrow: 1 }}>
			<BottomNavigation sx={{ height: 64 }} />
			<Paper elevation={5} sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1100 }}>
				<BottomNavigation sx={{ height: 64 }} showLabels value={route}>
					<BottomNavigationAction
						component={Link} to={"/" + CustomerClass.getType(true)} value={CustomerClass.getType(true)}
						label={CustomerClass.getTypeTitle(true)} icon={<CustomerIcon />}
					/>
					<BottomNavigationAction
						component={Link} to={"/" + SampleClass.getType(true)} value={SampleClass.getType(true)}
						label={SampleClass.getTypeTitle(true)} icon={<SampleIcon />}
					/>
					<BottomNavigationAction
						component={Link} to={"/" + TestClass.getType(true)} value={TestClass.getType(true)}
						label={TestClass.getTypeTitle(true)} icon={<TestIcon />}
					/>
					<BottomNavigationAction
						component={Link} to={"/" + LabClass.getType(true)} value={LabClass.getType(true)}
						label={LabClass.getTypeTitle(true)} icon={<LabIcon />}
					/>
				</BottomNavigation>
			</Paper>
		</Box>
	);
}