import React from "react";
import { Certificate as CertificateClass } from "../classes/certificate";
import ComboField from "./combo-field";
import { getNumberText } from "./number-field";
import { Straighten as MeasureIcon } from "@mui/icons-material";
import TextField from "./text-field";

export default function SpecificationField(props) {
	const [data, setData] = React.useState(props.data ? {
		...props.data,
		detectionLimitUOM: props.data.specificationValueUOM || props.data.detectionLimitUOM || props.data.specificationRangeValueUOM || "",
		specificationRangeValueUOM: props.data.specificationValueUOM || props.data.detectionLimitUOM || props.data.specificationRangeValueUOM || "",
		specificationValueUOM: props.data.specificationValueUOM || props.data.detectionLimitUOM || props.data.specificationRangeValueUOM || ""
	} : {});
	const handleChange = (event) => {
		const updatedSpecification = processSpecificationUpdate({ ...data, [event.target.name]: event.target.value }, data);
		if (props.onChange) props.onChange(updatedSpecification, event.target.isDefault);
		setData(updatedSpecification);
	};
	return (
		<>
			<ComboField
				inputStep="0.001"
				labelIcon={MeasureIcon}
				label="Method Detection Limit"
				mode={props.mode}
				name="detectionLimit"
				onChange={handleChange}
				onQualifierChange={handleChange}
				qualifierLabel="Unit of Measurement"
				qualifierName="detectionLimitUOM"
				qualifierValue={data.detectionLimitUOM}
				submode={props.submode}
				value={data.detectionLimit}
			/>
			{props.mode === "edit" ? (
				<>
					<SpecificationFieldOptions {...props} value={data.specification} onChange={handleChange} />
					{
						(
							data.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
							data.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG ||
							data.specification === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
							data.specification === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
						) ? (
							<ComboField
								inputStep="0.001"
								label="Specification Value"
								mode={props.mode}
								name="specificationValue"
								onChange={handleChange}
								onQualifierChange={handleChange}
								qualifierLabel="Unit of Measurement"
								qualifierName="specificationValueUOM"
								qualifierRequired="true"
								qualifierValue={data.specificationValueUOM}
								required="true"
								submode={props.submode}
								value={data.specificationValue}
							/>
						) : null
					}
					{
						(
							data.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
							data.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
						) ? (
							<SpecificationFieldRangeOptions {...props} value={data.specificationRange} onChange={handleChange} />
						) : null
					}
					{
						(
							(
								data.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
								data.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
							) && (
								data.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
								data.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
							)
						) ? (
							<ComboField
								inputStep="0.001"
								label="Specification Range Value"
								mode={props.mode}
								name="specificationRangeValue"
								onChange={handleChange}
								onQualifierChange={handleChange}
								qualifierLabel="Unit of Measurement"
								qualifierName="specificationRangeValueUOM"
								qualifierRequired="true"
								qualifierValue={data.specificationRangeValueUOM}
								required="true"
								submode={props.submode}
								value={data.specificationRangeValue}
							/>
						) : null
					}
				</>
			) : (
				<TextField
					{...props}
					label="Specification"
					labelIcon={MeasureIcon}
					name="specification"
					value={
						CertificateClass.convertSpecification(data.specification) + (
							(
								data.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
								data.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG ||
								data.specification === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
								data.specification === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
							) ? (
								" " + getNumberText(data.specificationValue, "0.001") + " " + data.specificationValueUOM +
								(
									(
										data.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
										data.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
									) ? (
										CertificateClass.SPECIFICATION_RANGE_CONNECTOR +
										CertificateClass.convertSpecification(data.specificationRange) +
										" " + getNumberText(data.specificationRangeValue, "0.001") +
										" " + data.specificationRangeValueUOM
									) : ""
								)
							) : ""
						)
					}
				/>
			)}
		</>
	);
}

export function SpecificationFieldOptions(props) {
	return (
		<>
			{props.mode === "edit" ? (
				<TextField
					{...props}
					name="specification"
					label="Specification"
					propagateRef={props.propagateRef}
					valueDefault={CertificateClass.SPECIFICATION.REPORT.LONG}
				>
					<option value={CertificateClass.SPECIFICATION.ABSENT.LONG}>
						{CertificateClass.SPECIFICATION.ABSENT.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.CONFORMS.LONG}>
						{CertificateClass.SPECIFICATION.CONFORMS.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.GREATER_THAN.LONG}>
						{CertificateClass.SPECIFICATION.GREATER_THAN.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG}>
						{CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.LESS_THAN.LONG}>
						{CertificateClass.SPECIFICATION.LESS_THAN.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG}>
						{CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.NEW.LONG}>
						{CertificateClass.SPECIFICATION.NEW.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.PRESENT.LONG}>
						{CertificateClass.SPECIFICATION.PRESENT.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.REPORT.LONG}>
						{CertificateClass.SPECIFICATION.REPORT.LONG}
					</option>
				</TextField>
			) : (
				<TextField
					{...props}
					name="specification"
					label="Specification"
					value={CertificateClass.convertSpecification(props.value)}
				/>
			)}
		</>
	);
}

export function SpecificationFieldRangeOptions(props) {
	return (
		<>
			{props.mode === "edit" ? (
				<TextField
					{...props}
					name="specificationRange"
					label="Specification Range"
					propagateRef={props.propagateRef}
					valueDefault={CertificateClass.SPECIFICATION.NONE.LONG}
				>
					<option value={CertificateClass.SPECIFICATION.NONE.LONG}>
						{CertificateClass.SPECIFICATION.NONE.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.LESS_THAN.LONG}>
						{CertificateClass.SPECIFICATION.LESS_THAN.LONG}
					</option>
					<option value={CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG}>
						{CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG}
					</option>
				</TextField>
			) : (
				<TextField
					{...props}
					name="specificationRange"
					label="Specification Range"
					value={CertificateClass.convertSpecification(props.value)}
				/>
			)}
		</>
	);
}

export function processSpecificationUpdate(updatedSpecification, originalSpecification, isResult = false) {
	//console.log("Original Specification", originalSpecification);
	updatedSpecification.detectionLimit = parseFloat(updatedSpecification.detectionLimit);
	originalSpecification.detectionLimit = parseFloat(originalSpecification.detectionLimit);
	if (updatedSpecification.specification !== originalSpecification.specification) {
		updatedSpecification.specificationValue = undefined;
		updatedSpecification.specificationRangeValue = undefined;
		if (
			updatedSpecification.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
			updatedSpecification.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
		) {
			updatedSpecification.specificationRange = CertificateClass.SPECIFICATION.NONE.LONG;
		} else {
			updatedSpecification.specificationRange = undefined;
		}
	} else {
		updatedSpecification.specificationValue = parseFloat(updatedSpecification.specificationValue);
		originalSpecification.specificationValue = parseFloat(originalSpecification.specificationValue);
		if (updatedSpecification.specificationRange !== originalSpecification.specificationRange) {
			updatedSpecification.specificationRangeValue = undefined;
		} else {
			updatedSpecification.specificationRangeValue = parseFloat(updatedSpecification.specificationRangeValue);
			originalSpecification.specificationRangeValue = parseFloat(originalSpecification.specificationRangeValue);
		}
	}
	if (isResult) {
		updatedSpecification.detectionResult = parseFloat(updatedSpecification.detectionResult);
		originalSpecification.detectionResult = parseFloat(originalSpecification.detectionResult);
	}
	if (updatedSpecification.detectionLimitUOM !== originalSpecification.detectionLimitUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.detectionLimitUOM, isResult);
	} else if (updatedSpecification.specificationValueUOM !== originalSpecification.specificationValueUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.specificationValueUOM, isResult);
	} else if (updatedSpecification.specificationRangeValueUOM !== originalSpecification.specificationRangeValueUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.specificationRangeValueUOM, isResult);
	} else if (isResult && updatedSpecification.detectionResultUOM !== originalSpecification.detectionResultUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.detectionResultUOM, isResult);
	} else if (updatedSpecification.detectionLimitUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.detectionLimitUOM, isResult);
	} else if (updatedSpecification.specificationValueUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.specificationValueUOM, isResult);
	} else if (updatedSpecification.specificationRangeValueUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.specificationRangeValueUOM, isResult);
	} else if (isResult && updatedSpecification.detectionResultUOM) {
		updatedSpecification = processUOMUpdate(updatedSpecification, updatedSpecification.detectionResultUOM, isResult);
	} else {
		updatedSpecification = processUOMUpdate(updatedSpecification, undefined, isResult);
	}
	//console.log("Updated Specification", updatedSpecification);
	return updatedSpecification;
}

function processUOMUpdate(updatedSpecification, updatedUOM, isResult = false) {
	updatedSpecification.detectionLimitUOM = updatedUOM;
	if (
		(
			updatedSpecification.specification === CertificateClass.SPECIFICATION.GREATER_THAN.LONG ||
			updatedSpecification.specification === CertificateClass.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG
		) || (
			updatedSpecification.specification === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
			updatedSpecification.specification === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
		)
	) {
		updatedSpecification.specificationValueUOM = updatedUOM;
	} else {
		updatedSpecification.specificationValueUOM = undefined;
	}
	if (
		updatedSpecification.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN.LONG ||
		updatedSpecification.specificationRange === CertificateClass.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG
	) {
		updatedSpecification.specificationRangeValueUOM = updatedUOM;
	} else {
		updatedSpecification.specificationRangeValueUOM = undefined;
	}
	if (isResult) {
		updatedSpecification.detectionResultUOM = updatedUOM;
	}
	return updatedSpecification;
}