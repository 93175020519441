import React from "react";
import { CalendarMonth as CalendarIcon } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import TextField from "./text-field";

export default function DateField(props) {
	const [inputProps, setInputProps] = React.useState({});
	const onChange = props.onChange ? props.onChange : null;
	const [value, setValue] = React.useState("");
	const [valueChanged, setValueChanged] = React.useState(false);
	const [valueDisplay, setValueDisplay] = React.useState("");
	React.useEffect(() => {
		const inputProps = props.inputProps ? props.inputProps : {};
		if (props.min) inputProps.min = getDateDisplay(props.min);
		setInputProps(inputProps);
	}, [props]);
	React.useEffect(() => {
		if (props.value) {
			setValue(getDate(props.value));
			setValueDisplay(getDateDisplay(props.value));
		} else if (props.mode !== "view" && props.valueDefault === "true") {
			const defaultDate = new Date().toISOString();
			setValue(getDate(defaultDate));
			setValueDisplay(getDateDisplay(defaultDate));
			if (onChange) onChange({ target: { name: props.name, value: defaultDate, isDefault: true } });
		} else {
			setValue("");
			setValueDisplay("");
		}
	}, [onChange, props.mode, props.name, props.value, props.valueDefault]);
	const handleBlur = () => {
		if (valueChanged && onChange) onChange({ target: { name: props.name, value: value } });
	};
	const handleChange = (event) => {
		const newDate = new Date(event.target.value);
		const offset = newDate.getTimezoneOffset();
		newDate.setMinutes(newDate.getMinutes() + offset);
		setValue(getDate(newDate));
		setValueDisplay(getDateDisplay(newDate));
		setValueChanged(true);
	};
	return (
		<TextField
			{...props}
			inputProps={inputProps}
			InputProps={{ startAdornment: <InputAdornment position="start" sx={{ marginRight: 0 }}></InputAdornment> }}
			labelIcon={CalendarIcon}
			onChange={handleChange}
			onBlur={handleBlur}
			type="date"
			value={valueDisplay}
			valueDefault={null}
		/>
	);
}

export function getDate(dateData) {
	let date = null;
	if (dateData) {
		if (dateData instanceof Date) {
			date = dateData;
		} else {
			date = new Date(dateData);
		}
	} else {
		date = new Date();
	}
	return date.toISOString();
}

export function getDateDisplay(dateData) {
	let date = null;
	if (dateData) {
		if (dateData instanceof Date) {
			date = dateData;
		} else {
			date = new Date(dateData);
		}
	} else {
		date = new Date();
	}
	const offset = date.getTimezoneOffset();
	date.setMinutes(date.getMinutes() - offset);
	return date.toISOString().split("T")[0];
}