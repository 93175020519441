import React from "react";
import { Button, IconButton, Link, Paper, Tooltip, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import {
	Close as CloseIcon,
	ContentCopy as CopyIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Info as InfoIcon,
	Save as SaveIcon
} from "@mui/icons-material";
import { createItem, readItem, updateItem } from "../api";
import DateField from "./date-field";
import debounce from "lodash/debounce";
import { Form, useNavigate } from "react-router-dom";
import NoteField from "./note-field";
import PropTypes from "prop-types";
import { setDocumentTitle as setDocumentTitleRouter } from "../router";
import TextField from "./text-field";
import Title from "./title";
import useAlert from "../hooks/useAlert";
import useBlocker, { UNSAVED_CHANGES_MESSAGE, UNSAVED_CHANGES_TITLE } from "../hooks/useBlocker";
import useDialog from "../hooks/useDialog";
import useSession from "../hooks/useSession";
import useVeil from "../hooks/useVeil";

Item.propTypes = {
	children: PropTypes.node
};

export default function Item(props) {
	const [isDirty, setIsDirty] = React.useState(false);
	const [item, setItem] = React.useState({});
	const [formDisabled, setFormDisabled] = React.useState(false);
	const navigate = useNavigate();
	const onSubmitForm = props.onSubmitForm;
	const { session } = useSession();
	const { setAlert } = useAlert();
	const { setDialog } = useDialog();
	const { setVeil } = useVeil();
	useBlocker(() => isDirty, isDirty);
	React.useEffect(() => {
		const newItem = new props.class();
		props.data.userId = session.user.email;
		props.data.tenantId = session.tenant.id;
		newItem.initialize(props.data);
		setItem(newItem);
		if (props.isDirty) setIsDirty(true);
		setDocumentTitle(props.class.getTypeTitle(), newItem.name);
	}, [props, session]);
	const handleChange = React.useMemo(() => handleChangeDebounced(setItem, props.onChange, setIsDirty), [props.onChange, setIsDirty, setItem]);
	const handleFormClose = () => {
		if (formDisabled) return;
		if (isDirty) {
			setDialog({
				confirm: {
					title: UNSAVED_CHANGES_TITLE,
					message: UNSAVED_CHANGES_MESSAGE,
					onConfirm: () => {
						setFormDisabled(true);
						navigate(-1);
					}
				}
			});
		} else {
			setFormDisabled(true);
			navigate(-1);
		}
	};
	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (formDisabled) return;
		try {
			setVeil(true);
			setFormDisabled(true);
			if (onSubmitForm) await onSubmitForm(event);
			await encodeFiles(item);
			//console.log("ITEM", item);
			item.validate();
			if (item.id) {
				//console.log("UPDATE");
				await updateItem(props.class.getType(), item);
			} else {
				//console.log("CREATE");
				await createItem(props.class.getType(), item);
			}
			setAlert("success", "Saved " + props.class.getTypeTitle());
			navigate(-1);
		} catch (error) {
			console.error("Error Saving " + props.class.getTypeTitle(), error);
			setAlert("error", "Error Saving " + props.class.getTypeTitle() + ": " + error.message);
		} finally {
			setFormDisabled(false);
			setVeil(false);
		}
	};
	return (
		<Paper elevation={2} sx={{ padding: 2, height: "100%" }}>
			<Form id="ItemForm" onSubmit={handleFormSubmit} onKeyDown={(e) => {
				const elementType = document.activeElement.type;
				if (e.key === "Enter" && elementType !== "submit" && elementType !== "button" && elementType !== "textarea") e.preventDefault();
			}}>
				<Grid container spacing={2} sx={{ padding: 0 }}>
					<Grid xs={6} display="flex" justifyContent="left" alignItems="center">
						<Title marginBottom="0">{props.class.getTypeTitle()}</Title>
					</Grid>
					<Grid xs={6} display="flex" justifyContent="right" alignItems="center">
						{props.mode === "view" && Array.isArray(props.action) &&
							props.action.map((action, index) => {
								if (action) {
									return (
										<Tooltip key={index} title={action.title}>
											<IconButton component={Link} to={action.link} onClick={action.onClick} size="small">
												{action.icon}
											</IconButton>
										</Tooltip>
									);
								}
								return null;
							})
						}
						{
							props.mode === "edit" &&
							<Tooltip title="Save">
								<IconButton type="submit" disabled={formDisabled} size="small">
									<SaveIcon />
								</IconButton>
							</Tooltip>
						}
						{
							props.mode === "edit" &&
							<Tooltip title="Close">
								<IconButton disabled={formDisabled} onClick={() => handleFormClose()} size="small">
									<CloseIcon />
								</IconButton>
							</Tooltip>
						}
						{
							props.mode === "view" &&
							item.getEditUrl &&
							<Tooltip title="Edit">
								<IconButton component={Link} to={item.getEditUrl()} size="small">
									<EditIcon />
								</IconButton>
							</Tooltip>
						}
						{props.mode === "view" && item.getEditUrl && session.groups.includes("Manager") &&
							<Tooltip title="Delete"><IconButton size="small" onClick={() => setDialog({
								deleteItem: {
									item: {
										class: props.class,
										tenantIdShard: item.tenantIdShard,
										id: item.id,
										name: item.name
									}
								}
							})}>
								<DeleteIcon />
							</IconButton></Tooltip>
						}
					</Grid>
					<TextField
						name="name" showLabel="false" labelIcon={props.icon} label="Name" value={item.name}
						autoFocus="true" required="true" onChange={handleChange} mode={props.mode}
					/>
					<NoteField value={item.note} onChange={handleChange} mode={props.mode} />
					{props.children}
					{props.mode === "edit" ? (
						<>
							<Grid xs={6} display="flex" justifyContent="right" alignItems="center">
								<Button type="submit" disabled={formDisabled} startIcon={<SaveIcon />} variant="contained">
									Save
								</Button>
							</Grid>
							<Grid xs={6} display="flex" justifyContent="left" alignItems="center">
								<Button disabled={formDisabled} onClick={() => handleFormClose()} startIcon={<CloseIcon />} variant="outlined">
									Close
								</Button>
							</Grid>
						</>
					) : (
						<Grid xs={12}>
							<Typography component="div" sx={{ fontWeight: "bold" }}>
								<InfoIcon sx={{ fontSize: "inherit", fontWeight: "inherit", marginRight: 0.75, verticalAlign: "middle" }} />Record
							</Typography>
							{item.id &&
								<Typography component="div" variant="body2" color="text.secondary">
									ID: {item.id}
									<Tooltip title="Copy ID to Clipboard">
										<Link sx={{ marginLeft: "5px" }} onClick={() => {
											navigator.clipboard.writeText(item.id);
											setAlert("success", "Copied ID to Clipboard");
										}}>
											<CopyIcon sx={{ fontSize: "inherit", fontWeight: "inherit", verticalAlign: "middle" }} />
										</Link>
									</Tooltip>
								</Typography>
							}
							{item.createdAt &&
								<Typography component="div" variant="body2" color="text.secondary">
									Created: <DateField submode="naked" value={item.createdAt} />{item.createdBy && <span> by {item.createdBy}</span>}
								</Typography>
							}
							{item.updatedAt &&
								<Typography component="div" variant="body2" color="text.secondary">
									Updated: <DateField submode="naked" value={item.updatedAt} />{item.updatedBy && <span> by {item.updatedBy}</span>}
								</Typography>
							}
						</Grid>
					)}
				</Grid>
			</Form>
		</Paper>
	);
}

export function encodeFile(file) {
	return new Promise((resolve, reject) => {
		if (file.size >= 300 * 1024) {
			console.log("File Size Is " + file.size + " Bytes");
			reject(new Error("File Size Must Be Less Than 300 KB"));
			return;
		}
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(file);
	});
}

async function encodeFiles(item) {
	for (const property in item) {
		if (item.hasOwnProperty(property)) {
			if (item[property]) {
				try {
					if (typeof item[property] === "object" && item[property] instanceof Blob) {
						item[property] = await encodeFile(item[property]);
					} else if (typeof item[property] === "string" && item[property].startsWith("blob:")) {
						const blob = await fetch(item[property]).then(response => response.blob());
						item[property] = await encodeFile(blob);
					}
				} catch (error) {
					const titleCaseProperty = property.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
					throw new Error(titleCaseProperty + " " + error.message);
				}
			}
		}
	}
}

export function handleChangeDebounced(setItem, onChange, setIsDirty) {
	return debounce((event) => {
		//console.log("handleChangeDebounced", event.target.name, event.target.value, event.target.isDefault);
		if (onChange) onChange(event);
		setItem((previousItem) => {
			previousItem[event.target.name] = event.target.value;
			return { ...previousItem };
		});
		if (setIsDirty && !event.target.isDefault) setIsDirty(true);
	}, 300);
}

export async function loader({ Class, params }) {
	const item = await readItem(Class.getType(), Class.getTenantIdShardFromCombinedId(params.id), Class.getIdFromCombinedId(params.id));
	if (!item) throw new Error(Class.getTypeTitle() + " Not Found");
	return item;
}

function setDocumentTitle(typeTitle, itemName) {
	let documentTitle = typeTitle;
	if (itemName) {
		documentTitle += " | " + itemName;
	} else {
		documentTitle += " | New";
	}
	setDocumentTitleRouter(documentTitle);
}