import React from "react";
import {
	DataGridPremium as MuiDataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiRef,
	useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";

export default function DataGrid(props) {
	const apiRef = useGridApiRef();
	const initialState = useKeepGroupedColumnsHidden({
		apiRef: apiRef,
		initialState: {
			columns: { columnVisibilityModel: props.columnVisibilityModel },
			pagination: { paginationModel: { pageSize: 20 } },
			sorting: { sortModel: props.sortModel }
		}
	});
	const columns = props.columns ? props.columns : [];
	const rows = props.rows ? props.rows : [];
	const handleCellClick = (params) => {
		if (params.isEditable) {
			const currentCellMode = apiRef.current.getCellMode(params.id, params.field);
			if (currentCellMode !== "edit") apiRef.current.startCellEditMode({ id: params.id, field: params.field });
		}
	};
	return (
		<MuiDataGrid
			apiRef={apiRef}
			autoHeight
			columns={columns}
			density="compact"
			disableAggregation={props.disableAggregation}
			disableRowGrouping={props.disableRowGrouping}
			disableRowSelectionOnClick
			hideFooterSelectedRowCount
			initialState={initialState}
			isCellEditable={props.isCellEditable}
			onCellClick={handleCellClick}
			onProcessRowUpdateError={props.onProcessRowUpdateError}
			pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000]}
			pagination
			processRowUpdate={props.processRowUpdate}
			rowModesModel={props.rowModesModel}
			rows={rows}
			slots={{ toolbar: () => <DataGridToolbar /> }}
		/>
	);
}

function DataGridToolbar(props) {
	return (
		<GridToolbarContainer sx={{ paddingTop: 1, paddingRight: 1, paddingBottom: 0, paddingLeft: 1 }}>
			<GridToolbarColumnsButton color="primary" sx={{ marginTop: 0, marginRight: 1, marginBottom: 1, marginLeft: 0 }} />
			<GridToolbarFilterButton color="primary" sx={{ marginTop: 0, marginRight: 1, marginBottom: 1, marginLeft: 0 }} />
			<GridToolbarDensitySelector color="primary" sx={{ marginTop: 0, marginRight: 1, marginBottom: 1, marginLeft: 0 }} />
			<GridToolbarExport color="primary" printOptions={{ disableToolbarButton: true }} sx={{ marginTop: 0, marginRight: 1, marginBottom: 1, marginLeft: 0 }} />
		</GridToolbarContainer>
	);
}