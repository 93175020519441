const { Document, Font, Image, Page, StyleSheet, Text, View } = require("@react-pdf/renderer");
const { Item } = require("./item.js");
const React = require("react");
const ReactPDF = require("@react-pdf/renderer");

class Certificate extends Item {

	static QUALIFIER = {
		AMENDED: "Amended",
		NORMAL: "Normal",
		PRELIMINARY: "Preliminary"
	};

	static SPECIFICATION = {
		ABSENT: {
			LONG: "Absent",
			SHORT: "Absent"
		},
		CONFORMS: {
			LONG: "Conforms to Standard",
			SHORT: "Conforms"
		},
		GREATER_THAN: {
			LONG: "Greater Than",
			SHORT: ">"
		},
		GREATER_THAN_OR_EQUAL_TO: {
			LONG: "Greater Than or Equal To",
			SHORT: ">="
		},
		LESS_THAN: {
			LONG: "Less Than",
			SHORT: "<"
		},
		LESS_THAN_OR_EQUAL_TO: {
			LONG: "Less Than or Equal To",
			SHORT: "<="
		},
		NEW: {
			LONG: "New",
			SHORT: "New"
		},
		NONE: {
			LONG: "None",
			SHORT: "None"
		},
		PRESENT: {
			LONG: "Present",
			SHORT: "Present"
		},
		REPORT: {
			LONG: "Report",
			SHORT: "Report"
		}
	};

	static SPECIFICATION_RANGE_CONNECTOR = " & ";

	static RESULT_STATUS = {
		FAILED: "Failed",
		PASSED: "Passed",
		PENDING: "Pending"
	};

	static convertSpecification(specification) {
		for (const key in Certificate.SPECIFICATION) {
			if (Certificate.SPECIFICATION[key].LONG === specification) {
				return Certificate.SPECIFICATION[key].SHORT ? Certificate.SPECIFICATION[key].SHORT : Certificate.SPECIFICATION[key].LONG;
			}
		}
		return specification;
	}

	static generate(customer, sample, user, tenant, environment, isClientSide) {
		const coaStyles = Certificate.#initialize();
		return (
			<Document
				title={Certificate.getTitle(sample, environment)}
				author={tenant.name}
				subject={sample.name}
				keywords={customer.name}
				creator="Lab Axiom"
				producer="React PDF Renderer"
			>
				<Page size="Letter" style={coaStyles.page}>
					{Certificate.#generateTop(customer, sample, tenant, environment, coaStyles)}
					{Certificate.#generateMiddle(sample, user, tenant, coaStyles)}
					{Certificate.#generateBottom(tenant, environment, isClientSide, coaStyles)}
				</Page>
			</Document>
		);
	}

	static #generateBottom(tenant, environment, isClientSide, coaStyles) {
		return (
			<>
				<View wrap={false} style={coaStyles.section}>
					<Text style={coaStyles.header2}>Lab</Text>
					<View style={coaStyles.columns}>
						<View style={{ flex: 1 }}>
							<Text style={[coaStyles.paragraph, { fontWeight: "bold" }]}>{tenant.name}</Text>
							{tenant.address && <Text style={coaStyles.paragraph}>{tenant.address}</Text>}
							{tenant.email && <Text style={coaStyles.paragraph}>{tenant.email}</Text>}
							{tenant.phone && <Text style={coaStyles.paragraph}>{tenant.phone}</Text>}
						</View>
						{tenant.iso &&
							<View style={{ alignItems: "center", display: "flex", flex: 1.25, flexDirection: "column", justifyContent: "center" }}>
								<View style={coaStyles.paragraph}>
									<Image
										style={coaStyles.logoCertified}
										src={Certificate.#getImageUrl("/images/iso-accredited-laboratory.png", environment, isClientSide)}
									/>
								</View>
								<Text style={[coaStyles.paragraph, coaStyles.small]}>Accreditation Number: {tenant.iso}</Text>
							</View>
						}
						<View style={{ alignItems: "center", display: "flex", flex: 1.25, flexDirection: "column", justifyContent: "center" }}>
							<View style={coaStyles.paragraph}>
								<Image
									style={coaStyles.logoCertified}
									src={Certificate.#getImageUrl("/images/lab-axiom-logo-registered-laboratory.png", environment, isClientSide)}
								/>
							</View>
							<Text style={[coaStyles.paragraph, coaStyles.small]}>ID: {tenant.id}</Text>
						</View>
					</View>
				</View>
				<View wrap={false} style={coaStyles.footer} fixed>
					<View style={coaStyles.columns}>
						<View style={{ flex: 0.9 }}>
							<Text>Copyright {new Date().getFullYear()} {tenant.name}. All rights reserved.</Text>
						</View>
						<View style={{ flex: 0.1, textAlign: "right" }}>
							<Text render={({ pageNumber, totalPages }) => (<>Page {pageNumber} of {totalPages}</>)} />
						</View>
					</View>
				</View>
			</>
		);
	}

	static #generateMiddle(sample, user, tenant, coaStyles) {
		const qualifier = Certificate.getQualifier(sample);
		const certificationDisclaimer = "This " + (qualifier !== Certificate.QUALIFIER.NORMAL ? qualifier.toLowerCase() + " " : "") +
			"analysis is certified by " + tenant.name + ". It represents only the sample provided by the customer. It does not " +
			"constitute a guarantee of quality for the entire product from which the sample was taken, or for any similar product. " +
			"Any modification of this certificate renders it void.";
		return (
			<View style={coaStyles.section}>
				{Certificate.#generateResults(sample.results, coaStyles)}
				{sample.results && sample.results.map((result) => {
					if (result.coaNote) {
						return (
							<Text key={result.id} style={coaStyles.paragraph}>{result.coaNote}</Text>
						);
					} else {
						return null;
					}
				})}
				{sample.coaNote && <Text style={coaStyles.paragraph}>{sample.coaNote}</Text>}
				<View wrap={false}>
					<Text style={coaStyles.paragraph}>{certificationDisclaimer}</Text>
					<View style={coaStyles.columns}>
						<View style={coaStyles.column}>
							{user.name ? (
								<View style={coaStyles.paragraph}>
									{user["custom:signature"] &&
										<Image style={coaStyles.signature} src={user["custom:signature"]} />
									}
									<Text>
										{user.name}
										{user["custom:title"] && <>{"\n" + user["custom:title"]}</>}
									</Text>
								</View>
							) : (
								<Text style={coaStyles.paragraph}>{user.email}</Text>
							)}
							<Text style={coaStyles.paragraph}>{new Date().toISOString().replace("T", " ").replace("Z", " UTC")}</Text>
						</View>
					</View>
				</View>
			</View>
		);
	}

	static #generateResult(result, index, coaStyles) {
		let detectionLimitValue = null;
		if (result.detectionLimit) {
			detectionLimitValue = parseFloat(result.detectionLimit);
			detectionLimitValue = detectionLimitValue.toLocaleString("en-US");
			if (result.detectionLimitUOM) detectionLimitValue += " " + result.detectionLimitUOM;
		}
		let specificationValue = null;
		if (result.specificationValue || result.specificationValue === 0) {
			specificationValue = parseFloat(result.specificationValue);
			specificationValue = specificationValue.toLocaleString("en-US");
			if (result.specification === Certificate.SPECIFICATION.GREATER_THAN.LONG) {
				specificationValue = Certificate.SPECIFICATION.GREATER_THAN.SHORT + " " + specificationValue;
			} else if (result.specification === Certificate.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.LONG) {
				specificationValue = Certificate.SPECIFICATION.GREATER_THAN_OR_EQUAL_TO.SHORT + " " + specificationValue;
			} else if (result.specification === Certificate.SPECIFICATION.LESS_THAN.LONG) {
				specificationValue = Certificate.SPECIFICATION.LESS_THAN.SHORT + " " + specificationValue;
			} else if (result.specification === Certificate.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG) {
				specificationValue = Certificate.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.SHORT + " " + specificationValue;
			}
			if (result.specificationValueUOM) specificationValue += " " + result.specificationValueUOM;
			let specificationRangeValue = null;
			if (result.specificationRangeValue || result.specificationRangeValue === 0) {
				specificationRangeValue = parseFloat(result.specificationRangeValue);
				specificationRangeValue = specificationRangeValue.toLocaleString("en-US");
				if (result.specificationRange === Certificate.SPECIFICATION.LESS_THAN.LONG) {
					specificationRangeValue = Certificate.SPECIFICATION.LESS_THAN.SHORT + " " + specificationRangeValue;
				} else if (result.specificationRange === Certificate.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.LONG) {
					specificationRangeValue = Certificate.SPECIFICATION.LESS_THAN_OR_EQUAL_TO.SHORT + " " + specificationRangeValue;
				}
				if (result.specificationRangeValueUOM) specificationRangeValue += " " + result.specificationRangeValueUOM;
				specificationValue += Certificate.SPECIFICATION_RANGE_CONNECTOR + specificationRangeValue;
			}
		} else {
			specificationValue = result.specification;
		}
		let detectionResultValue = null;
		if (result.detectionResult || result.detectionResult === 0) {
			const referenceDetectionResultValue = parseFloat(result.detectionResult);
			detectionResultValue = referenceDetectionResultValue.toLocaleString("en-US");
			if (result.detectionLimit) {
				const referenceDetectionLimitValue = parseFloat(result.detectionLimit);
				if (referenceDetectionResultValue < referenceDetectionLimitValue) {
					detectionResultValue = Certificate.SPECIFICATION.LESS_THAN.SHORT + " " + referenceDetectionLimitValue.toLocaleString("en-US");
				}
			}
			if (result.detectionResultUOM) detectionResultValue += " " + result.detectionResultUOM;
		}
		return (
			<View style={result.inGroup ? coaStyles.tableRowGrouped : index !== 0 ? coaStyles.tableRow : coaStyles.tableRow1} key={result.id}>
				<View style={coaStyles.tableCell1}>
					<Text style={result.inGroup ? { paddingLeft: "0.05in" } : { fontWeight: "bold" }}>{result.name}</Text>
				</View>
				<View style={coaStyles.tableCell}><Text>{result.method}</Text></View>
				<View style={coaStyles.tableCell}><Text>{detectionLimitValue}</Text></View>
				<View style={coaStyles.tableCell}><Text>{specificationValue}</Text></View>
				<View style={coaStyles.tableCell}>
					<View style={coaStyles.cellContent}>
						<Text>{detectionResultValue}</Text>
						{(result.detectionResult || result.detectionResult === 0) && result.method === "By Input" &&
							<View style={coaStyles.superscriptContainer}><Text>‡</Text></View>
						}
						{result.detectionResult && result.specificationResult === Certificate.RESULT_STATUS.FAILED &&
							<View style={coaStyles.superscriptContainer}><Text>¶</Text></View>
						}
					</View>
				</View>
			</View>
		);
	}

	static #generateResults(results, coaStyles) {
		const { processedResults, byInput, failed } = Certificate.processResults(results);
		return (
			<View style={coaStyles.table}>
				<View style={coaStyles.tableHeader} fixed>
					<View style={coaStyles.tableRow1}>
						<View style={coaStyles.tableHeaderCell1}><Text>Test</Text></View>
						<View style={coaStyles.tableHeaderCell}><Text>Method</Text></View>
						<View style={coaStyles.tableHeaderCell}>
							<View style={coaStyles.cellContent}>
								<Text>MDL</Text>
								<View style={coaStyles.superscriptContainer}><Text>*</Text></View>
							</View>
						</View>
						<View style={coaStyles.tableHeaderCell}>
							<View style={coaStyles.cellContent}>
								<Text>Specification</Text>
								<View style={coaStyles.superscriptContainer}><Text>†</Text></View>
							</View>
						</View>
						<View style={coaStyles.tableHeaderCell}><Text>Result</Text></View>
					</View>
				</View>
				<View style={coaStyles.tableBody}>
					{processedResults && processedResults.map((result, index) => Certificate.#generateResult(result, index, coaStyles))}
				</View>
				<View style={coaStyles.tableFooter}>
					<Text>
						* Method detection limit (MDL) is the lowest concentration that the test method can reliably detect.
					</Text>
				</View>
				<View style={coaStyles.tableFooter}>
					<Text>
						† Specification is provided by the customer.
					</Text>
				</View>
				{byInput && <View style={coaStyles.tableFooter}>
					<Text>
						‡ Result for method "By Input" is calculated using information provided by the customer.
					</Text>
				</View>}
				{failed && <View style={coaStyles.tableFooter}>
					<Text>
						¶ Result is out of specification.
					</Text>
				</View>}
			</View>
		);
	}

	static #generateTop(customer, sample, tenant, environment, coaStyles) {
		const truncatedDateReceived = sample.dateReceived ? sample.dateReceived.slice(0, 10) : null;
		const truncatedTestDateCompleted = sample.testDateCompleted ? sample.testDateCompleted.slice(0, 10) : null;
		return (
			<>
				{environment !== Certificate.ENVIRONMENT.PROD &&
					<View style={coaStyles.watermarkContainer} fixed><Text style={coaStyles.watermarkText}>PREVIEW</Text></View>
				}
				{tenant.logo ? (
					<Image style={coaStyles.logo} src={tenant.logo} />
				) : (
					<Text style={[coaStyles.header1, { fontWeight: "bold" }]}>{tenant.name}</Text>
				)}
				<Text style={coaStyles.header1}>{Certificate.getTitle(sample)}</Text>
				<View wrap={false} style={coaStyles.section}>
					<View style={coaStyles.columns}>
						<View style={coaStyles.column}>
							<Text style={coaStyles.header2}>Sample</Text>
							<Text style={[coaStyles.paragraph, { fontWeight: "bold" }]}>{sample.name}</Text>
							<Text style={coaStyles.paragraph}>ID: {sample.id}</Text>
							<Text style={coaStyles.paragraph}>Code: {Certificate.getCode(sample.createdAt, sample.updatedAt)}</Text>
							{sample.lotNumber && <Text style={coaStyles.paragraph}>Lot Number: {sample.lotNumber}</Text>}
							{truncatedDateReceived && <Text style={coaStyles.paragraph}>Received: {truncatedDateReceived}</Text>}
							{truncatedTestDateCompleted && <Text style={coaStyles.paragraph}>Completed: {truncatedTestDateCompleted}</Text>}
							{sample.picture && <View style={coaStyles.paragraph}><Image style={coaStyles.sample} src={sample.picture} /></View>}
						</View>
						<View style={coaStyles.column}>
							<Text style={coaStyles.header2}>Customer</Text>
							<Text style={[coaStyles.paragraph, { fontWeight: "bold" }]}>{customer.name}</Text>
							{customer.address && <Text style={coaStyles.paragraph}>{customer.address}</Text>}
							{customer.email && <Text style={coaStyles.paragraph}>{customer.email}</Text>}
							{customer.phone && <Text style={coaStyles.paragraph}>{customer.phone}</Text>}
						</View>
					</View>
				</View>
			</>
		);
	}

	static getCode(createdAt, updatedAt) {
		const date = new Date(createdAt ? createdAt : updatedAt);
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const hour = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		return month.toString().padStart(2, "0") + day.toString().padStart(2, "0") +
			hour.toString().padStart(2, "0") + minute.toString().padStart(2, "0") + second.toString().padStart(2, "0");
	}

	static #getImageUrl(url, environment, isClientSide) {
		if (!isClientSide) url = Certificate.getBaseUrl(environment) + url;
		return url;
	}

	static getQualifier(sample) {
		if (sample && sample.testDateCompleted) {
			return sample.coaQualifier ? Certificate.QUALIFIER.AMENDED : Certificate.QUALIFIER.NORMAL;
		}
		return Certificate.QUALIFIER.PRELIMINARY;
	}

	static #getStyles() {
		return StyleSheet.create({
			cellContent: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "center"
			},
			column: {
				flex: 1
			},
			columns: {
				display: "flex",
				flexDirection: "row",
				gap: "0.25in"
			},
			footer: {
				bottom: "0.5in",
				color: "#333",
				fontSize: "0.12in",
				left: 0,
				padding: "0 0.5in",
				position: "absolute",
				right: 0
			},
			header1: {
				fontSize: "0.3in",
				textAlign: "center"
			},
			header2: {
				backgroundColor: "#6699CC",
				color: "#FFF",
				fontWeight: "bold",
				padding: "0.05in"
			},
			logo: {
				display: "block",
				margin: "0 auto",
				textAlign: "center",
				width: "3in"
			},
			logoCertified: {
				display: "block",
				height: "auto",
				margin: "0 auto",
				width: "2in"
			},
			page: {
				backgroundColor: "#FFF",
				color: "#000",
				fontFamily: "Roboto",
				fontSize: "0.15in",
				padding: "0.5in 0.5in 1in"
			},
			paragraph: {
				padding: "0.1in 0.05in 0"
			},
			sample: {
				display: "block",
				maxHeight: "1in",
				margin: "0.05in auto 0 0",
				width: "auto"
			},
			section: {
				padding: "0.25in 0 0"
			},
			signature: {
				display: "block",
				maxHeight: "0.33in",
				margin: "0 auto 0 0",
				width: "auto"
			},
			small: {
				color: "#333",
				fontSize: "0.12in"
			},
			superscriptContainer: {
				fontSize: "0.09in",
				lineHeight: "1",
				position: "relative",
				top: "-0.01in"
			},
			table: {
				display: "table",
				width: "100%"
			},
			tableBody: {
				borderBottom: "0.01in solid #999",
				color: "#000",
				fontWeight: "normal",
				width: "100%"
			},
			tableCell: {
				borderLeft: "0.01in solid #FFF",
				height: "100%",
				justifyContent: "center",
				padding: "0.05in",
				textAlign: "center",
				width: "17.5%"
			},
			tableCell1: {
				height: "100%",
				justifyContent: "center",
				padding: "0.05in",
				width: "30%"
			},
			tableFooter: {
				color: "#333",
				fontSize: "0.12in",
				padding: "0.05in 0.05in 0"
			},
			tableHeader: {
				backgroundColor: "#6699CC",
				color: "#FFF",
				fontWeight: "bold",
				marginBottom: "0.01in",
				width: "100%"
			},
			tableHeaderCell: {
				borderLeft: "0.01in solid #FFF",
				height: "100%",
				justifyContent: "center",
				padding: "0.05in",
				textAlign: "center",
				width: "17.5%"
			},
			tableHeaderCell1: {
				height: "100%",
				justifyContent: "center",
				padding: "0.05in",
				width: "30%"
			},
			tableRow: {
				alignItems: "center",
				borderTop: "0.01in solid #999",
				display: "flex",
				flexDirection: "row"
			},
			tableRow1: {
				alignItems: "center",
				display: "flex",
				flexDirection: "row"
			},
			tableRowGrouped: {
				alignItems: "center",
				display: "flex",
				flexDirection: "row"
			},
			watermarkContainer: {
				position: "absolute",
				top: "4in"
			},
			watermarkText: {
				color: "#CCC",
				fontSize: "2.2in",
				fontWeight: "bold",
				transform: "rotate(-45deg)"
			}
		});
	}

	static getTitle(sample, environment) {
		const qualifier = Certificate.getQualifier(sample);
		const title = (environment && environment !== Certificate.ENVIRONMENT.PROD ? "Preview " : "") +
			(qualifier !== Certificate.QUALIFIER.NORMAL ? qualifier + " " : "") +
			"Certificate of Analysis";
		return title;
	}

	static getType(plural = false) {
		return plural ? "coas" : "coa";
	}

	static getTypeTitle(plural = false) {
		return plural ? "Certificates of Analysis" : "Certificate of Analysis";
	}

	static #initialize() {
		Font.register({
			family: "Roboto",
			fonts: [
				{ src: "https://fonts.gstatic.com/s/roboto/v15/zN7GBFwfMP4uA6AR0HCoLQ.ttf" },
				{ src: "https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOKCWcynf_cDxXwCLxiixG1c.ttf", fontWeight: "bold" }
			]
		});
		Font.registerHyphenationCallback(word => (
			[word]
		));
		return Certificate.#getStyles();
	}

	static processResults(results = []) {
		let byInput = false;
		let failed = false;
		const isGroupResults = [];
		const otherResults = [];
		results.sort((a, b) => {
			if (a.type === b.type) return a.name.localeCompare(b.name);
			return a.type?.localeCompare(b.type) ?? 0;
		});
		for (const result of results) {
			if (result.method === "By Input") byInput = true;
			if (result.specificationResult === Certificate.RESULT_STATUS.FAILED) failed = true;
			if (result.isGroup) {
				isGroupResults.push(result);
			} else if (!result.inGroup) {
				otherResults.push(result);
			}
		}
		const inGroupResults = results.filter(result => result.inGroup && !result.isGroup);
		for (const group of isGroupResults) {
			const groupInGroupResults = inGroupResults.filter(
				inGroupResult => group.tenantIdShardTestIds && group.tenantIdShardTestIds.includes(inGroupResult.id)
			);
			//groupInGroupResults.sort((a, b) => a.name.localeCompare(b.name));
			const index = isGroupResults.indexOf(group);
			isGroupResults.splice(index + 1, 0, ...groupInGroupResults);
		}
		return { processedResults: [...isGroupResults, ...otherResults], byInput, failed };
	}

	static async renderToStream(customer, sample, user, tenant, environment) {
		const coa = Certificate.generate(customer, sample, user, tenant, environment);
		return await ReactPDF.renderToStream(coa);
	}

}

export { Certificate };