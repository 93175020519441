import TextField from "./text-field";
import { Warehouse as WarehouseIcon } from "@mui/icons-material";

export default function SampleStorageField(props) {
	return (
		<TextField {...props} name="storage" labelIcon={WarehouseIcon} label="Storage" valueDefault="Room Temperature">
			<option value="Room Temperature">Room Temperature</option>
			<option value="Refrigerated">Refrigerated</option>
			<option value="Frozen">Frozen</option>
		</TextField>
	);
}