import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { deleteItem, notifyCustomer, notifyCustomers } from "../api";
import useAlert from "../hooks/useAlert";
import useDialog from "../hooks/useDialog";
import { useNavigate, useRevalidator } from "react-router-dom";

export default function Dialog() {
	const { dialogConfiguration, setDialog } = useDialog();
	const navigate = useNavigate();
	const revalidator = useRevalidator();
	const { setAlert } = useAlert();
	const handleClose = () => {
		setDialog(null, null);
	};
	if (dialogConfiguration?.confirm) {
		const { title, message, onConfirm } = dialogConfiguration.confirm;
		const handleConfirm = async (event) => {
			let formButtons = null;
			try {
				formButtons = event.target.querySelectorAll("button");
				formButtons.forEach((button) => { button.disabled = true; });
				await onConfirm(event);
			} catch (error) {
				console.error("Error Confirming", error);
				setAlert("error", "Error Confirming");
			} finally {
				if (formButtons) formButtons.forEach((button) => { button.disabled = false; });
				handleClose();
			}
		};
		return (
			<MuiDialog id="ConfirmDialog" open onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm}>Confirm</Button>
				</DialogActions>
			</MuiDialog>
		);
	} else if (dialogConfiguration?.deleteItem) {
		const deleteItemType = dialogConfiguration.deleteItem.item.class.getType();
		const deleteItemTypeMultiple = dialogConfiguration.deleteItem.item.class.getType(true);
		const deleteItemTypeTitle = dialogConfiguration.deleteItem.item.class.getTypeTitle();
		const deleteItemTenantIdShard = dialogConfiguration.deleteItem.item.tenantIdShard;
		const deleteItemId = dialogConfiguration.deleteItem.item.id;
		const deleteItemName = dialogConfiguration.deleteItem.item.name;
		const handleItemDelete = async (event) => {
			let formButtons = null;
			try {
				formButtons = event.target.querySelectorAll("button");
				formButtons.forEach((button) => { button.disabled = true; });
				await deleteItem(deleteItemType, deleteItemTenantIdShard, deleteItemId);
				setAlert("success", "Deleted " + deleteItemTypeTitle);
				if (window.location.pathname.startsWith("/" + deleteItemType + "/")) {
					navigate("/" + deleteItemTypeMultiple);
				} else {
					revalidator.revalidate();
				}
			} catch (error) {
				console.error("Error Deleting " + deleteItemTypeTitle, error);
				setAlert("error", "Error Deleting " + deleteItemTypeTitle);
			} finally {
				if (formButtons) formButtons.forEach((button) => { button.disabled = false; });
				handleClose();
			}
		};
		return (
			<MuiDialog id="DeleteItemDialog" open onClose={handleClose}>
				<DialogTitle>Delete {deleteItemTypeTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete {deleteItemType} "{deleteItemName}"?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleItemDelete}>Delete</Button>
				</DialogActions>
			</MuiDialog>
		);
	} else if (dialogConfiguration?.notifyCustomer) {
		const tenantIdShardCustomerId = dialogConfiguration.notifyCustomer.tenantIdShardCustomerId;
		const tenantIdShardSampleId = dialogConfiguration.notifyCustomer.tenantIdShardSampleId;
		const customerName = dialogConfiguration.notifyCustomer.customerName;
		const handleNotify = async (event) => {
			let formButtons = null;
			try {
				formButtons = event.target.querySelectorAll("button");
				formButtons.forEach((button) => { button.disabled = true; });
				await notifyCustomer(tenantIdShardCustomerId, tenantIdShardSampleId);
				setAlert("success", "Notified Customer");
				revalidator.revalidate();
			} catch (error) {
				console.error("Error Notifying Customer", error);
				setAlert("error", "Error Notifying Customer");
			} finally {
				if (formButtons) formButtons.forEach((button) => { button.disabled = false; });
				handleClose();
			}
		}
		return (
			<MuiDialog id="NotifyDialog" open onClose={handleClose}>
				<DialogTitle>Notify Customer</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to notify {customerName} of this certificate of analysis?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleNotify}>Notify</Button>
				</DialogActions>
			</MuiDialog>
		);
	} else if (dialogConfiguration?.notifyCustomers) {
		const handleNotify = async (event) => {
			let formButtons = null;
			try {
				formButtons = event.target.querySelectorAll("button");
				formButtons.forEach((button) => { button.disabled = true; });
				const count = await notifyCustomers();
				setAlert("success", "Notified " + count + " Customers");
				revalidator.revalidate();
			} catch (error) {
				console.error("Error Notifying Customers", error);
				setAlert("error", "Error Notifying Customers");
			} finally {
				if (formButtons) formButtons.forEach((button) => { button.disabled = false; });
				handleClose();
			}
		}
		return (
			<MuiDialog id="NotifyDialog" open onClose={handleClose}>
				<DialogTitle>Notify Customers</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to notify customers of new certificates of analysis?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleNotify}>Notify</Button>
				</DialogActions>
			</MuiDialog>
		);
	} else if (dialogConfiguration?.removeSubitem) {
		const removeSubitemType = dialogConfiguration.removeSubitem.class.getType();
		const removeSubitemTypeTitle = dialogConfiguration.removeSubitem.class.getTypeTitle();
		const removeSubitemId = dialogConfiguration.removeSubitem.id;
		const removeSubitemName = dialogConfiguration.removeSubitem.name;
		const handleSubitemRemove = (event) => {
			let formButtons = null;
			try {
				formButtons = event.target.querySelectorAll("button");
				formButtons.forEach((button) => { button.disabled = true; });
				setAlert("success", "Removed " + removeSubitemTypeTitle);
				dialogConfiguration.removeSubitem.callback(removeSubitemId);
			} catch (error) {
				console.error("Error Removing " + removeSubitemTypeTitle, error);
				setAlert("error", "Error Removing " + removeSubitemTypeTitle);
			} finally {
				if (formButtons) formButtons.forEach((button) => { button.disabled = false; });
				handleClose();
			}
		};
		return (
			<MuiDialog id="RemoveSubitemDialog" open onClose={handleClose}>
				<DialogTitle>Remove {removeSubitemTypeTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to remove {removeSubitemType}{removeSubitemName ? " \"" + removeSubitemName + "\"" : ""}?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSubitemRemove}>Remove</Button>
				</DialogActions>
			</MuiDialog>
		);
	} else {
		return null;
	}
}