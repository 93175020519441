import Alert from "../components/alert";
import Dialog from "../components/dialog";
import Footer from "../components/footer";
import Header from "../components/header";
import { Outlet } from "react-router-dom";
import { Paper } from "@mui/material";
import Veil from "../components/veil";

export default function Root() {
	return (
		<>
			<Alert />
			<Veil>
				<Dialog />
				<Header />
				<Paper elevation={1} sx={{ padding: 2, flexGrow: 1, minHeight: "calc(100vh - 128px)" }}>
					<Outlet />
				</Paper>
				<Footer />
			</Veil>
		</>
	);
}