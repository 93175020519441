import StatusField from "./status-field";

export const COA_NOTIFICATION_STATUS = {
	FAILED: "Failed",
	PENDING: "Pending",
	SENT: "Sent"
};

export default function COANotificationStatusField(props) {
	const label = props.label ? props.label : "Certificate Notification Status";
	const status = props.value === COA_NOTIFICATION_STATUS.SENT ? "green" : props.value === COA_NOTIFICATION_STATUS.FAILED ? "red" : "yellow";
	return (
		<StatusField {...props} status={status} label={label} />
	);
}