import React from "react";
import { Autocomplete, CircularProgress, Unstable_Grid2 as Grid } from "@mui/material";
import Field from "./field";
import { listItems } from "../api";
import TextField from "./text-field";
import useAlert from "../hooks/useAlert";
import useVeil from "../hooks/useVeil";

export default function ReferenceField(props) {
	const hideLabel = props.hideLabel === "true" ? true : false;
	const hideLink = props.hideLink === "true" ? true : false;
	const Icon = props.icon;
	const label = props.label;
	const multiple = props.multiple === "true" ? true : false;
	const onChange = props.onChange;
	const typeTitle = props.class.getTypeTitle();
	const [options, setOptions] = React.useState([]);
	const [optionsOpen, setOptionsOpen] = React.useState(false);
	const optionsLoading = optionsOpen && options.length === 0;
	const { setAlert } = useAlert();
	const { setVeil } = useVeil();
	const [value, setValue] = React.useState(multiple ? [] : null);
	const [valueInitialized, setValueInitialized] = React.useState(false);
	const propsDefaultItems = React.useMemo(() => {
		let defaultItems = [];
		if (props.defaultIdReference) {
			defaultItems.push(props.class.getReferenceFromCombinedId(props.defaultIdReference));
		} else if (props.defaultIdReferences) {
			if (Array.isArray(props.defaultIdReferences)) {
				props.defaultIdReferences.forEach((defaultIdReferencesSub) => {
					defaultItems.push(props.class.getReferenceFromCombinedId(defaultIdReferencesSub));
				});
			} else {
				defaultItems.push(props.class.getReferenceFromCombinedId(props.defaultIdReferences));
			}
		}
		return defaultItems;
	}, [props.defaultIdReference, props.defaultIdReferences, props.class]);
	const propsItems = React.useMemo(() => {
		let items = [];
		if (props.idReference) {
			items.push(props.class.getReferenceFromCombinedId(props.idReference));
		} else if (props.idReferences) {
			if (Array.isArray(props.idReferences)) {
				props.idReferences.forEach((idReferencesSub) => {
					items.push(props.class.getReferenceFromCombinedId(idReferencesSub));
				});
			} else {
				items.push(props.class.getReferenceFromCombinedId(props.idReferences));
			}
		}
		if (items.length === 0) {
			items = propsDefaultItems;
		}
		return items;
	}, [props.idReference, props.idReferences, propsDefaultItems, props.class]);
	React.useEffect(() => {
		const initializeValue = async () => {
			if (!valueInitialized) {
				if (propsItems.length > 0) {
					const initialValue = multiple ? propsItems : propsItems[0];
					if (onChange) await onChange(initialValue, true);
					setValue(initialValue);
				}
				setValueInitialized(true);
			}
		};
		initializeValue();
	}, [multiple, onChange, propsItems, valueInitialized]);
	React.useEffect(() => {
		if (!optionsOpen) setOptions([]);
	}, [optionsOpen]);
	React.useEffect(() => {
		let isComponentMounted = true;
		const fetchAndProcessItems = async () => {
			if (!optionsLoading) return;
			try {
				const items = await listItems(props.class.getType(), props.class.getIdIndex());
				const allOptions = items.map((item) => props.class.getReferenceFromData(item));
				if (propsDefaultItems) allOptions.push(...propsDefaultItems);
				const filteredOptions = allOptions.filter(option => {
					return option.id !== props.id && (!props.filter || !option.nameContext.includes(props.filter));
				});
				filteredOptions.sort((a, b) => a.nameContext?.localeCompare(b.nameContext));
				if (isComponentMounted) setOptions(filteredOptions);
			} catch (error) {
				console.error("Error Fetching Reference Items", error);
				setAlert("error", "Error Fetching Reference Items");
			}
		};
		fetchAndProcessItems();
		return () => {
			isComponentMounted = false;
		};
	}, [optionsLoading, props.class, props.filter, props.id, propsDefaultItems, setAlert]);
	const handleChange = async (value) => {
		try {
			setVeil(true);
			if (multiple) value?.sort((a, b) => a.nameContext.localeCompare(b.nameContext));
			if (onChange) await onChange(value);
			setValue(value);
		} catch (error) {
			console.error("Error Changing Reference", error);
			setAlert("error", "Error Changing Reference");
		} finally {
			setVeil(false);
		}
	}
	if (props.mode === "edit") {
		return (
			<Grid xs={12} spacing={2} sx={{ padding: 0 }}>
				<Autocomplete
					autoHighlight={multiple ? false : true}
					autoSelect={multiple ? false : true}
					blurOnSelect={multiple ? false : true}
					disableCloseOnSelect={multiple ? true : false}
					disablePortal
					getOptionLabel={(option) => option?.nameContext ? option.nameContext : ""}
					isOptionEqualToValue={(option, value) => { return option.id === value?.id; }}
					loading={optionsLoading}
					multiple={multiple ? true : false}
					onChange={(event, value) => { handleChange(value); }}
					onClose={() => { setOptionsOpen(false); }}
					onOpen={() => { setOptionsOpen(true); }}
					open={optionsOpen}
					options={options}
					renderInput={
						(parameters) => (
							<TextField
								{...parameters}
								InputProps={{
									...parameters.InputProps,
									required: props.required === "true" ? (!value || value.length === 0) : null,
									endAdornment: (
										<>
											{optionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{parameters.InputProps.endAdornment}
										</>
									)
								}}
								label={label ? label : (typeTitle + (multiple ? "s" : ""))}
								mode="edit"
								required={props.required}
							/>
						)
					}
					renderOption={
						(props, option) => <li {...props} key={option.id}>{option.nameContext}</li>
					}
					selectOnFocus={multiple ? false : true}
					value={value}
				/>
			</Grid>
		);
	} else {
		return (
			<Field
				label={label ? label : (typeTitle + (multiple ? "s" : ""))}
				labelIcon={Icon}
				showLabel={hideLabel ? "false" : "true"}
				submode={props.submode}
				value={value}
				valueDisplay={multiple ? "nameContext" : value?.nameContext}
				valueLink={
					multiple ? "viewUrl" : (
						hideLink || value?.tenantIdShard?.startsWith(value?.id)) ? null
						: props.class.getViewUrlFromInputs(value?.tenantIdShard, value?.id
					)
				}
				valueLinkReplace="true"
			/>
		);
	}
}