import About from "./routes/about";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Customer as CustomerClass } from "./classes/customer";
import CustomerEdit from "./routes/customer-edit";
import Customers from "./routes/customers";
import CustomerView from "./routes/customer-view";
import Error from "./routes/error";
import Home from "./routes/home";
import { Lab as LabClass } from "./classes/lab";
import LabEdit from "./routes/lab-edit";
import Labs from "./routes/labs";
import LabView from "./routes/lab-view";
import { loader as itemLoader } from "./components/item";
import { loader as itemsLoader } from "./components/items";
import Root from "./routes/root";
import { Sample as SampleClass } from "./classes/sample";
import SampleCertify from "./routes/sample-certify";
import SampleEdit from "./routes/sample-edit";
import Samples from "./routes/samples";
import SampleView from "./routes/sample-view";
import Support from "./routes/support";
import { Test as TestClass } from "./classes/test";
import TestEdit from "./routes/test-edit";
import Tests from "./routes/tests";
import TestView from "./routes/test-view";

export default function Router() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			errorElement: <Error />,
			children: [
				{
					index: true,
					element: <Home />
				},
				{
					path: "about",
					element: <About />
				},
				{
					path: CustomerClass.getType(),
					element: <Navigate to={"/" + CustomerClass.getType(true)} replace />
				},
				{
					path: CustomerClass.getType() + "/edit",
					element: <Navigate to={"/" + CustomerClass.getType(true)} replace />
				},
				{
					path: CustomerClass.getType() + "/edit/:id",
					element: <CustomerEdit />,
					loader: (args) => itemLoader({ Class: CustomerClass, ...args })
				},
				{
					path: CustomerClass.getType() + "/view",
					element: <Navigate to={"/" + CustomerClass.getType(true)} replace />
				},
				{
					path: CustomerClass.getType() + "/view/:id",
					element: <CustomerView />,
					loader: (args) => itemLoader({ Class: CustomerClass, ...args })
				},
				{
					path: CustomerClass.getType(true),
					element: <Customers />,
					loader: (args) => itemsLoader({ Class: CustomerClass, ...args })
				},
				{
					path: LabClass.getType(),
					element: <Navigate to={"/" + LabClass.getType(true)} replace />
				},
				{
					path: LabClass.getType() + "/edit",
					element: <Navigate to={"/" + LabClass.getType(true)} replace />
				},
				{
					path: LabClass.getType() + "/edit/:id",
					element: <LabEdit />,
					loader: (args) => itemLoader({ Class: LabClass, ...args })
				},
				{
					path: LabClass.getType() + "/view",
					element: <Navigate to={"/" + LabClass.getType(true)} replace />
				},
				{
					path: LabClass.getType() + "/view/:id",
					element: <LabView />,
					loader: (args) => itemLoader({ Class: LabClass, ...args })
				},
				{
					path: LabClass.getType(true),
					element: <Labs />,
					loader: (args) => itemsLoader({ Class: LabClass, ...args })
				},
				{
					path: SampleClass.getType(),
					element: <Navigate to={"/" + SampleClass.getType(true)} replace />
				},
				{
					path: SampleClass.getType() + "/certify",
					element: <Navigate to={"/" + SampleClass.getType(true)} replace />
				},
				{
					path: SampleClass.getType() + "/certify/:id",
					element: <SampleCertify />,
					loader: (args) => itemLoader({ Class: SampleClass, ...args })
				},
				{
					path: SampleClass.getType() + "/edit",
					element: <Navigate to={"/" + SampleClass.getType(true)} replace />
				},
				{
					path: SampleClass.getType() + "/edit/:id",
					element: <SampleEdit />,
					loader: (args) => itemLoader({ Class: SampleClass, ...args })
				},
				{
					path: SampleClass.getType() + "/view",
					element: <Navigate to={"/" + SampleClass.getType(true)} replace />
				},
				{
					path: SampleClass.getType() + "/view/:id",
					element: <SampleView />,
					loader: (args) => itemLoader({ Class: SampleClass, ...args })
				},
				{
					path: SampleClass.getType(true),
					element: <Samples />,
					loader: (args) => itemsLoader({ Class: SampleClass, ...args })
				},
				{
					path: "support",
					element: <Support />
				},
				{
					path: TestClass.getType(),
					element: <Navigate to={"/" + TestClass.getType(true)} replace />
				},
				{
					path: TestClass.getType() + "/edit",
					element: <Navigate to={"/" + TestClass.getType(true)} replace />
				},
				{
					path: TestClass.getType() + "/edit/:id",
					element: <TestEdit />,
					loader: (args) => itemLoader({ Class: TestClass, ...args })
				},
				{
					path: TestClass.getType() + "/view",
					element: <Navigate to={"/" + TestClass.getType(true)} replace />
				},
				{
					path: TestClass.getType() + "/view/:id",
					element: <TestView />,
					loader: (args) => itemLoader({ Class: TestClass, ...args })
				},
				{
					path: TestClass.getType(true),
					element: <Tests />,
					loader: (args) => itemsLoader({ Class: TestClass, ...args })
				}
			]
		}
	]);
	return (
		<RouterProvider router={router} />
	);
}

export function setDocumentTitle(subtitle) {
	document.title = subtitle ? "Lab Axiom | " + subtitle : "Lab Axiom";
}