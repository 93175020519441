import React from "react";
import { Unstable_Grid2 as Grid } from "@mui/material";
import NumberField from "./number-field";
import PropTypes from "prop-types";
import TextField from "./text-field";

ComboField.propTypes = {
	children: PropTypes.node,
};

export default function ComboField(props) {
	const inputStep = props.inputStep ? props.inputStep : "1";
	const [value, setValue] = React.useState("");
	const [qualifierValue, setQualifierValue] = React.useState("");
	React.useEffect(() => {
		props.value ? setValue(props.value) : props.valueDefault ? setValue(props.valueDefault) : setValue("");
	}, [props.value, props.valueDefault]);
	React.useEffect(() => {
		props.qualifierValue ? setQualifierValue(props.qualifierValue) : props.qualifierValueDefault ? setQualifierValue(props.qualifierValueDefault) : setQualifierValue("");
	}, [props.qualifierValue, props.qualifierValueDefault]);
	const handleChange = (event) => {
		if (props.onChange) props.onChange(event);
		setValue(event.target.value);
	}
	const handleQualifierChange = (event) => {
		if (props.onQualifierChange) props.onQualifierChange(event);
		setQualifierValue(event.target.value);
	}
	if (props.mode === "edit") {
		return (
			<>
				<Grid xs={6} spacing={2} sx={{ padding: 0 }}>
					<NumberField name={props.name} label={props.label} value={value} inputStep={inputStep} required={props.required} onChange={handleChange} mode="edit" />
				</Grid>
				<Grid xs={6} spacing={2} sx={{ padding: 0 }}>
					{props.children?.length > 0 ? (
						<TextField name={props.qualifierName} label={props.qualifierLabel} value={qualifierValue} required={props.qualifierRequired} onChange={handleQualifierChange} mode="edit">
							{props.children}
						</TextField>
					) : (
						<TextField name={props.qualifierName} label={props.qualifierLabel} value={qualifierValue} required={props.qualifierRequired} onChange={handleQualifierChange} mode="edit" />
					)}
				</Grid>
			</>
		);
	} else {
		return (
			<NumberField {...props} valueSuffix={" " + qualifierValue} />
		);
	}
}