import { Contact as ContactClass } from "../classes/contact";
import EmailField from "./email-field";
import PhoneField from "./phone-field";
import Subitems from "./subitems";
import TextField from "./text-field";

export default function Contacts(props) {
	const columns = [
		{
			field: "title",
			type: "string",
			headerName: "Title",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				return (
					<TextField name="title" value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "email",
			type: "string",
			headerName: "Email",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				return (
					<EmailField value={params.value} mode={props.mode} submode="naked" />
				);
			}
		},
		{
			field: "phone",
			type: "string",
			headerName: "Phone",
			editable: props.mode === "edit" ? true : false,
			renderCell: (params) => {
				return (
					<PhoneField value={params.value} mode={props.mode} submode="naked" />
				);
			}
		}
	];
	const columnVisibilityModel = props.columnVisibilityModel ? props.columnVisibilityModel : {};
	if (window.innerWidth < 300) columnVisibilityModel.email = false;
	columnVisibilityModel.phone = true;
	if (window.innerWidth < 600) columnVisibilityModel.title = false;
	return (
		<Subitems
			class={ContactClass}
			columns={columns}
			columnVisibilityModel={columnVisibilityModel}
			data={props.data}
			mode={props.mode}
			onChange={props.onChange}
		/>
	);
}