import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	Unstable_Grid2 as Grid
} from "@mui/material";
import Certificate from "./certificate";
import { Certificate as CertificateClass } from "../classes/certificate";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, Save as SaveIcon } from "@mui/icons-material";
import { createItem } from "../api";
import { Customer as CustomerClass } from "../classes/customer";
import { Form, useNavigate } from "react-router-dom";
import { Icon as CustomerIcon } from "./customer";
import { Icon as SampleIcon } from "./sample";
import ReferenceField from "./reference-field";
import { Sample as SampleClass } from "../classes/sample";
import TextField from "./text-field";
import Title from "./title";
import useAlert from "../hooks/useAlert";
import useSession from "../hooks/useSession";
import useVeil from "../hooks/useVeil";

export default function Certificates(props) {
	const data = props.data;
	const [accordionExpanded, setAccordionExpanded] = React.useState(null);
	const [certifiedChecked, setCertifiedChecked] = React.useState(false);
	const [formDisabled, setFormDisabled] = React.useState(false);
	const navigate = useNavigate();
	const [notifyBillingCustomerChecked, setNotifyBillingCustomerChecked] = React.useState(false);
	const [notifyCoACustomersChecked, setNotifyCoACustomersChecked] = React.useState(false);
	const [notifyCustomersChecked, setNotifyCustomersChecked] = React.useState(false);
	const { session } = useSession();
	const authorized = session.groups.includes("Manager");
	const { setAlert } = useAlert();
	const { setVeil } = useVeil();
	const handleAccordionChange = (panel) => (event, newExpanded) => {
		setAccordionExpanded(newExpanded ? panel : false);
	};
	const handleCertificateFormClose = () => {
		if (formDisabled) return;
		navigate("/sample/view/" + data.tenantIdShard + CertificateClass.COMBINED_ID_DELIMITER + data.id);
	};
	const handleCertificateFormSubmit = async (event) => {
		event.preventDefault();
		if (formDisabled) return;
		try {
			setVeil(true);
			setFormDisabled(true);
			await createItem("certificate", {
				"tenantIdShardSampleId": data.tenantIdShard + CertificateClass.COMBINED_ID_DELIMITER + data.id,
				"notifyBillingCustomer": notifyBillingCustomerChecked,
				"notifyCoACustomers": notifyCoACustomersChecked,
				"notifyCustomers": notifyCustomersChecked,
				"certified": certifiedChecked
			});
			setAlert("success", "Certified Sample");
			navigate(SampleClass.getViewUrlFromInputs(data.tenantIdShard, data.id));
		} catch (error) {
			console.error("Error Certifying Sample", error);
			setAlert("error", "Error Certifying Sample: " + error.message);
		} finally {
			setFormDisabled(false);
			setVeil(false);
		}
	};
	const handleCertifiedChange = (event) => {
		setCertifiedChecked(event.target.checked);
	};
	const handleNotifyBillingCustomerChange = (event) => {
		setNotifyBillingCustomerChecked(event.target.checked);
		if (event.target.checked && notifyCoACustomersChecked) {
			setNotifyCustomersChecked(true);
		} else {
			setNotifyCustomersChecked(false);
		}
	};
	const handleNotifyCoACustomersChange = (event) => {
		setNotifyCoACustomersChecked(event.target.checked);
		if (notifyBillingCustomerChecked && event.target.checked) {
			setNotifyCustomersChecked(true);
		} else {
			setNotifyCustomersChecked(false);
		}
	};
	const handleNotifyCustomersChange = (event) => {
		setNotifyCustomersChecked(event.target.checked);
		setNotifyBillingCustomerChecked(event.target.checked);
		setNotifyCoACustomersChecked(event.target.checked);
	};
	const notificationStatement = "Notify " + (data.tenantIdShardCustomerIdReferences ? "All " : "") +
		"Customer" + (data.tenantIdShardCustomerIdReferences ? "s" : "");
	const qualifier = CertificateClass.getQualifier(data);
	const certificationStatement = "I, " + (session.user.name ? session.user.name : session.user.email) + ", certify " +
		(qualifier !== CertificateClass.QUALIFIER.NORMAL ? qualifier.toLowerCase() + " " : "") +
		"analysis of this sample on behalf of " + session.tenant.name + ".";
	return (
		<Grid container spacing={2} sx={{ padding: 0 }}>
			<Grid xs={12}>
				<Paper elevation={2} sx={{ padding: 2, height: "100%" }}>
					{!authorized ? "Unauthorized" : (
						<Form id="CertificateForm" onSubmit={handleCertificateFormSubmit} onKeyDown={(e) => {
							const elementType = document.activeElement.type;
							if (e.key === "Enter" && elementType !== "submit" && elementType !== "button" && elementType !== "textarea") {
								e.preventDefault();
							}
						}}>
							<Grid container spacing={2} sx={{ padding: 0 }}>
								<Grid xs={10} display="flex" justifyContent="left" alignItems="center">
									<Title marginBottom="0">{CertificateClass.getTitle(data)}</Title>
								</Grid>
								<Grid xs={2} display="flex" justifyContent="right" alignItems="center">
									<Tooltip title="Close">
										<IconButton disabled={formDisabled} onClick={() => handleCertificateFormClose()} size="small">
											<CloseIcon />
										</IconButton>
									</Tooltip>
								</Grid>
								<TextField name="name" showLabel="false" labelIcon={SampleIcon} label="Name" value={data.name} mode="view" />
								<ReferenceField
									class={CustomerClass}
									icon={CustomerIcon}
									label="Billing Customer"
									mode="view"
									id={data.id}
									idReference={data.tenantIdShardCustomerIdReference}
								/>
								<Grid xs={12}>
									<Accordion
										elevation={3}
										TransitionProps={{ unmountOnExit: true }}
										expanded={accordionExpanded === data.tenantIdShardCustomerIdReference}
										onChange={handleAccordionChange(data.tenantIdShardCustomerIdReference)}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography>Preview for {CustomerClass.getNameFromCombinedId(data.tenantIdShardCustomerIdReference)}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Certificate
												customer={data.tenantIdShardCustomerIdReference}
												sample={data}
												user={session.user}
												tenant={session.tenant}
											/>
										</AccordionDetails>
									</Accordion>
								</Grid>
								{data.tenantIdShardCustomerIdReferences &&
									<>
										<Grid xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														name="notifyBillingCustomer"
														checked={notifyBillingCustomerChecked}
														onChange={handleNotifyBillingCustomerChange}
													/>
												}
												label={<Typography>Notify Billing Customer</Typography>}
											/>
										</Grid>
										<ReferenceField
											class={CustomerClass}
											icon={CustomerIcon}
											label="Certificate of Analysis Customers"
											multiple="true"
											mode="view"
											id={data.id}
											idReferences={data.tenantIdShardCustomerIdReferences}
										/>
										<Grid xs={12}>
											{Array.isArray(data.tenantIdShardCustomerIdReferences) ? data.tenantIdShardCustomerIdReferences.map(
												(tenantIdShardCustomerIdReference, index) => (
													<Accordion
														key={index}
														elevation={3}
														TransitionProps={{ unmountOnExit: true }}
														expanded={accordionExpanded === tenantIdShardCustomerIdReference}
														onChange={handleAccordionChange(tenantIdShardCustomerIdReference)}
													>
														<AccordionSummary expandIcon={<ExpandMoreIcon />}>
															<Typography>
																Preview for {CustomerClass.getNameFromCombinedId(tenantIdShardCustomerIdReference)}
															</Typography>
														</AccordionSummary>
														<AccordionDetails>
															<Certificate
																customer={tenantIdShardCustomerIdReference}
																sample={data}
																user={session.user}
																tenant={session.tenant}
															/>
														</AccordionDetails>
													</Accordion>
												)
											) : (
												<Accordion
													elevation={3}
													TransitionProps={{ unmountOnExit: true }}
													expanded={accordionExpanded === data.tenantIdShardCustomerIdReferences}
													onChange={handleAccordionChange(data.tenantIdShardCustomerIdReferences)}
												>
													<AccordionSummary expandIcon={<ExpandMoreIcon />}>
														<Typography>
															Preview for {CustomerClass.getNameFromCombinedId(data.tenantIdShardCustomerIdReferences)}
														</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<Certificate
															customer={data.tenantIdShardCustomerIdReferences}
															sample={data}
															user={session.user}
															tenant={session.tenant}
														/>
													</AccordionDetails>
												</Accordion>
											)}
										</Grid>
										<Grid xs={12}>
											<FormControlLabel
												control={<Checkbox name="notifyCoACustomers"
													checked={notifyCoACustomersChecked}
													onChange={handleNotifyCoACustomersChange} />}
												label={<Typography>Notify Certificate of Analysis Customers</Typography>}
											/>
										</Grid>
									</>
								}
								<Grid xs={12}>
									<FormControlLabel
										control={
											<Checkbox name="notifyCustomers" checked={notifyCustomersChecked} onChange={handleNotifyCustomersChange}
												indeterminate={notifyBillingCustomerChecked !== notifyCoACustomersChecked}
											/>
										}
										label={<Typography>{notificationStatement}</Typography>}
									/>
								</Grid>
								<Grid xs={12}>
									<FormControlLabel
										control={<Checkbox name="certified" checked={certifiedChecked} onChange={handleCertifiedChange} required />}
										label={
											<Typography>{certificationStatement}</Typography>
										}
									/>
								</Grid>
								<Grid xs={6} display="flex" justifyContent="right" alignItems="center">
									<Button type="submit" disabled={formDisabled} startIcon={<SaveIcon />} variant="contained">
										Save
									</Button>
								</Grid>
								<Grid xs={6} display="flex" justifyContent="left" alignItems="center">
									<Button
										disabled={formDisabled}
										onClick={() => handleCertificateFormClose()}
										startIcon={<CloseIcon />}
										variant="outlined"
									>
										Close
									</Button>
								</Grid>
							</Grid>
						</Form>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}