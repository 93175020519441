import React from "react";
import { Box, Link, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import PropTypes from "prop-types";

Field.propTypes = {
	children: PropTypes.node
};

export default function Field(props) {
	const append = props.append;
	const boldValue = props.boldValue === "true" ? true : false;
	const label = props.label;
	const LabelIcon = props.labelIcon;
	const newline = props.newline;
	const showLabel = props.showLabel === "false" ? false : true;
	const [value, setValue] = React.useState(null);
	const valueColor = props.valueColor;
	const valueDisplay = props.valueDisplay ? props.valueDisplay : value;
	const ValueIcon = props.valueIcon;
	const valueLink = props.valueLink;
	const valueLinkReplace = props.valueLinkReplace === "true" ? true : false;
	const valuePrefix = props.valuePrefix;
	const valueSuffix = props.valueSuffix;
	React.useEffect(() => {
		if (props.value || props.value === 0) {
			setValue(props.value);
		} else if (props.valueDefault || props.valueDefault === 0) {
			setValue(props.valueDefault);
		} else {
			setValue(null);
		}
	}, [props.value, props.valueDefault]);
	if ((value || value === 0) && (!Array.isArray(value) || value.length > 0)) {
		if (props.submode === "naked") {
			return FieldViewNaked(ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline);
		} else if (props.submode === "box") {
			return FieldViewBox(showLabel, LabelIcon, label, ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, boldValue, newline);
		} else {
			return FieldViewGrid(showLabel, LabelIcon, label, ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline, append);
		}
	}
}

function FieldViewBox(showLabel, LabelIcon, label, ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, boldValue, newline) {
	const iconStyle = { fontSize: "inherit", fontWeight: "inherit", marginRight: 0.75, verticalAlign: "middle" };
	const valueStyle = { fontSize: "inherit", fontWeight: boldValue ? "bold" : "inherit", verticalAlign: "middle" };
	return (
		<Box>
			{LabelIcon && <LabelIcon fontSize="small" sx={iconStyle} />}
			{label && showLabel && <Typography component="span" sx={iconStyle}>{label}:</Typography>}
			{FieldViewNaked(ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline, valueStyle)}
		</Box>
	);
}

function FieldViewGrid(showLabel, LabelIcon, label, ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline, append) {
	const iconStyle = { fontSize: "inherit", fontWeight: "inherit", marginRight: 0.75, verticalAlign: "middle" };
	const valueStyle = { fontSize: "inherit", fontWeight: "inherit", verticalAlign: "middle" };
	return (
		<Grid xs={12}>
			{label && showLabel &&
				<Box sx={{ fontWeight: "bold" }}>
					{LabelIcon && <LabelIcon fontSize="small" sx={iconStyle} />}
					<Typography component="span" sx={valueStyle}>{label}</Typography>
				</Box>
			}
			<Box sx={{ fontWeight: (!label || !showLabel) ? "bold" : "inherit", marginBottom: append ? 0.75 : 0 }}>
				{LabelIcon && (!label || !showLabel) && <LabelIcon fontSize="small" sx={iconStyle} />}
				{FieldViewNaked(ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline, valueStyle)}
			</Box>
			{append}
		</Grid>
	);
}

function FieldViewNaked(ValueIcon, valueLink, valueLinkReplace, valuePrefix, value, valueDisplay, valueSuffix, valueColor, newline, valueStyle, index) {
	if (Array.isArray(value)) {
		if (value.length === 0) return null;
		return value.sort(
			(a, b) => a[valueDisplay].localeCompare(b[valueDisplay])
		).map(
			(item, index) => FieldViewNaked(ValueIcon, item[valueLink], valueLinkReplace, valuePrefix, item, item[valueDisplay], valueSuffix, valueColor, newline, valueStyle, index)
		).reduce(
			(prev, curr) => [prev, <span key={"comma" + index}>,&ensp;</span>, curr]
		);
	} else {
		const iconStyle = { color: valueColor ? valueColor : "inherit", fontSize: "inherit", fontWeight: "inherit", marginRight: 0.75, verticalAlign: "middle" };
		if (!valueStyle) valueStyle = { fontSize: "inherit", fontWeight: "inherit", verticalAlign: "middle" };
		if (newline) valueDisplay = valueDisplay.replace(/\r?\n/g, newline);
		valueDisplay = valuePrefix ? valuePrefix + valueDisplay : valueDisplay;
		valueDisplay = valueSuffix ? valueDisplay + valueSuffix : valueDisplay;
		return (
			<span key={index ? index : null}>
				{ValueIcon && <ValueIcon fontSize="small" size={14} sx={iconStyle} />}
				{valueLink ? (
					<Link target={valueLinkReplace ? null : "_blank"} rel={valueLinkReplace ? null : "noreferrer"} to={valueLink} sx={valueStyle}>
						{valueDisplay}
					</Link>
				) : (
					<Typography component="span" sx={[valueStyle, { color: valueColor ? valueColor : "inherit" }]}>
						{valueDisplay}
					</Typography>
				)}
			</span>
		);
	}
}