import { authenticate } from "./session";
import { Item as ItemClass } from "./classes/item";

const API_URL = process.env.REACT_APP_API_URL;

export async function createItem(type, data) {
	const session = await authenticate();
	const url = API_URL + "/" + type + "/create";
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Authorization": session.idToken,
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});
	return handleResponse(response);
}

export async function deleteItem(type, tenantIdShard, id) {
	const session = await authenticate();
	if (!session.groups.includes("Manager")) throw new Error("Not Authorized");
	const url = API_URL + "/" + type + "/delete?" + getRequestParameters(tenantIdShard, id);
	const response = await fetch(url, {
		method: "DELETE",
		headers: {
			"Authorization": session.idToken
		}
	});
	return handleResponse(response);
}

function getRequestParameters(tenantIdShard, id) {
	const requestParameters = { id: id };
	if (tenantIdShard) requestParameters.tenantIdShard = tenantIdShard;
	return new URLSearchParams(requestParameters).toString();
}

async function handleResponse(response) {
	if (!response.ok) {
		console.error("Error Calling API", response.statusText);
		throw new Error("Error Calling API");
	}
	const data = await response.json();
	if (data.error) {
		console.error("Error Reading API", data.error);
		throw new Error("Error Reading API");
	}
	return data.result;
}

export async function listItems(type, filterName, filterValue) {
	const session = await authenticate();
	let requestParameters = null;
	if (filterName) {
		requestParameters = { filterName: filterName };
		if (filterValue) requestParameters.filterValue = filterValue;
		requestParameters = new URLSearchParams(requestParameters).toString();
	}
	const url = API_URL + "/" + type + "/list" + (requestParameters ? "?" + requestParameters : "");
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Authorization": session.idToken
		}
	});
	return handleResponse(response);
}

export async function notifyCustomer(tenantIdShardCustomerId, tenantIdShardSampleId) {
	const session = await authenticate();
	if (!session.groups.includes("Manager")) throw new Error("Not Authorized");
	let requestParameters = {
		tenantIdShardCustomerId: tenantIdShardCustomerId,
		tenantIdShardSampleId: tenantIdShardSampleId
	};
	requestParameters = new URLSearchParams(requestParameters).toString();
	const url = API_URL + "/certificate/notify?" + requestParameters;
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Authorization": session.idToken
		}
	});
	return handleResponse(response);
}

export async function notifyCustomers() {
	const session = await authenticate();
	if (!session.groups.includes("Manager")) throw new Error("Not Authorized");
	const url = API_URL + "/certificate/notify";
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Authorization": session.idToken
		}
	});
	return handleResponse(response);
}

async function read(idToken, type, tenantIdShard, id) {
	const url = API_URL + "/" + type + "/read?" + getRequestParameters(tenantIdShard, id);
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Authorization": idToken
		}
	});
	return handleResponse(response);
}

export async function readItem(type, tenantIdShard, id) {
	if (id === ItemClass.NEW_ID) return {};
	const session = await authenticate();
	return await read(session.idToken, type, tenantIdShard, id);
}

export async function readTenant(idToken, id) {
	return await read(idToken, "tenant", null, id);
}

export async function updateItem(type, data) {
	const session = await authenticate();
	const url = API_URL + "/" + type + "/update";
	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Authorization": session.idToken,
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});
	return handleResponse(response);
}