import React from "react";
import { Button, Card, CardActionArea, CardContent, CardMedia, Paper, Typography, Unstable_Grid2 as Grid, useMediaQuery } from "@mui/material";
import { Icon as CustomerIcon } from "../components/customer";
import { Icon as LabIcon } from "../components/lab";
import { Icon as SampleIcon } from "../components/sample";
import { Icon as TestIcon } from "../components/test";
import { InstallDesktop as InstallDesktopIcon, InstallMobile as InstallMobileIcon } from "@mui/icons-material";
import { setDocumentTitle } from "../router";
import Title from "../components/title";
import useSession from "../hooks/useSession";

export default function Home() {
	const [deferredPrompt, setDeferredPrompt] = React.useState(null);
	const iconStyle = { verticalAlign: "text-top" };
	const isMobile = useMediaQuery("(max-width:600px)");
	const { session } = useSession();
	const [showInstallPrompt, setShowInstallPrompt] = React.useState(false);
	setDocumentTitle();
	React.useEffect(() => {
		const isStandalone = window.matchMedia("(display-mode: standalone)").matches || navigator.standalone;
		if (!isStandalone) {
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
			const isSafari = /Safari/i.test(navigator.userAgent);
			if (isIOS || isSafari) {
				setShowInstallPrompt(true);
			} else {
				window.addEventListener("beforeinstallprompt", (e) => {
					e.preventDefault();
					setDeferredPrompt(e);
					setShowInstallPrompt(true);
				});
			}
		}
	}, []);
	const handleInstallClick = () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((choiceResult) => {
				setDeferredPrompt(null);
				setShowInstallPrompt(false);
			});
		} else {
			const isMacOS = /Macintosh/i.test(navigator.userAgent);
			if (isMacOS) {
				alert('To install this web app on your macOS device, click the "Share" icon, and then click "Add to Dock".');
			} else {
				alert('To install this web app on your iOS device, open this web app in Safari, tap the "Share" icon, and then tap "Add to Home Screen".');
			}
		}
	};
	return (
		<Grid container spacing={2} sx={{ padding: 0 }}>
			<Grid xs={12}>
				<Paper elevation={2} sx={{ height: "100%", padding: 2 }}>
					<Title>Welcome</Title>
					<Typography paragraph>
						Hi {session.user.name ? session.user.name : session.user.email}.
						Welcome to your lab information management system for {session.tenant.name}, powered by Lab Axiom.
					</Typography>
				</Paper>
			</Grid>
			{[
				{
					href: "/customers",
					image: "/images/customers.jpg",
					alt: "Customers",
					icon: <CustomerIcon sx={iconStyle} />,
					title: "Customers",
					description: "Manage your customers and their contact information.",
				},
				{
					href: "/samples",
					image: "/images/samples.jpg",
					alt: "Samples",
					icon: <SampleIcon sx={iconStyle} />,
					title: "Samples",
					description: "Manage the samples that your customers submit.",
				},
				{
					href: "/tests",
					image: "/images/tests.jpg",
					alt: "Tests",
					icon: <TestIcon sx={iconStyle} />,
					title: "Tests",
					description: "Manage tests available from your lab and others.",
				},
				{
					href: "/labs",
					image: "/images/labs.jpg",
					alt: "Labs",
					icon: <LabIcon sx={iconStyle} />,
					title: "Labs",
					description: "Manage the external labs to which you outsource tests.",
				},
			].map((item, index) => (
				<Grid xs={6} md={3} key={index}>
					<Card elevation={2} sx={{ height: "100%" }}>
						<CardActionArea href={item.href}>
							<CardMedia component="img" image={item.image} alt={item.alt} />
							<CardContent>
								<Typography gutterBottom component="h3" variant="h6">
									{item.icon} {item.title}
								</Typography>
								<Typography variant="body2" color="text.secondary">
									{item.description}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			))}
			{showInstallPrompt &&
				<Grid xs={12}>
					<Paper elevation={2} sx={{ height: "100%", padding: 2 }}>
						<Title>Install</Title>
						<Typography paragraph>
							For easy access and the best experience, install Lab Axiom as an application on your device.
							It will open in its own window and safely integrate with device features.
							Installation is easy and fast. No app store or download required.
						</Typography>
						<Button
							variant="contained"
							color="primary"
							startIcon={isMobile ? <InstallMobileIcon /> : <InstallDesktopIcon />}
							onClick={handleInstallClick}
						>
							Install
						</Button>
					</Paper>
				</Grid>
			}
		</Grid>
	);
}