import React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import useDialog from "./useDialog";

export const UNSAVED_CHANGES_MESSAGE = "You have unsaved changes. Do you really want to leave?";
export const UNSAVED_CHANGES_TITLE = "Unsaved Changes";

export default function useBlocker(blocker, when = true) {

	const { navigator } = React.useContext(NavigationContext);
	const { setDialog } = useDialog();

	React.useEffect(() => {
		if (!when) return;
		const push = navigator.push;
		const replace = navigator.replace;
		const handlePopState = () => {
			if (blocker()) {
				setDialog({
					confirm: {
						title: UNSAVED_CHANGES_TITLE,
						message: UNSAVED_CHANGES_MESSAGE,
						onConfirm: () => window.history.pushState(null, "", window.location.pathname)
					}
				});
			}
		};
		navigator.push = (...args) => {
			if (!blocker()) {
				push(...args);
			} else {
				setDialog({
					confirm: {
						title: UNSAVED_CHANGES_TITLE,
						message: UNSAVED_CHANGES_MESSAGE,
						onConfirm: () => push(...args)
					}
				});
			}
		};
		navigator.replace = (...args) => {
			if (!blocker()) {
				replace(...args);
			} else {
				setDialog({
					confirm: {
						title: UNSAVED_CHANGES_TITLE,
						message: UNSAVED_CHANGES_MESSAGE,
						onConfirm: () => replace(...args)
					}
				});
			}
		};
		window.addEventListener("popstate", handlePopState);
		return () => {
			window.removeEventListener("popstate", handlePopState);
			navigator.push = push;
			navigator.replace = replace;
		};
	}, [blocker, navigator, setDialog, when]);

	React.useEffect(() => {
		if (!when) return;
		const handleBeforeUnload = (event) => {
			if (blocker()) {
				event.preventDefault();
				event.returnValue = ""; // Necessary for most browsers
			}
		};
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => window.removeEventListener("beforeunload", handleBeforeUnload);
	}, [blocker, when]);

}