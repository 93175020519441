import { Certificate as CertificateClass } from "./classes/certificate";
import { getEnvironment } from "./app";
import { Sample as SampleClass } from "./classes/sample";

function generateLabel(data) {
	const CHAR_WIDTH_AND_HEIGHT = 28;
	const QR_CODE_Y_POS = 405;
	const MAX_TEXT_CHARS = 25;
	const name = data.name.length > MAX_TEXT_CHARS ? data.name.substring(0, MAX_TEXT_CHARS) + " ..." : data.name;
	const code = CertificateClass.getCode(data.createdAt, data.updatedAt);
	const storage = data.storage;
	const url = SampleClass.getViewUrlFromInputs(data.tenantIdShard, data.id, getEnvironment());
	const tests = [];
	data.tenantIdShardTestIdReferences.forEach((test) => {
		const testName = SampleClass.getNameContextFromCombinedId(test);
		if (testName.length > MAX_TEXT_CHARS) {
			tests.push(testName.substring(0, MAX_TEXT_CHARS) + " ...");
		} else {
			tests.push(testName);
		}
	});
	const totalLabels = Math.ceil(tests.length / 2);
	let zpl = "";
	for (let i = 0; i < tests.length; i += 2) {
		const chunk = tests.slice(i, i + 2);
		const labelNumber = Math.floor(i / 2) + 1;
		zpl += `
^XA
^FWR
; -- Name Bold
^FO243,40^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FD${name}^FS
^FO242,41^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FD${name}^FS
; -- Code
^FO213,40^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FDCode: ${code}^FS
; -- Storage
^FO183,40^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FDStorage: ${storage}^FS
; -- QR Code
^FO105,${QR_CODE_Y_POS}^BQN,2,3
^FDQA,${url}^FS
	`.trim();
		let yPos = 153;
		const step = 30;
		for (const testLine of chunk) {
			zpl += `
^FO${yPos},40^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FD${testLine}^FS
`.trim();
			yPos -= step;
		}
		zpl += `
^FO${yPos},40^A0,${CHAR_WIDTH_AND_HEIGHT},${CHAR_WIDTH_AND_HEIGHT}^FD[label ${labelNumber} of ${totalLabels}]^FS
^XZ
`.trim();
	}
	return zpl;
}

async function getPrinter() {
	console.log("Getting Zebra Printer");
	const printer = await new Promise((resolve, reject) => {
		window.BrowserPrint.getDefaultDevice(
			"printer", 
			(printer) => {
				if (printer) {
					console.log("Zebra Printer", printer);
					resolve(printer);
				} else {
					reject(new Error("Zebra Printer Not Found"));
				}
			},
			(error) => reject(new Error(error || "Zebra Printer Not Found")));
	});
	return printer;
}

function loadSdk() {
	console.log("Loading Zebra SDK");
	return new Promise((resolve, reject) => {
		if (window.BrowserPrint) {
			resolve();
		} else {
			const script = document.createElement("script");
			script.src = "/scripts/BrowserPrint-3.1.250.min.js";
			script.async = true;
			script.onload = () => {
				console.log("Zebra SDK Loaded");
				resolve();
			};
			script.onerror = () => {
				reject(new Error("Error Loading Zebra SDK"));
			};
			document.body.appendChild(script);
		}
	});
}

export async function print(data) {
	await loadSdk();
	const printer = await getPrinter();
	const labelZpl = generateLabel(data);
	await new Promise((resolve, reject) => {
		printer.send(
			labelZpl, 
			() => resolve("Print successful"),
			(error) => reject(new Error(error || "Failed to send to printer"))
		);
	});
}